import {
  //collection,
  doc,
  //getDocs,
  //updateDoc,
  writeBatch,
  //deleteField,
} from "firebase/firestore";
import { db } from "../firebaseInit";
//import { FieldValue } from "firebase/firestore";
//const cardClubsCollection = collection(db, "cardClubs");
//const scoreCardsCollection = collection(db, "scoreCards"); //Used to update score cards

const initDemoModule = {
  namespaced: true,
  state() {
    return {
      demoUserClubsData: [
        {
          "4gDs4S7zyFdMYFVICfqV": {
            createdDate: new Date("2023-10-06T19:48:12.074Z"),
            description: null,
            lastGameSelected: "id0",
            managers: [
              {
                id: "Em73LzpnzWayz3UT22AL53pd4Vi2",
              },
            ],
            name: "Family Night",
            selected: false,
            teams: [
              {
                name: "Boys",
              },
              {
                name: "Girls",
              },
              {
                name: "Team 3",
              },
              {
                name: "Team 4",
              },
            ],
          },
        },
        {
          jMm8sPNNxeZOyyAGkmhK: {
            createdDate: new Date("2023-10-07T02:43:38.430Z"),
            description: null,
            lastGameSelected: "id3",
            managers: [
              {
                id: "Em73LzpnzWayz3UT22AL53pd4Vi2",
              },
            ],
            name: "Neighborhood Canasta",
            selected: true,
            teams: [
              {
                name: "Yellow Team",
              },
              {
                name: "Brown Team",
              },
              {
                inputError: false,
                name: "Team 3",
              },
              {
                inputError: false,
                name: "Team 4",
              },
            ],
          },
        },
        {
          LGxzkPVXFPPJ1S8cRjb4: {
            createdDate: new Date("2023-10-07T05:35:04.234Z"),

            description: null,
            lastGameSelected: null,
            managers: [
              {
                id: "Em73LzpnzWayz3UT22AL53pd4Vi2",
              },
            ],
            name: "Thursday Nights",
            selected: true,
            teams: [
              {
                inputError: false,
                name: "Team 1",
              },
              {
                inputError: false,
                name: "Team 2",
              },
              {
                inputError: false,
                name: "Team 3",
              },
              {
                inputError: false,
                name: "Team 4",
              },
            ],
          },
        },
      ],
      demoUserPlayersData: [
        {
          "0oBDxcl9JKh2RzEdqFj5": {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:45:05.556Z"),
            id3: {
              selected: true,
              team: "1",
            },
            name: "Jade",
            nickname: "",
            selected: true,
            team: "1",
          },
        },
        {
          "1EiqShB8gE6pd3GDHPeG": {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:45:45.158Z"),
            id3: {
              selected: false,
              team: 0,
            },
            name: "Mike",
            nickname: "",
            selected: false,
            team: 0,
          },
        },
        {
          "9LnKlQsR4Y6fsJRz8b3Q": {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:44:47.393Z"),
            id3: {
              selected: true,
              team: "0",
            },
            name: "Bob",
            nickname: "",
            selected: true,
            team: "0",
          },
        },
        {
          DKEmIEXhPrvEFlYKx1h1: {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:45:48.191Z"),
            id3: {
              selected: true,
              team: "1",
            },
            name: "Larry",
            nickname: "",
            selected: true,
            team: "1",
          },
        },
        {
          DgxLPGlklmsL0EoQ3kmP: {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:45:18.382Z"),
            id3: {
              selected: false,
              team: 0,
            },
            name: "Ruth",
            nickname: "",
            selected: false,
            team: 0,
          },
        },
        {
          G5B60flZs3OVXzUovrGt: {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:45:09.482Z"),
            id3: {
              selected: false,
              team: 0,
            },
            name: "Morgan",
            nickname: "",
            selected: false,
            team: 0,
          },
        },
        {
          GH21kBZxnSVQdagBHTQw: {
            ccOwner: "4gDs4S7zyFdMYFVICfqV",
            dateCreated: new Date("2023-10-07T02:45:09.482Z"),
            id0: {
              selected: true,
              team: "1",
            },
            id1: {
              selected: true,
              team: "0",
            },
            name: "Rachael",
            nickname: "",
            selected: true,
            team: "1",
          },
        },
        {
          NvtjC2gCgIbhkq7plQK8: {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:44:51.465Z"),
            id3: {
              selected: false,
              team: 0,
            },
            name: "Sam",
            nickname: "",
            selected: false,
            team: 0,
          },
        },
        {
          QYEjSMHiolDFiZ6TcaR8: {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:45:20.642Z"),
            id3: {
              selected: false,
              team: 0,
            },
            name: "Sharon",
            nickname: "",
            selected: false,
            team: 0,
          },
        },
        {
          f8IBKMkGvbqPggbqgARF: {
            ccOwner: "jMm8sPNNxeZOyyAGkmhK",
            dateCreated: new Date("2023-10-07T02:45:15.497Z"),
            id3: {
              selected: true,
              team: "0",
            },
            name: "Cindy",
            nickname: "",
            selected: true,
            team: "0",
          },
        },
        {
          k5A6NBzD1PKXWhqSwj0V: {
            ccOwner: "4gDs4S7zyFdMYFVICfqV",
            dateCreated: new Date("2023-10-07T02:27:56.919Z"),
            id0: {
              selected: true,
              team: "1",
            },
            id1: {
              selected: true,
              team: "0",
            },
            name: "Mom",
            nickname: "",
            selected: true,
            team: "1",
          },
        },
        {
          py4L5CkgbdgxhSIEczZ9: {
            ccOwner: "4gDs4S7zyFdMYFVICfqV",
            dateCreated: new Date("2023-10-07T02:27:58.567Z"),
            id0: {
              selected: true,
              team: "0",
            },
            id1: {
              selected: true,
              team: "0",
            },
            name: "Dad",
            nickname: "",
            selected: true,
            team: "0",
          },
        },
        {
          zZ8c2mYdl2QUe2DRoQfU: {
            ccOwner: "4gDs4S7zyFdMYFVICfqV",
            dateCreated: new Date("2023-10-07T02:28:37.135Z"),
            id0: {
              selected: true,
              team: "0",
            },
            id1: {
              selected: true,
              team: "0",
            },
            name: "Levi",
            nickname: "",
            selected: true,
            team: "0",
          },
        },

        {
          NvtjC2gCgIbhkq7plQK8: {
            ccOwner: "LGxzkPVXFPPJ1S8cRjb4",
            dateCreated: new Date("2023-10-07T02:44:51.465Z"),
            id3: {
              selected: false,
              team: 0,
            },
            name: "Sam",
            nickname: "",
            selected: false,
            team: 0,
          },
        },
        {
          QYEjSMHiolDFiZ6TcaR8: {
            ccOwner: "LGxzkPVXFPPJ1S8cRjb4",
            dateCreated: new Date("2023-10-07T02:45:20.642Z"),
            id3: {
              selected: false,
              team: 0,
            },
            name: "Sharon",
            nickname: "",
            selected: false,
            team: 0,
          },
        },
        {
          f8IBKMkGvbqPggbqgARF: {
            ccOwner: "LGxzkPVXFPPJ1S8cRjb4",
            dateCreated: new Date("2023-10-07T02:45:15.497Z"),
            id3: {
              selected: true,
              team: "0",
            },
            name: "Rosa",
            nickname: "",
            selected: true,
            team: "0",
          },
        },
        {
          k5A6NBzD1PKXWhqSwj0V: {
            ccOwner: "LGxzkPVXFPPJ1S8cRjb4",
            dateCreated: new Date("2023-10-07T02:27:56.919Z"),
            id0: {
              selected: true,
              team: "1",
            },
            id1: {
              selected: true,
              team: "0",
            },
            name: "Cindy",
            nickname: "",
            selected: true,
            team: "1",
          },
        },
        {
          py4L5CkgbdgxhSIEczZ9: {
            ccOwner: "LGxzkPVXFPPJ1S8cRjb4",
            dateCreated: new Date("2023-10-07T02:27:58.567Z"),
            id0: {
              selected: true,
              team: "0",
            },
            id1: {
              selected: true,
              team: "0",
            },
            name: "Frank",
            nickname: "",
            selected: true,
            team: "0",
          },
        },
        {
          zZ8c2mYdl2QUe2DRoQfU: {
            ccOwner: "LGxzkPVXFPPJ1S8cRjb4",
            dateCreated: new Date("2023-10-07T02:28:37.135Z"),
            id0: {
              selected: true,
              team: "0",
            },
            id1: {
              selected: true,
              team: "0",
            },
            name: "Jose",
            nickname: "",
            selected: true,
            team: "0",
          },
        },
      ],
      demoUserScoreCardsData: [
        {
          glaMIDCAHVTpb3Mte2LF: {
            ccID: "4gDs4S7zyFdMYFVICfqV",
            ccName: "Family Night",
            customizable: {
              allowSixBid: {
                note: "fiveHundred.allowSixBidNote",
                sortOrder: 4,
                title: "fiveHundred.allowSixBid",
                type: "boolean",
                value: false,
              },
              bidToWin: {
                note: "fiveHundred.custMustBidToWinNote",
                sortOrder: 1,
                title: "fiveHundred.custMustBidToWin",
                type: "boolean",
                value: true,
              },
              playDoubleNula: {
                note: "fiveHundred.playDoubleNulaNote",
                sortOrder: 3,
                title: "fiveHundred.playDoubleNula",
                type: "boolean",
                value: false,
              },
              playNula: {
                note: "fiveHundred.playNulaNote",
                sortOrder: 2,
                title: "fiveHundred.playNula",
                type: "boolean",
                value: false,
              },
            },
            dateCreated: "Fri Oct 06 2023",
            gameEnds: {
              howGameEnds: "rounds",
              pointsToEnd: 100,
              roundsToEnd: 3,
              winningScore: "low",
            },
            gamePlayedBy: "teams",
            games: [
              {
                dateFinished: new Date("2023-10-07T02:42:50.355Z"),
                dateStarted: new Date("2023-10-07T02:42:50.355Z"),
                gameFinished: true,
                gameNumber: 1,
                team1Score: 280,
                team2Score: 540,
                teamName1: {
                  name: "Boys",
                },
                teamName2: {
                  name: "Girls",
                },
                winningPlayer: ["Girls"],
              },
            ],
            gdID: "id0",
            gdName: "gdInsert.cust500",
            hands: [
              {
                bid: "0 - Nula",
                bidderIndex: 0,
                bidderName: {
                  name: "Boys",
                },
                gameNumber: 1,
                handNumber: 1,
                team1: "Boys",
                team1Player1: "Dad",
                team1Player2: "Levi",
                team1Score: 250,
                team2: "Girls",
                team2Player1: "Mom",
                team2Player2: "Rachael",
                team2Score: 0,
                tricksTaken: 0,
              },
              {
                bid: "8 - Clubs",
                bidderIndex: 1,
                bidderName: {
                  name: "Girls",
                },
                gameNumber: 1,
                handNumber: 2,
                team1: "Boys",
                team1Player1: "Dad",
                team1Player2: "Levi",
                team1Score: 20,
                team2: "Girls",
                team2Player1: "Mom",
                team2Player2: "Rachael",
                team2Score: 260,
                tricksTaken: 8,
              },
              {
                bid: "8 - Diamonds",
                bidderIndex: 1,
                bidderName: {
                  name: "Girls",
                },
                gameNumber: 1,
                handNumber: 3,
                team1: "Boys",
                team1Player1: "Dad",
                team1Player2: "Levi",
                team1Score: 10,
                team2: "Girls",
                team2Player1: "Mom",
                team2Player2: "Rachael",
                team2Score: 280,
                tricksTaken: 9,
              },
            ],
            id: "rQM3mKLWIOYJ0OybSNt4",
            lastDealer: 3,
            players: [
              {
                dealOrder: 0,
                id: "py4L5CkgbdgxhSIEczZ9",
                name: "Dad",
                team: "0",
              },
              {
                dealOrder: 1,
                id: "zZ8c2mYdl2QUe2DRoQfU",
                name: "Levi",
                team: "0",
              },
              {
                dealOrder: 2,
                id: "k5A6NBzD1PKXWhqSwj0V",
                name: "Mom",
                team: "1",
              },
              {
                dealOrder: 3,
                id: "GH21kBZxnSVQdagBHTQw",
                name: "Rachael",
                team: "1",
              },
            ],
            scoreCardName: "Family Night - 500 with the kids",
            siteMemberID: "Em73LzpnzWayz3UT22AL53pd4Vi2",
            siteMemberName: "Demo User",
            teams: [
              {
                name: "Boys",
              },
              {
                name: "Girls",
              },
            ],
            vueFileToLoad: "FiveHundred.vue",
          },
        },
        {
          rQM3mKLWIOYJ0OybSNt4: {
            ccID: "4gDs4S7zyFdMYFVICfqV",
            ccName: "Family Night",
            customizable: {},
            dateCreated: "Fri Oct 06 2023",
            gameEnds: {
              howGameEnds: "rounds",
              pointsToEnd: 100,
              roundsToEnd: 3,
              winningScore: "low",
            },
            gamePlayedBy: "individuals",
            games: [
              {
                dateFinished: new Date("2023-10-07T02:33:35.081Z"),
                gameFinished: true,
                gameNumber: 1,
                scores: [
                  {
                    player: "Dad",
                    score: 127,
                  },
                  {
                    player: "Levi",
                    score: 106,
                  },
                  {
                    player: "Mom",
                    score: 112,
                  },
                  {
                    player: "Rachael",
                    score: 106,
                  },
                ],
                winningPlayer: ["Levi", "Rachael"],
                winningPlayerScore: 106,
              },
            ],
            gdID: "id1",
            gdName: "gdInsert.cust5Crowns",
            hands: [
              {
                dateFinished: new Date("2023-10-07T02:30:00.794Z"),
                gameNumber: 1,
                handNumber: 0,
                scores: [
                  {
                    player: "Dad",
                    score: 12,
                  },
                  {
                    player: "Levi",
                    score: 14,
                  },
                  {
                    player: "Mom",
                    score: 0,
                  },
                  {
                    player: "Rachael",
                    score: 6,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:30:26.157Z"),
                gameNumber: 1,
                handNumber: 1,
                scores: [
                  {
                    player: "Dad",
                    score: 22,
                  },
                  {
                    player: "Levi",
                    score: 8,
                  },
                  {
                    player: "Mom",
                    score: 18,
                  },
                  {
                    player: "Rachael",
                    score: 8,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:30:43.745Z"),
                gameNumber: 1,
                handNumber: 2,
                scores: [
                  {
                    player: "Dad",
                    score: 0,
                  },
                  {
                    player: "Levi",
                    score: 12,
                  },
                  {
                    player: "Mom",
                    score: 18,
                  },
                  {
                    player: "Rachael",
                    score: 6,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:30:56.061Z"),
                gameNumber: 1,
                handNumber: 3,
                scores: [
                  {
                    player: "Dad",
                    score: 22,
                  },
                  {
                    player: "Levi",
                    score: 0,
                  },
                  {
                    player: "Mom",
                    score: 12,
                  },
                  {
                    player: "Rachael",
                    score: 18,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:31:04.851Z"),
                gameNumber: 1,
                handNumber: 4,
                scores: [
                  {
                    player: "Dad",
                    score: 18,
                  },
                  {
                    player: "Levi",
                    score: 22,
                  },
                  {
                    player: "Mom",
                    score: 0,
                  },
                  {
                    player: "Rachael",
                    score: 12,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:31:16.349Z"),
                gameNumber: 1,
                handNumber: 5,
                scores: [
                  {
                    player: "Dad",
                    score: 0,
                  },
                  {
                    player: "Levi",
                    score: 12,
                  },
                  {
                    player: "Mom",
                    score: 18,
                  },
                  {
                    player: "Rachael",
                    score: 6,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:31:28.124Z"),
                gameNumber: 1,
                handNumber: 6,
                scores: [
                  {
                    player: "Dad",
                    score: 0,
                  },
                  {
                    player: "Levi",
                    score: 12,
                  },
                  {
                    player: "Mom",
                    score: 18,
                  },
                  {
                    player: "Rachael",
                    score: 20,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:31:46.807Z"),
                gameNumber: 1,
                handNumber: 7,
                scores: [
                  {
                    player: "Dad",
                    score: 12,
                  },
                  {
                    player: "Levi",
                    score: 0,
                  },
                  {
                    player: "Mom",
                    score: 4,
                  },
                  {
                    player: "Rachael",
                    score: 18,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:32:31.020Z"),
                gameNumber: 1,
                handNumber: 8,
                scores: [
                  {
                    player: "Dad",
                    score: 36,
                  },
                  {
                    player: "Levi",
                    score: 14,
                  },
                  {
                    player: "Mom",
                    score: 7,
                  },
                  {
                    player: "Rachael",
                    score: 0,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:33:01.516Z"),
                gameNumber: 1,
                handNumber: 9,
                scores: [
                  {
                    player: "Dad",
                    score: 5,
                  },
                  {
                    player: "Levi",
                    score: 0,
                  },
                  {
                    player: "Mom",
                    score: 2,
                  },
                  {
                    player: "Rachael",
                    score: 4,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T02:33:35.079Z"),
                gameNumber: 1,
                handNumber: 10,
                scores: [
                  {
                    player: "Dad",
                    score: 0,
                  },
                  {
                    player: "Levi",
                    score: 12,
                  },
                  {
                    player: "Mom",
                    score: 15,
                  },
                  {
                    player: "Rachael",
                    score: 8,
                  },
                ],
              },
            ],
            lastDealer: 3,
            players: [
              {
                dealOrder: 0,
                id: "py4L5CkgbdgxhSIEczZ9",
                name: "Dad",
                team: "0",
              },
              {
                dealOrder: 1,
                id: "zZ8c2mYdl2QUe2DRoQfU",
                name: "Levi",
                team: "0",
              },
              {
                dealOrder: 2,
                id: "k5A6NBzD1PKXWhqSwj0V",
                name: "Mom",
                team: "0",
              },
              {
                dealOrder: 3,
                id: "GH21kBZxnSVQdagBHTQw",
                name: "Rachael",
                team: "0",
              },
            ],
            scoreCardName: "Family Night - 5 Crowns with the kids",
            siteMemberID: "Em73LzpnzWayz3UT22AL53pd4Vi2",
            siteMemberName: "Demo User",
            teams: [
              {
                name: "Team 1",
              },
            ],
            vueFileToLoad: "FiveCrowns.vue",
          },
        },
        {
          rQUAqvOl5VpnXoVwGJdg: {
            ccID: "jMm8sPNNxeZOyyAGkmhK",
            ccName: "Neighborhood Canasta",
            customizable: {
              acesMoreThanTwo: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 25,
                title: "canasta.custAcesMoreThanTwo",
                value: -1500,
              },
              acesNatural: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 21,
                title: "canasta.custAcesNatural",
                value: 2500,
              },
              acesOpen: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 23,
                title: "canasta.custAcesOpen",
                value: -2500,
              },
              cleanBook: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 51,
                title: "canasta.custPointsCleanBook",
                value: 500,
              },
              deucesCanasta: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 45,
                title: "canasta.custDeucesCanasta",
                value: 3000,
              },
              dirtyBook: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 52,
                title: "canasta.custPointsDirtyBook",
                value: 300,
              },
              gameEndsScore: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 66,
                title: "canasta.custGameEndsScore",
                value: 8500,
              },
              goingOut: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 10,
                title: "handAndFoot.custPointsGoingOut",
                value: 100,
              },
              level1MinMeld: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 61,
                title: "canasta.custLevel1MeldPoints",
                value: 125,
              },
              level1Threshold: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 62,
                title: "canasta.custLevel1Threshold",
                value: 3000,
              },
              level2MinMeld: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 63,
                title: "canasta.custLevel2MeldPoints",
                value: 155,
              },
              level2Threshold: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 64,
                title: "canasta.custLevel2Threshold",
                value: 5000,
              },
              level3MinMeld: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 65,
                title: "canasta.custLevel3MeldPoints",
                value: 180,
              },
              openWild: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 47,
                title: "canasta.custOpenWild",
                value: -2000,
              },
              roundsToPrint: {
                note: "canasta.custRoundsToPrintNote",
                sortOrder: 70,
                title: "canasta.custRoundsToPrint",
                value: 10,
              },
              scoreAcesMoreThanTwo: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 24,
                title: "canasta.custPlayAcesMoreThanTwo",
                type: "boolean",
                value: true,
              },
              scoreAcesNatural: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 20,
                title: "canasta.custPlayAcesNatural",
                type: "boolean",
                value: true,
              },
              scoreAcesOpen: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 22,
                title: "canasta.custPlayAcesOpen",
                type: "boolean",
                value: true,
              },
              scoreDeucesCanasta: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 44,
                title: "canasta.custPlayDuecesCanasta",
                type: "boolean",
                value: true,
              },
              scoreOpenWild: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 46,
                title: "canasta.custPlayOpenWild",
                type: "boolean",
                value: true,
              },
              scoreSevensMoreThanTwo: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 34,
                title: "canasta.custPlaySevensMoreThanTwo",
                type: "boolean",
                value: true,
              },
              scoreSevensNatural: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 30,
                title: "canasta.custPlaySevensNatural",
                type: "boolean",
                value: true,
              },
              scoreSevensOpen: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 32,
                title: "canasta.custPlaySevensOpen",
                type: "boolean",
                value: true,
              },
              scoreThrees: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 50,
                title: "canasta.custPlayThrees",
                type: "boolean",
                value: true,
              },
              scoreWildCanasta: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 40,
                title: "canasta.custPlayWildCanasta",
                type: "boolean",
                value: true,
              },
              scoreWildCanasta4J: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 42,
                title: "canasta.custPlayWildCanasta4J",
                type: "boolean",
                value: true,
              },
              sevensMoreThanTwo: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 35,
                title: "canasta.custSevensMoreThanTwo",
                value: -1500,
              },
              sevensNatural: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 31,
                title: "canasta.custSevensNatural",
                value: 2500,
              },
              sevensOpen: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 33,
                title: "canasta.custSevensOpen",
                value: -2500,
              },
              wildCanasta: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 41,
                title: "canasta.custWildCanasta",
                value: 2000,
              },
              wildCanasta4J: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 43,
                title: "canasta.custWildCanasta4J",
                value: 2500,
              },
            },
            dateCreated: "Fri Oct 06 2023",
            gameEnds: {
              howGameEnds: "rounds",
              pointsToEnd: 100,
              roundsToEnd: 3,
              winningScore: "low",
            },
            gamePlayedBy: "teams",
            games: [
              {
                dateFinished: new Date("2023-10-07T02:47:35.654Z"),
                dateStarted: new Date("2023-10-07T02:47:35.654Z"),
                gameFinished: true,
                gameNumber: 1,
                scores: [
                  {
                    player: "Yellow Team",
                    score: 8600,
                  },
                  {
                    player: "Brown Team",
                    score: 4100,
                  },
                ],
                winningPlayer: ["Yellow Team"],
                winningPlayerScore: 8600,
              },
            ],
            gdID: "id3",
            gdName: "canasta.gameName",
            hands: [
              {
                gameNumber: 1,
                handNumber: 0,
                rTitle: "Round 1",
                scores: [
                  {
                    name: "Yellow Team",
                    score: 5600,
                  },
                  {
                    name: "Brown Team",
                    score: 2000,
                  },
                ],
              },
              {
                gameNumber: 1,
                handNumber: 1,
                rTitle: "Round 2",
                scores: [
                  {
                    name: "Yellow Team",
                    score: 3000,
                  },
                  {
                    name: "Brown Team",
                    score: 2100,
                  },
                ],
              },
            ],
            id: "glaMIDCAHVTpb3Mte2LF",
            lastDealer: 2,
            players: [
              {
                dealOrder: 0,
                id: "9LnKlQsR4Y6fsJRz8b3Q",
                name: "Bob",
                team: "0",
              },
              {
                dealOrder: 1,
                id: "f8IBKMkGvbqPggbqgARF",
                name: "Cindy",
                team: "0",
              },
              {
                dealOrder: 2,
                id: "0oBDxcl9JKh2RzEdqFj5",
                name: "Jade",
                team: "1",
              },
              {
                dealOrder: 3,
                id: "DKEmIEXhPrvEFlYKx1h1",
                name: "Larry",
                team: "1",
              },
            ],
            scoreCardName:
              "Neighborhood Canasta - Canasta with Bob, Cindy, Jade, and Larry",
            siteMemberID: "Em73LzpnzWayz3UT22AL53pd4Vi2",
            siteMemberName: "Demo User",
            teams: [
              {
                name: "Yellow Team",
              },
              {
                name: "Brown Team",
              },
            ],
            vueFileToLoad: "CanastaAmerican.vue",
          },
        },
        {
          VtwivrGREbKWq6qU7H1P: {
            ccID: "LGxzkPVXFPPJ1S8cRjb4",
            ccName: "Thursday Nights",
            customizable: {
              cleanBook: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 4,
                title: "handAndFoot.custPointsCleanBook",
                value: 500,
              },
              dirtyBook: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 5,
                title: "handAndFoot.custPointsDirtyBook",
                value: 300,
              },
              goingOut: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 3,
                title: "handAndFoot.custPointsGoingOut",
                value: 100,
              },
              minMeldR0: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 7,
                title: "handAndFoot.custRound1MinMeld",
                value: 50,
              },
              minMeldR1: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 8,
                title: "handAndFoot.custRound2MinMeld",
                value: 75,
              },
              minMeldR2: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 9,
                title: "handAndFoot.custRound3MinMeld",
                value: 100,
              },
              minMeldR3: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 10,
                title: "handAndFoot.custRound4MinMeld",
                value: 125,
              },
              minMeldR4: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 11,
                title: "handAndFoot.custRound5MinMeld",
                value: 150,
              },
              perfectCut: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 2,
                title: "handAndFoot.custPointsPerfectCut",
                value: 100,
              },
              playPerfectCut: {
                note: "genericScoreCard.custNoNote",
                sortOrder: 1,
                title: "handAndFoot.custPlayWithPerfectCut",
                type: "boolean",
                value: true,
              },
              roundsPerGame: {
                note: "handAndFoot.custRoundsPerGameNote",
                sortOrder: 6,
                title: "handAndFoot.custRoundsPerGame",
                value: 5,
              },
            },
            dateCreated: "Sat Oct 07 2023",
            gameEnds: {
              howGameEnds: "rounds",
              pointsToEnd: 100,
              roundsToEnd: 3,
              winningScore: "low",
            },
            gamePlayedBy: "teams",
            games: [
              {
                dateFinished: new Date("2023-10-07T16:33:26.679Z"),
                dateStarted: new Date("2023-10-07T16:33:26.679Z"),
                gameFinished: true,
                gameNumber: 1,
                scores: [
                  {
                    player: "Smith",
                    score: 12310,
                  },
                  {
                    player: "Webster",
                    score: 13380,
                  },
                ],
                winningPlayer: ["Webster"],
                winningPlayerScore: 13380,
              },
            ],
            gdID: "id2",
            gdName: "gdInsert.custHandAndFoot",
            hands: [
              {
                gameNumber: 1,
                handNumber: 0,
                rTitle: "Round 1",
                scores: [
                  {
                    name: "Smith",
                    score: 2200,
                  },
                  {
                    name: "Webster",
                    score: 2300,
                  },
                ],
              },
              {
                gameNumber: 1,
                handNumber: 1,
                rTitle: "Round 2",
                scores: [
                  {
                    name: "Smith",
                    score: 2300,
                  },
                  {
                    name: "Webster",
                    score: 3090,
                  },
                ],
              },
              {
                gameNumber: 1,
                handNumber: 2,
                rTitle: "Round 3",
                scores: [
                  {
                    name: "Smith",
                    score: 2350,
                  },
                  {
                    name: "Webster",
                    score: 1540,
                  },
                ],
              },
              {
                gameNumber: 1,
                handNumber: 3,
                rTitle: "Round 4",
                scores: [
                  {
                    name: "Smith",
                    score: 3890,
                  },
                  {
                    name: "Webster",
                    score: 1960,
                  },
                ],
              },
              {
                gameNumber: 1,
                handNumber: 4,
                rTitle: "Round 5",
                scores: [
                  {
                    name: "Smith",
                    score: 1570,
                  },
                  {
                    name: "Webster",
                    score: 4490,
                  },
                ],
              },
              {
                gameNumber: 2,
                handNumber: 0,
                rTitle: "Round 1",
                scores: [
                  {
                    name: "Smith",
                    score: 2560,
                  },
                  {
                    name: "Webster",
                    score: 1850,
                  },
                ],
              },
              {
                gameNumber: 2,
                handNumber: 1,
                rTitle: "Round 2",
                scores: [
                  {
                    name: "Smith",
                    score: 2070,
                  },
                  {
                    name: "Webster",
                    score: 3170,
                  },
                ],
              },
            ],
            lastDealer: 3,
            players: [
              {
                dealOrder: 0,
                id: "k5A6NBzD1PKXWhqSwj0V",
                name: "Cindy",
                team: "0",
              },
              {
                dealOrder: 1,
                id: "py4L5CkgbdgxhSIEczZ9",
                name: "Frank",
                team: "0",
              },
              {
                dealOrder: 2,
                id: "NvtjC2gCgIbhkq7plQK8",
                name: "Sam",
                team: "1",
              },
              {
                dealOrder: 3,
                id: "QYEjSMHiolDFiZ6TcaR8",
                name: "Sharon",
                team: "1",
              },
            ],
            scoreCardName:
              "Thursday Nights - Hand and Foot with Smith and Webster",
            siteMemberID: "Em73LzpnzWayz3UT22AL53pd4Vi2",
            siteMemberName: "Demo User",
            teams: [
              {
                name: "Smith",
              },
              {
                name: "Webster",
              },
            ],
            vueFileToLoad: "HandAndFoot.vue",
          },
        },
        {
          YYgnE4Ox2neJoIz0FYPU: {
            ccID: "LGxzkPVXFPPJ1S8cRjb4",
            ccName: "Thursday Nights",
            customizable: {
              rowsToPrint: {
                note: "genericGame.rowsToPrintNote",
                sortOrder: 1,
                title: "genericGame.rowsToPrint",
                value: 10,
              },
            },
            dateCreated: "Sat Oct 07 2023",
            gameEnds: {
              howGameEnds: "points",
              pointsToEnd: 121,
              roundsToEnd: 3,
              winningScore: "high",
            },
            gamePlayedBy: "individuals",
            games: [
              {
                dateFinished: new Date("2023-10-07T16:41:08.818Z"),
                gameFinished: true,
                gameNumber: 1,
                scores: [
                  {
                    player: "Rosa",
                    score: 121,
                  },
                  {
                    player: "Sharon",
                    score: 112,
                  },
                ],
                winningPlayer: ["Rosa"],
                winningPlayerScore: 121,
              },
              {
                dateFinished: new Date("2023-10-07T16:41:19.759Z"),
                gameFinished: true,
                gameNumber: 2,
                scores: [
                  {
                    player: "Rosa",
                    score: 121,
                  },
                  {
                    player: "Sharon",
                    score: 118,
                  },
                ],
                winningPlayer: ["Rosa"],
                winningPlayerScore: 121,
              },
              {
                dateFinished: new Date("2023-10-07T16:41:39.169Z"),
                gameFinished: true,
                gameNumber: 3,
                scores: [
                  {
                    player: "Rosa",
                    score: 105,
                  },
                  {
                    player: "Sharon",
                    score: 121,
                  },
                ],
                winningPlayer: ["Sharon"],
                winningPlayerScore: 121,
              },
              {
                dateFinished: new Date("2023-10-07T16:42:00.239Z"),
                gameFinished: true,
                gameNumber: 4,
                scores: [
                  {
                    player: "Rosa",
                    score: 121,
                  },
                  {
                    player: "Sharon",
                    score: 96,
                  },
                ],
                winningPlayer: ["Rosa"],
                winningPlayerScore: 121,
              },
              {
                dateFinished: new Date("2023-10-07T16:42:20.056Z"),
                gameFinished: true,
                gameNumber: 5,
                scores: [
                  {
                    player: "Rosa",
                    score: 109,
                  },
                  {
                    player: "Sharon",
                    score: 121,
                  },
                ],
                winningPlayer: ["Sharon"],
                winningPlayerScore: 121,
              },
              {
                dateFinished: new Date("2023-10-07T16:42:32.857Z"),
                gameFinished: true,
                gameNumber: 6,
                scores: [
                  {
                    player: "Rosa",
                    score: 103,
                  },
                  {
                    player: "Sharon",
                    score: 121,
                  },
                ],
                winningPlayer: ["Sharon"],
                winningPlayerScore: 121,
              },
            ],
            gdID: "id4",
            gdName: "gdInsert.custGeneric",
            hands: [
              {
                dateFinished: new Date("2023-10-07T16:41:08.817Z"),
                gameNumber: 1,
                handNumber: 0,
                scores: [
                  {
                    player: "Rosa",
                    score: 121,
                  },
                  {
                    player: "Sharon",
                    score: 112,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T16:41:19.757Z"),
                gameNumber: 2,
                handNumber: 0,
                scores: [
                  {
                    player: "Rosa",
                    score: 121,
                  },
                  {
                    player: "Sharon",
                    score: 118,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T16:41:39.167Z"),
                gameNumber: 3,
                handNumber: 0,
                scores: [
                  {
                    player: "Rosa",
                    score: 105,
                  },
                  {
                    player: "Sharon",
                    score: 121,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T16:42:00.237Z"),
                gameNumber: 4,
                handNumber: 0,
                scores: [
                  {
                    player: "Rosa",
                    score: 121,
                  },
                  {
                    player: "Sharon",
                    score: 96,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T16:42:20.053Z"),
                gameNumber: 5,
                handNumber: 0,
                scores: [
                  {
                    player: "Rosa",
                    score: 109,
                  },
                  {
                    player: "Sharon",
                    score: 121,
                  },
                ],
              },
              {
                dateFinished: new Date("2023-10-07T16:42:32.855Z"),
                gameNumber: 6,
                handNumber: 0,
                scores: [
                  {
                    player: "Rosa",
                    score: 103,
                  },
                  {
                    player: "Sharon",
                    score: 121,
                  },
                ],
              },
            ],
            lastDealer: 0,
            players: [
              {
                dealOrder: 0,
                id: "f8IBKMkGvbqPggbqgARF",
                name: "Rosa",
                team: "0",
              },
              {
                dealOrder: 1,
                id: "QYEjSMHiolDFiZ6TcaR8",
                name: "Sharon",
                team: "0",
              },
            ],
            scoreCardName:
              "Thursday Nights - Cribbage Games History - Rosa vs Sharon",
            siteMemberID: "Em73LzpnzWayz3UT22AL53pd4Vi2",
            siteMemberName: "Demo User",
            teams: [
              {
                name: "Smith",
              },
            ],
            vueFileToLoad: "GenericScoreCard.vue",
          },
        },
      ],
      demoUserSiteMemberData: [
        {
          Em73LzpnzWayz3UT22AL53pd4Vi2: {
            transaction: [
              {
                amountPaid: 0,
                dateExpires: new Date("2073-10-07T04:00:57.124Z"),
                datePurchase: new Date("2023-10-07T04:00:57.124Z"),
                ppOrder: {
                  transaction: null,
                },
                userEmail: "demouser@demo.com",
              },
            ],
          },
        },
      ],
    };
  },

  getters: {},
  mutations: {},

  //Database reads/writes are in actions
  actions: {
    initDemoUser({ state }) {
      //Write clubs
      writeDocs(state.demoUserClubsData, "cardClubs");
      //Write players
      writeDocs(state.demoUserPlayersData, "clubPlayers");
      //Write score cards
      writeDocs(state.demoUserScoreCardsData, "scoreCards");
      //Write players
      writeDocs(state.demoUserSiteMemberData, "siteMembers");
    },
  },
};

async function writeDocs(data, collectionName) {
  const batch = writeBatch(db);
  data.forEach((cc) => {
    const topLevelName = Object.keys(cc)[0];
    let myID = Object.keys(cc)[0];
    const docRef = doc(db, collectionName, myID);
    let ccData = cc[topLevelName];
    batch.set(docRef, ccData);
  });

  await batch.commit();
}

export default initDemoModule;
