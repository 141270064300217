<template>
  <!-- This Vue 3 template consists of a card component that displays a status line text and a button to create a new scorecard. The card is divided into two columns, with the first column displaying the status line text and the second column containing the button.

The template starts with a `<v-card>` component, which has a class of `cardHeaderSection01`. Inside the card, there is a `<v-container>` component with a class of `pa-0` and a `fluid` attribute, ensuring that the container takes up the full width of its parent.

Within the container, a `<v-row>` component is used to create a row with the `no-gutters` attribute, which removes the gutters between columns. The row also has a class of `justify-center`, which centers the columns horizontally.

The first column, `<v-col>`, has a class that sets the text style based on screen size, such as `text-subtitle-1`, `text-sm-h6`, `text-md-h6`, `text-lg-h5`, and `text-xl-h4`. It also has a class of `titleTextLight-Caps` and `font-weight-bold` to style the text. The text content is bound to the `statusLineText` data property using the mustache syntax `{{ statusLineText }}`.

The second column contains a `<v-btn>` component, which has a click event listener that triggers the `createNewScoreCard` method. The button is styled with a class of `elevation-2` and has a conditional class based on the value of `store.getters['readyToCreateNewScoreCard']`. If `readyToCreateNewScoreCard` is true, the button will have a class of `stdBtn-enabled`; otherwise, it will have a class of `stdBtn-disabled`. The button is also disabled if `readyToCreateNewScoreCard` is false. The button's text is set using the `t()` function with the argument `"scSetupStatus.createNew"`.

Overall, this template creates a card that displays a status line text and a button to create a new scorecard, with the button being enabled or disabled based on the value of `readyToCreateNewScoreCard`. -->
  <TheStandardHeaderRow :headerLevel="1" :rightColumnNumber="1">
    <template v-slot:title> {{ statusLineText }} </template>
    <template v-slot:icons>
      <v-btn
        @click="createNewScoreCard"
        class="elevation-2"
        :size="smAndDown ? 'x-small' : 'small'"
        :disabled="!store.getters['readyToCreateNewScoreCard']"
        :class="
          store.getters['readyToCreateNewScoreCard']
            ? 'stdBtn-enabled'
            : 'stdBtn-disabled'
        "
        >{{ t("scSetupStatus.createNew") }}</v-btn
      ></template
    >
  </TheStandardHeaderRow>
</template>

<script setup>
  import TheStandardHeaderRow from "../reusable/TheStandardHeaderRow.vue";
  import { inDemoMode } from "../composables/demoModeHelpers";
  import { useStore } from "vuex";
  import { watch, ref, onMounted, onActivated } from "vue";
  import { useI18n } from "vue-i18n";
  import { useDisplay } from "vuetify";

  let { smAndDown } = useDisplay();
  const store = useStore();

  //Load i18n for working with translations
  let { t } = useI18n({});

  let statusLineText = ref("--");
  let statusLineParts = {
    prefix: t("scSetupStatus.prefix") + ": ",
    cc: "--",
    cg: "--",
    cp: "--",
  };

  /* Watch state value, change indicates the selected card club has changed.
            Update the status line to show changed card club */
  watch(
    () => store.getters["cc/cardClubID"],
    () => {
      buildStatusLineString();
    }
  );

  /* Watch state value, change indicates the selected game has changed.
            Update the status line to show changed game */
  watch(
    () => store.getters["cg/gdsGameDataID"],
    () => {
      buildStatusLineString();
    }
  );

  /* Watch state value, change indicates the selected players have changed.
            Update the status line to show player change */
  watch(
    () => store.getters["cp/clubPlayerSelectedChanged"],
    () => {
      buildStatusLineString();
    }
  );

  /* Builds a string for the status line based on the current state of the user selections in the club, game and players of the screen.  */
  function buildStatusLineString() {
    // Set the club name in the status line
    statusLineParts.cc =
      t("common.club") + " - " + store.getters["cc/cardClubName"];
    // Set the game name in the status line
    statusLineParts.cg =
      t("common.game") + " - " + t(store.getters["cg/gdsName"]);
    // Set the number of players selected in the status line
    statusLineParts.cp =
      t("common.Players") + " - " + store.getters["cp/playersSelected"];

    // Combine all the parts of the status line into one string and set it as the value of the statusLineText element
    statusLineText.value =
      statusLineParts.prefix +
      statusLineParts.cc +
      " / " +
      statusLineParts.cg +
      " / " +
      statusLineParts.cp;
  }

  // This function is called when the user wants to create a new score card
  function createNewScoreCard() {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.createNewScoreCard"), true)) {
      return;
    }
    // Check if the user is not a subscriber and if they already have a score card.  --- indicates that the score card names array is empty
    if (
      !store.getters["sm/isUserSubscriber"] &&
      store.getters["sch/scoreCardNames"][0] != "---"
    ) {
      // If the condition is true, show a snackbar message and return
      store.commit(
        "showSnackBar",
        t("scSetupStatus.freeAccounta") +
          store.getters["salesPrice"] +
          t("scSetupStatus.freeAccountb")
      );

      return;
    }
    //Write team names to club in case they have changed or have not been written yet.
    /* let teamNames = store.getters["cc/cardClubTeamNames"];
    saveStorage("teamNames", teamNames);
    store.dispatch("cc/writeTeamNames", teamNames); */

    //Commit mutations to the store to indicate that the score card is loading and is a new score card
    store.commit("sch/scoreCardLoading");
    store.commit("sch/scoreCardOrigination", "new");
    // Dispatch an action to create the score card header
    store.dispatch("sch/createScoreCard");
  }

  /* Build the status line string anytime the compoenent is mounted or activated */
  onMounted(() => {
    buildStatusLineString();
  });

  onActivated(() => {
    buildStatusLineString();
  });
</script>

<style lang="scss" scoped></style>
