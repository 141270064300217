<template>
  <!-- Display header to games list -->
  <TheStandardHeaderRow :headerLevel="2" :rightColumnNumber="3">
    <template v-slot:title> {{ t("gdInsert.selectAGame") }} </template>
    <template v-slot:icons>
      <TheStandardSearchBox
        :sortArray="gamesData"
        :translateNames="true"
        :placeHolderText="t('gdInsert.searchGames')"
        @updatedArray="updatedArray"
      ></TheStandardSearchBox>
    </template>
  </TheStandardHeaderRow>

  <!-- List all the games passed in via a prop. The list may be a subset of all games based on any search filter set in the UI -->

  <v-card class="cardBodySection04">
    <div class="scrollZone">
      <v-list
        v-for="(item, index) in gamesDataFiltered"
        :key="item.id"
        :index="index"
        lines="one"
        hide-details="true"
        density="compact"
        class="pb-0 pt- card-body"
      >
        <v-list-item
          density="compact"
          :class="item.selected ? 'listItemRow-selected' : 'listItemRow'"
        >
          <!-- Row to display individual games to select -->
          <v-row
            :dense="true"
            :no-gutters="true"
            class="d-flex align-center my-auto my-auto pa-0"
          >
            <!-- Column to display select a club radio button -->
            <v-col
              cols="1"
              class="text-left align-center my-auto mr-2 pa-0 pl-1"
            >
              <v-radio-group
                v-model="radioGroup"
                hide-details="true"
                density="compact"
              >
                <v-tooltip location="top" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-radio
                      :value="item.id"
                      v-bind="props"
                      class="my-auto py-auto"
                      @click="radioSelected(item.id)"
                    >
                    </v-radio>
                  </template>
                  <p class="ttText">
                    {{ t("gdInsert.ttClickToSelect") + " " + t(item.name) }}
                  </p>
                </v-tooltip>
              </v-radio-group>
            </v-col>

            <!-- Column to display Game name -->
            <v-col class="text-left my-auto">
              <v-list-item-title
                hide-details="true"
                class="text-h6 text-sm-h6 text-md-h6 text-lg-h5 text-xl-h4 font-weight-bold"
                :class="
                  item.selected ? 'listItemText-selected' : 'listItemText'
                "
              >
                {{ t(item.name) }}
                <!-- Only display link if link exists for game in db -->
                <a
                  v-if="item.infoLink != ''"
                  :href="item.infoLink"
                  target="_blank"
                  class="text-body-2"
                >
                  {{ t("gdInsert.moreInfo") }}
                </a>
              </v-list-item-title>
            </v-col>
            <!-- column for chosing teams or individual players.  Only show if game allows both team and individual play -->
            <v-col cols="12" class="text-left my-auto pt-0">
              <!-- Row with column to select teams or individuals -->
              <v-row
                v-if="
                  item.gamePlayedByIndividuals &&
                  item.gamePlayedByTeams &&
                  item.selected
                "
                :dense="true"
                :no-gutters="true"
                class="d-flex align-start ma-0 pa-0 pl-4"
              >
                <v-radio-group
                  :inline="true"
                  v-model="tOrIradioGroup"
                  hide-details="true"
                  density="compact"
                >
                  <v-col class="text-left ma-0 pa-0">
                    <v-tooltip location="top right" open-delay="1000">
                      <template v-slot:activator="{ props }">
                        <v-radio
                          :label="t('gdInsert.playWithTeams')"
                          v-bind="props"
                          value="teams"
                          key="teams"
                          :class="
                            tOrIradioGroup == 'teams'
                              ? 'listItemText-selected'
                              : 'listItemText'
                          "
                          @click="tOrIRadioSelected('teams', $event)"
                        >
                        </v-radio>
                      </template>
                      <p class="ttText">
                        {{ t("gdInsert.ttSelectToCreateTeams") }}
                      </p>
                    </v-tooltip>
                  </v-col>
                  <v-col class="text-left ma-0 pa-0">
                    <v-tooltip location="top right" open-delay="1000">
                      <template v-slot:activator="{ props }">
                        <v-radio
                          :label="t('gdInsert.playWithIndividuals')"
                          v-bind="props"
                          value="individuals"
                          key="individuals"
                          @click="tOrIRadioSelected('individuals', $event)"
                          :class="
                            tOrIradioGroup == 'individuals'
                              ? 'listItemText-selected'
                              : 'listItemText'
                          "
                        >
                        </v-radio>
                      </template>
                      <p class="ttText">
                        {{ t("gdInsert.ttSelectToCreateIndividuals") }}
                      </p>
                    </v-tooltip>
                  </v-col>
                </v-radio-group>
              </v-row>

              <!-- Row to set whether high score or low score wins. This row is only shown when creating a generic score card -->
              <v-row
                :dense="true"
                :no-gutters="true"
                class="d-flex ma-0 pa-0 pl-4"
                v-if="
                  item.moreRows.hasMoreRows &&
                  item.moreRows.name === 'generic' &&
                  item.selected
                "
              >
                <v-radio-group
                  :inline="true"
                  v-model="gameEnds.winningScore"
                  hide-details="true"
                  density="compact"
                >
                  <v-col class="text-left ma-0 pa-0">
                    <v-radio
                      :label="t('gdInsert.highScoreWins')"
                      value="high"
                      key="high"
                      @click="winningScore('high', $event)"
                      class="mr-1"
                      :class="
                        gameEnds.winningScore == 'high'
                          ? 'listItemText-selected'
                          : 'listItemText'
                      "
                    >
                      <v-tooltip
                        activator="parent"
                        location="start"
                        open-delay="1000"
                        ><p class="ttText">
                          {{ t("gdInsert.ttcreateWinnerHighestScore") }}
                        </p>
                      </v-tooltip>
                    </v-radio>
                  </v-col>
                  <v-col class="ma-0 pa-0 text-left">
                    <v-radio
                      :label="t('gdInsert.lowScoreWins')"
                      value="low"
                      key="low"
                      @click="winningScore('low', $event)"
                      :class="
                        gameEnds.winningScore == 'low'
                          ? 'listItemText-selected'
                          : 'listItemText'
                      "
                    >
                      <v-tooltip
                        activator="parent"
                        location="top"
                        open-delay="1000"
                        ><p class="ttText">
                          {{ t("gdInsert.ttcreateWinnerLowestScore") }}
                        </p>
                      </v-tooltip>
                    </v-radio>
                  </v-col>
                </v-radio-group>
              </v-row>

              <!-- Row with in column to select how game ends points or rounds.  This row is only shown when creating a generic score card-->
              <v-row
                v-if="
                  item.moreRows.hasMoreRows &&
                  item.moreRows.name === 'generic' &&
                  item.selected
                "
                :dense="true"
                :no-gutters="true"
                class="d-flex align-center ma-0 mb-1 pa-0 pl-4"
              >
                <v-radio-group
                  :inline="true"
                  v-model="gameEnds.howGameEnds"
                  hide-details="true"
                  density="compact"
                >
                  <v-col class="text-left ma-0 pa-0">
                    <v-row class="d-flex align-center ma-0 pa-0">
                      <v-radio
                        :label="t('gdInsert.endsAfterRounds')"
                        value="rounds"
                        key="rounds"
                        :class="
                          gameEnds.howGameEnds == 'rounds'
                            ? 'listItemText-selected'
                            : 'listItemText'
                        "
                        @click="howGameEndsRadio('rounds', $event)"
                      >
                        <v-tooltip
                          activator="parent"
                          location="start"
                          open-delay="1000"
                          ><p class="ttText">
                            {{ t("gdInsert.ttEndsAfterRounds") }}
                          </p>
                        </v-tooltip>
                      </v-radio>

                      <input
                        type="number"
                        id="roundsToEnd"
                        v-model.number="gameEnds.roundsToEnd"
                        @change="howGameEndsChanged"
                        class="text-center htmlTextFieldStandard w-25 my-auto py-auto ml-2 elevation-1"
                        :class="
                          gameEnds.howGameEnds == 'rounds'
                            ? 'htmlTextFieldStandard'
                            : 'htmlTextFieldStandardDisabled'
                        "
                        :readonly="
                          gameEnds.howGameEnds == 'rounds' ? false : true
                        "
                      />
                    </v-row>
                  </v-col>
                  <v-col class="text-left ma-0 pa-0">
                    <v-row class="d-flex align-center ma-0 pa-0">
                      <v-radio
                        :label="t('gdInsert.endsWhenPointsReached')"
                        value="points"
                        key="points"
                        @click="howGameEndsRadio('points', $event)"
                        :class="
                          gameEnds.howGameEnds == 'points'
                            ? 'listItemText-selected'
                            : 'listItemText'
                        "
                      >
                        <v-tooltip
                          activator="parent"
                          location="start"
                          open-delay="1000"
                          ><p class="ttText">
                            {{ t("gdInsert.ttEndsWhenPointsReached") }}
                          </p>
                        </v-tooltip>
                      </v-radio>

                      <input
                        type="number"
                        id="pointsToEnd"
                        v-model.number="gameEnds.pointsToEnd"
                        @change="howGameEndsChanged"
                        :readonly="
                          gameEnds.howGameEnds == 'points' ? false : true
                        "
                        class="text-center htmlTextFieldStandard w-25 my-auto py-auto ml-2 elevation-1"
                        :class="
                          gameEnds.howGameEnds == 'points'
                            ? 'htmlTextFieldStandard'
                            : 'htmlTextFieldStandardDisabled'
                        "
                      />
                    </v-row>
                  </v-col>
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script setup>
  import TheStandardHeaderRow from "../reusable/TheStandardHeaderRow.vue";
  import { ref, watch, onMounted } from "vue";
  import { useStore } from "vuex";
  import { getStorage } from "../composables/localStorage";
  import { useI18n } from "vue-i18n";
  import TheStandardSearchBox from "../reusable/TheStandardSearchBox.vue";

  //create function to insert i18N text
  let { t } = useI18n({});

  //get vuex store
  const store = useStore();

  //get game data from store
  let gamesData = ref([]);

  //create a variable to hold the filtered list of game data from store
  let gamesDataFiltered = ref([]);

  //create a variable to hold the radio button group value of the selected
  let radioGroup = ref(null);

  /* Setup values for games play by teams or individuals using either what was stored in local storage or default values in nothing in local storage  */

  // Retrieve the value of "gamePlayedBy" from local storage
  let savedTeamOrIndividual = getStorage("gamePlayedBy");

  // Check if the retrieved value is undefined
  if (savedTeamOrIndividual === undefined || savedTeamOrIndividual === null) {
    // If undefined, set the default value to "teams"
    savedTeamOrIndividual = "teams";
  } else {
    // If not undefined, parse the JSON string to an object
    savedTeamOrIndividual = JSON.parse(savedTeamOrIndividual);
  }

  // Create a reference to the radio group using the saved value
  let tOrIradioGroup = ref(savedTeamOrIndividual);

  /* Setup how game ends radio buttons and text fields (used on generic score card) to the values last written to local storage. Check for undefined in case data has never been written and set values to default    */
  let gameEnds = ref({
    howGameEnds: "rounds",
    roundsToEnd: 10,
    pointsToEnd: 100,
    winningScore: "high",
  });

  // Retrieve saved game settings from local storage
  let savedHowGameEnds = getStorage("howGameEnds");

  // Check if saved settings exist and update gameEnds object accordingly
  if (savedHowGameEnds != "undefined" && savedHowGameEnds != null) {
    // Parse saved settings from JSON string
    savedHowGameEnds = JSON.parse(savedHowGameEnds);

    // Update gameEnds object with saved settings
    gameEnds.value.howGameEnds = savedHowGameEnds.howGameEnds;
    gameEnds.value.roundsToEnd = savedHowGameEnds.roundsToEnd;
    gameEnds.value.pointsToEnd = savedHowGameEnds.pointsToEnd;
    gameEnds.value.winningScore = savedHowGameEnds.winningScore;
  }

  // Commit updated gameEnds object to the store
  store.commit("cg/gameEnds", gameEnds.value);

  /* Watch for Game data to be loaded from db. After loaded copy game data from store to display list and set the game selected radio button of the game that was last selected in the database */
  watch(
    () => store.getters["cg/gamesDataLoaded"],
    () => {
      // Copy game data from store to display list
      loadGameData();
    }
  );

  /* Watch for card clubs to be loaded from db. After clubs are loaded, call function to load game data.  Loading game data after the clubs are loaded allows the game last selected in the selected club to be read */
  watch(
    () => store.getters["cc/cardClubsDataLoaded"],
    () => {
      /* Load game data after card clubs have been loaded.  Allows
          last selected game in card club to be available */
      store.dispatch("cg/loadGamesData");
    }
  );

  /* When the card game id is changed, send the changes to the db and radio buttons in the games list. gameDataID changes when card clubs are loaded (from last game selected field), when the user selects a new card club (from last game selected field) or when a new game is selected by the user with the radio buttons */
  watch(
    () => store.getters["cg/gameDataID"],
    (newID) => {
      // Update the radioGroup value with the new gameDataID
      radioGroup.value = store.getters["cg/gameDataID"];
      // Dispatch an action to update the selected game data with the new ID
      store.dispatch("cg/updateSelectedGameData", newID);

      // Create a payload object with the new game ID and the current card club ID
      let payLoad = {
        cgID: newID,
        ccID: store.getters["cc/cardClubID"],
      };

      // If card clubs not yet loaded, do not update the last game selected
      if (payLoad.ccID === "none") {
        return;
      }

      // Dispatch an action to update the last game selected with the payload
      store.dispatch("cc/updateLastGameSelected", payLoad);

      // Load played by flags from the game database
      let pbt = store.getters["cg/gdsGamePlayedByTeams"];
      let pbi = store.getters["cg/gdsGamePlayedByIndividuals"];

      // If the game can be played by both teams and individuals, load the user's selection
      if (pbt && pbi) {
        store.commit("cg/gamePlayedBy", tOrIradioGroup.value);
      } else if (pbt) {
        // If the game can only be played by teams, set the playedBy value to "teams"
        store.commit("cg/gamePlayedBy", "teams");
      } else {
        // If the game can only be played by individuals, set the playedBy value to "individuals"
        store.commit("cg/gamePlayedBy", "individuals");
      }

      //Load translated name of newly selected game to store
      store.commit("cg/setGameTranslatedName", t(store.getters["cg/gdsName"]));
    }
  );

  /* This function loads and sets up the game data when the compoenent is loaded or refreshed */
  function loadGameData() {
    /* Load games list from db.  This will also load the last game selected
      for each card club */
    gamesData.value = store.getters["cg/gamesData"];

    // Sort the games list alphabetically.  The games list is sorted by the game name in the database.  The game name is translated when displayed on the screen.  The sort function uses the translated name to sort the list
    gamesData.value.sort((a, b) => {
      const nameA = t(a.name); // Translate the name of 'a'
      const nameB = t(b.name); // Translate the name of 'b'

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    /* The games list displayed on the screen is the filtered list.  When first loading the lists are set to be the same.  If the user types a filter string, the filter list will be a subset of the games list */
    gamesDataFiltered.value = gamesData.value;

    //Set the game selected radio button to the last selected game
    radioGroup.value = store.state.cg.gameDataID;
  }

  /* This function handles clicks to the how game ends radio buttns.  */
  function howGameEndsRadio(payload) {
    /* Save which howGameEnds radio button is selected (rounds or points). Also save to local storage for recall when screen is refreshed */
    gameEnds.value.howGameEnds = payload;
    store.commit("cg/gameEnds", gameEnds.value);
  }

  /* This function saves data from enter points or enter rounds text boxes*/
  function howGameEndsChanged() {
    /* Save enter rounds or enter points text boxes to store.  The data will also be written to local storage for recall when screen is refreshed  */
    store.commit("cg/gameEnds", gameEnds.value);
  }

  /* This function saves the state of winning score radio buttons */
  function winningScore(payload) {
    /* Save wich winningScore radio button is selected (high or low). The data will also be written to local storage for recall when screen is refreshed */
    gameEnds.value.winningScore = payload;
    store.commit("cg/gameEnds", gameEnds.value);
  }

  //Change the Card Club Selected to the one selected by the radio button
  function radioSelected(id) {
    //change selected id. Watcher for this state will dispatch update to database
    if (store.getters["cg/gamesDataLoaded"] === true) {
      store.state.cg.gameDataID = id;
    }
  }

  /* This function services changes to the teams or individual radio buttons */
  function tOrIRadioSelected(selection) {
    // Save the selection to the store
    store.commit("cg/gamePlayedBy", selection);
  }

  /* Called by the filter array component when the list of games has been changed.  Copy the new filtered list over the list being displayed to update the display */
  const updatedArray = (filteredArray) => {
    gamesDataFiltered.value = filteredArray;
  };

  /* When the compoennt is mounted, load the games list data */
  onMounted(() => {
    //load game data from the store
    loadGameData();
  });
</script>

<style scoped lang="scss"></style>
