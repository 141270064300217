<template>
  <div>
    <v-dialog
      v-model="showD"
      :max-width="smAndDown ? '90%' : dialogSize"
      max-height="90%"
      transition="custom-from-bottom-transition"
      scrollable
      persistent
    >
      <v-card class="card-body w-100">
        <v-card-title
          class="card-header-text card-header text-h6 text-sm-h6 text-md-h5 pb-2 font-weight-bold"
          >{{ titleText }}</v-card-title
        >

        <v-card-text
          v-if="insertText != 'not-used'"
          class="font-weight-bold text-h6"
          >{{ insertText }}</v-card-text
        >
        <v-card-text
          v-if="explanationText != 'not-used'"
          class="font-italic font-weight-regular text-subtitle-1 text-sm-h6 text-md-h6"
          >{{ explanationText }}</v-card-text
        >

        <v-card-text class="text-subtitle-1 text-sm-h6 text-md-h6 w-100"
          ><slot name="dSlot" class="w-100"> </slot
        ></v-card-text>

        <v-card-actions class="card-field-width ma-0 pa-0">
          <v-spacer></v-spacer>
          <v-btn
            v-if="leftBtnText != 'not-used'"
            @click="$emit('leftBtn')"
            class="elevation-2 stdBtn mr-4 pa-0"
            :size="smAndDown ? 'x-small' : 'small'"
            :disabled="leftBtnDisabled"
            >{{ leftBtnText }}</v-btn
          >

          <v-btn
            v-if="rightBtnText != 'not-used'"
            @click="$emit('rightBtn')"
            class="elevation-2 stdBtn ml-0 pa-0"
            :size="smAndDown ? 'x-small' : 'small'"
            :disabled="rightBtnDisabled"
            >{{ rightBtnText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import { ref, watch } from "vue";
  import { useDisplay } from "vuetify";

  //Load reactive true or false for small and down display size
  let { smAndDown } = useDisplay();

  const props = defineProps({
    showDialog: {
      type: Boolean,
      required: true,
    },
    titleText: {
      type: String,
      required: true,
    },
    insertText: {
      type: String,
      required: true,
    },
    explanationText: {
      type: String,
      required: true,
    },
    leftBtnText: {
      type: String,
      required: true,
    },
    leftBtnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightBtnText: {
      type: String,
      required: true,
    },
    rightBtnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialogWidth: {
      type: String,
      required: false,
      default: "medium",
    },
  });

  let dialogSize = ref("");
  if (props.dialogWidth === "large") {
    dialogSize.value = "90%";
  } else if (props.dialogWidth === "medium") {
    dialogSize.value = "70%";
  } else if (props.dialogWidth === "small") {
    dialogSize.value = "50%";
  }

  let showD = ref(props.showDialog);

  watch(
    () => props.showDialog,
    (newVal) => {
      showD.value = newVal;
    }
  );
</script>

<style lang="scss"></style>
