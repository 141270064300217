<template>
  <v-card class="mx-auto pa-0" :elevation="elev" :rounded="0">
    <v-card-title class="ma-0 pa-0" :class="[className, cardMargin]">
      <v-container class="pa-0" fluid>
        <v-row :no-gutters="true" class="d-flex justify-start ma-0 py-auto">
          <v-col
            v-if="firstColumnSide == 'left'"
            :cols="firstColumnWidth"
            class="text-left pl-2 pr-2 ma-0 my-auto text-truncate"
            :class="{
              'text-caption': compactLayout,
              'text-caption text-md-h6': !compactLayout,
              borderRight: borderRight,
              borderTop: borderTop,
              cardBodySectionColor02: firstColHighlighted,
            }"
          >
            <slot name="firstColumn">
              <!-- insert components -->
            </slot>
          </v-col>

          <!-- Remaining columns -->
          <v-col
            :cols="rColWidth"
            class="text-left ma-0 my-auto text-truncate"
            :class="{
              'text-caption': compactLayout,
              'text-caption text-md-h6': !compactLayout,
              borderRight: borderRight,
              borderTop: borderTop,
              cardBodySectionColor01: rl1,
              cardBodySectionColor02: rl2,
              cardBodySectionColor03: rl3,
              cardBodySectionColor04: rl4,
            }"
          >
            <!-- When using data passed in -->
            <v-row
              v-if="useData"
              :no-gutters="true"
              class="d-flex justify-start ma-0 py-auto"
            >
              <v-col
                v-for="(d, i) in data"
                :key="i"
                :class="{
                  'text-caption': compactLayout,
                  'text-caption text-md-h6': !compactLayout,
                  borderRight: borderRight,
                  borderTop: borderTop,
                  cardBodySectionColor01: rl1,
                  cardBodySectionColor02: rl2,
                  cardBodySectionColor03: rl3,
                  cardBodySectionColor04: rl4,
                }"
                class="ma-0 pa-0 text-center"
              >
                {{ d[item] }}
              </v-col>
            </v-row>

            <!-- When using data inserted into slot -->
            <v-row
              v-if="!useData"
              :no-gutters="true"
              class="d-flex justify-start ma-0 py-auto"
            >
              <slot name="remainingColumns">
                <!-- insert components -->
              </slot>
            </v-row>
          </v-col>

          <!-- Used when first column is moved to right side -->
          <v-col
            v-if="firstColumnSide == 'right'"
            :cols="firstColumnWidth"
            class="text-right pr-2 my-auto text-truncate ma-0"
            :class="{
              'text-caption font': compactLayout,
              'text-caption text-md-h6': !compactLayout,
            }"
          >
            <slot name="firstColumn">
              <!-- insert components -->
            </slot>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
  </v-card>
</template>

<script setup>
  import { ref } from "vue";
  const props = defineProps({
    rowLevel: { type: Number, default: 1 },
    firstColHighlighted: { type: Boolean, default: false },
    compactLayout: { type: Boolean, default: false },
    borderRight: { type: Boolean, default: false },
    borderTop: { type: Boolean, default: false },
    firstColumnWidth: { type: Number, default: 2 },

    firstColumnSide: { type: String, default: "left" },
    cardMargin: { type: String, default: "mb-1" },
    elev: { type: String, default: "0" },
    useData: { type: Boolean, default: false },
    data: { type: Array, default: null },
    item: { type: String, default: null },
  });

  let rColWidth = ref(12 - props.firstColumnWidth);

  let className = ref("cardBodySection01");

  let rl1 = ref(false);
  let rl2 = ref(false);
  let rl3 = ref(false);
  let rl4 = ref(false);

  if (props.rowLevel === 1) {
    className.value = "cardBodySection01";
    rl1.value = true;
  } else if (props.rowLevel === 2) {
    className.value = "cardBodySection02";
    rl2.value = true;
  } else if (props.rowLevel === 3) {
    className.value = "cardBodySection03";
    rl3.value = true;
  } else if (props.rowLevel === 4) {
    className.value = "cardBodySection04";
    rl4.value = true;
  }

  /* const props = defineProps({
    cardColor: { type: String, default: "#EDEDED" },
    rowColor: { type: String, default: "#68868c" },
    mt: { type: Number, default: 1 },
    mb: { type: Number, default: 1 },
  }); */

  //Set supplied or default colors for the card and row
  let rColor = ref(props.rowColor);
  let cColor = ref(props.cardColor);
  //let margin = ref("mt-" + props.mt + " " + "mb-" + props.mb);

  /*   if (props.borderRight) {
    let borderRight = ref("borderRight");
  } else {
    let borderRight = ref("");
  } */
</script>

<style lang="scss" scoped>
  .rowColor {
    background-color: v-bind(rColor);
  }
  .cardColor {
    background-color: v-bind(cColor);
  }

  .firstColHighlighted {
    background-color: red;
    color: white;
  }
</style>
