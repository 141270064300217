import {
  writeBatch,
  getDocs,
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";
//NOTE update to remove unused firebase imports
import { db } from "../firebaseInit";

//Collection reference used by this file
const clubPlayersCollection = collection(db, "clubPlayers");

const clubPlayersModule = {
  namespaced: true,
  state() {
    return {
      /* All Players that are member of the selected card club */
      clubPlayers: [
        /*        {
          ccOwner: null,
          dateCreated: null,
          name: "",
          nickname: "",
          selected: false,
          team: 0,
        }, */
      ],

      /* Players that are members of the selected card club and are selected 
      TODO implement using this array.  May include teams*/
      clubPlayersSelected: [],

      /* Stores teams and players */
      clubPlayerTeamsWithPlayers: [],

      /* Changed any time the number of checked/selected player is new or has changed.  
      Monitored by watchers, changed value does not matter */
      clubPlayerSelectedChanged: null,

      /* ID of the player with the list item highlighed
      NOTE not sure if this will be used */
      clubPlayerID: null,

      /* Set to true when club player data is loaded
      TODO add setting this state to CC loading 
      NOTE not sure how/if this will be used */
      clubPlayerDataLoaded: false,

      /* Team names and entered by user.  May need to convert to array if
      significantly more teams are needed */
      /* teamNames: [
        { name: "Team 1", inputError: false },
        { name: "Team 2", inputError: false },
        { name: "Team 3", inputError: false },
        { name: "Team 4", inputError: false },
      ], */
    };
  },

  getters: {
    //Returns a changed value when the selected players changes.  Used by watchers
    clubPlayerSelectedChanged(state) {
      return state.clubPlayerSelectedChanged;
    },

    /* Returns the name and ID of all selected players */
    cpsAllNamesAndID(state) {
      //Filter out the unselected players
      let cpSelected = state.clubPlayers.filter((cp) => cp.selected === true);
      //If no players are selected return 0
      if (cpSelected.length === 0) {
        return 0;
      }
      //Create an array of objects with the name, ID, team and deal order for each selected player
      let players = [];
      cpSelected.forEach((cp, index) => {
        let p = { id: cp.id, name: cp.name, team: cp.team, dealOrder: index };
        players.push(p);
      });
      return players;
    },

    /* Returns an array of team names that have players linked to them */
    cpsTeamNames(state, getters, rootState, rootGetters) {
      let tNames = [];
      let teamNames = rootGetters["cc/cardClubTeamNames"];

      teamNames.forEach((team, i) => {
        let teamHasPlayers = state.clubPlayers.filter(function (cp) {
          if (cp.selected === true && parseInt(cp.team) === i) {
            return true;
          } else {
            return false;
          }
        });
        if (teamHasPlayers.length != 0) {
          let p = { name: team.name };
          tNames.push(p);
        }
      });

      return tNames;
    },

    /* Returns the number of players selected in the clubPlayers array.
    This returns the selected players for individual type game or the Team 1 players for a team type game.*/
    cpNumberSelectedT: (state) => (team) => {
      // Filter out the unselected players
      let cpSelected = state.clubPlayers.filter(
        (cp) => cp.selected === true && cp.team === team
      );
      // If no players are selected return 0
      if (cpSelected.length === 0) {
        return 0;
      }
      // Return the number of selected players
      return cpSelected.length;
    },

    // Returns the number of players currently selected
    playersSelected(state) {
      // Filter out the unselected players
      let cpSelected = state.clubPlayers.filter((cp) => cp.selected === true);

      // If no players are selected return 0
      if (cpSelected.length === 0) {
        return 0;
      }

      //
      return cpSelected.length;
    },

    //Returns the teamName variable
    teamNames(state) {
      return state.teamNames;
    },

    clubPlayers(state) {
      return state.clubPlayers;
    },

    /* Check to see if a player name already exists with the name passed in.  Return number of duplicates */
    isPlayerNameDuplicate: (state) => (name) => {
      let ccSelected = state.clubPlayers.filter(
        (cc) => cc.name.trim().toUpperCase() === name.trim().toUpperCase()
      );

      return ccSelected.length;
    },
  },

  /* Display object updates are made in mutations.  There will usually be mutation  to update the display object for most actions to read or update the database*/
  mutations: {
    /* Load club players to display object */
    loadClubPlayers(state, cp) {
      //Sort players by name before loading to display array
      cp.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      //Load the display object
      state.clubPlayers = cp;

      //Set data loaded to true
      state.clubPlayerDataLoaded = true;

      /* Trigger watchers to let them the number of players selected may have changed (value doesn't matter) */
      state.clubPlayerSelectedChanged = Math.random();
    },

    /* Add new club player to the memory array.  Memory array is created when card clubs is loaded and refreshed anytime a new card club is selected */
    addClubPlayer(state, cp) {
      //If adding to a newly created card club, clubPlayers will be undefined and must be created as an array
      if (state.clubPlayers === undefined) {
        state.clubPlayers = [];
      }

      //Add the new player to the array. cp is a db object
      state.clubPlayers.push({ ...cp.data(), id: cp.id });

      //Sort the display array by name
      state.clubPlayers.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      /* Trigger watchers to let them the number of players selected may have changed (value doesn't matter) */
      state.clubPlayerSelectedChanged = Math.random();
    },

    /* Delete a club player from the display array. */
    deleteClubPlayer(state, id) {
      //Filter out the deleted player from the display object
      state.clubPlayers = state.clubPlayers.filter((cp) => cp.id !== id);

      /* Trigger watchers to let them the number of players selected may have changed (value doesn't matter) */
      state.clubPlayerSelectedChanged = Math.random();
    },

    /* Update an edited club player in the display array.  Sort array, no other updates needed*/
    updateClubPlayer(state) {
      //Sort the display array by name
      state.clubPlayers.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      /* Trigger watchers to let them the number of players selected may have changed (value doesn't matter) */
      state.clubPlayerSelectedChanged = Math.random();
    },

    /* Sets the checked/unchecked status of a player in the display array. 
    Find the player in the display array and toggle the state. */
    setClubPlayerSelectedState(state, payLoad) {
      /* Payload contains the new selected state of the club player based on
      the checkbox state passed in the payload */
      let gameID = payLoad.selectedGame;
      let player = state.clubPlayers.filter((p) => p.id === payLoad.cpID);
      player[0].selected = payLoad.selected;
      player[0].team = payLoad.team;
      player[0][gameID] = {
        selected: payLoad.selected,
        team: payLoad.team,
      };

      /* Trigger watchers to let them the number of players selected may have changed (value doesn't matter) */
      state.clubPlayerSelectedChanged = Math.random();
    },
  },

  /* Actions read and write the database.  Most actions will have a corrosponding mutation that updates the display object */
  actions: {
    /* Query for associated with the passed in card club ID.  Load from db and then commit to display object. This function should be called anytime the selected card club changes*/
    async loadClubPlayers({ commit }, ccID) {
      const playersforCardClub = query(
        clubPlayersCollection,
        where("ccOwner", "==", ccID)
      );
      //Get the docs and push to an array that can be passed to the commit function
      const snapshot = await getDocs(playersforCardClub);
      let tempCC = [];
      snapshot.docs.forEach((player) => {
        tempCC.push({ ...player.data(), id: player.id });
      });

      //Commit has to be done after loading data from db
      commit("loadClubPlayers", tempCC);
    },

    /* Add a new club player to the database.  Player will be added with 
    the owner being the passed in card club */
    async addClubPlayer({ commit, rootGetters }, payLoad) {
      /* Add a new player to the db then add current games selected state default values to new player */

      //Get the ID of the currently selected game so that the players selection status for that game can be added to the new player
      let gameID = rootGetters["cg/gdsGameDataID"];

      //Add the new player record to the db
      const docRef = await addDoc(collection(db, "clubPlayers"), {
        name: payLoad.newClubPlayer.name,
        nickname: payLoad.newClubPlayer.nickname,
        dateCreated: payLoad.newClubPlayer.dateCreated,
        ccOwner: payLoad.ccID,
        selected: false,
        team: 0,
        [gameID]: {
          selected: false,
          team: 0,
        },
      });

      //Get the newly created player from the db
      let cp = await getDoc(docRef);

      /* Commit the newly created document to be added to the display object.*/
      commit("addClubPlayer", cp);
    },

    /* Delete the club player specific by ID from the database. */
    // eslint-disable-next-line
    async deleteClubPlayer({ commit }, id) {
      //Delete player by passed in ID
      const docRef = doc(db, "clubPlayers", id);
      await deleteDoc(docRef);

      commit("deleteClubPlayer", id);
    },

    /* Delete all club players associated with a card club from the database.  This functionality will be used when deleting a card club. */
    async deleteAllClubPlayers({ state, dispatch }, id) {
      //IF there are no club players defined for this card club return
      if (state.clubPlayers === undefined) {
        return;
      }

      //query for and deleted all club players owned by card club
      const clubPlayersForClub = query(
        clubPlayersCollection,
        where("ccOwner", "==", id)
      );

      //Delete all club players for the card club
      const batch = writeBatch(db);
      const snapshot = await getDocs(clubPlayersForClub);

      snapshot.forEach((cp) => {
        batch.delete(cp.ref);
      });
      await batch.commit();

      //Reload the club players
      await dispatch("loadClubPlayers", id);
    },

    /* Update an edited (name and nickname) club player in the database */
    async updateClubPlayer({ commit }, cpUpdates) {
      //find and update the club player in the db
      const docRef = doc(db, "clubPlayers", cpUpdates.id);
      await updateDoc(docRef, {
        name: cpUpdates.name,
        nickname: cpUpdates.nickname,
      });

      /*Get the newly updated club player from the db and commit to the display*/
      let cp = await getDoc(docRef);
      commit("updateClubPlayer", cp);
    },

    /* Sets the checked/unchecked status of a player in the display array. 
    Find the player in the database and update the state. The selected status is saved in the working variables (selected and team) and also written as a structure under the current gameID to allow recall of the
    selected state for that specific game at a later time. */
    async setClubPlayerSelectedState({ commit, rootGetters }, payLoad) {
      //get the ID of the currently selected game. Add to payLoad for use in the commit
      payLoad.selectedGame = rootGetters["cg/gdsGameDataID"];

      //Exit if the selected game is not defined
      if (payLoad.selectedGame === undefined || payLoad.selectedGame === null) {
        return;
      }

      //Exit if the club player ID is not defined
      if (payLoad.cpID === undefined || payLoad.cpID === null) {
        return;
      }

      //To be more responsive, update display object before changing database
      commit("setClubPlayerSelectedState", payLoad);

      /*Payload contains the new state based on the state of the checkbox.  Write the new state and selected team to the db and to a structure linked to the game ID*/
      const docRef2 = doc(db, "clubPlayers", payLoad.cpID);
      await updateDoc(docRef2, {
        selected: payLoad.selected,
        team: payLoad.team,
        [payLoad.selectedGame]: {
          selected: payLoad.selected,
          team: payLoad.team,
        },
      });
    },

    /* When a new game is selected update all the players in the currently selected club to reflect their previously selected state for the new game.  This recalls how the user had organized players into teams for any game in the club.  If the player record does not have any settings for the game selected, use defaults */
    updatePlayerForNewGameSelected({ state, dispatch }, payLoad) {
      //Exit if there are no club players
      if (state.clubPlayers.length === 0) {
        return;
      }
      let playerUpdates;
      //Loop through all club players
      state.clubPlayers.forEach((player) => {
        //If the player has a game record for the selected game, use it
        if (
          Object.prototype.hasOwnProperty.call(player, payLoad.selectedGame)
        ) {
          // Write the player updates. Read the game array within the player record and set the values in the player fields, used for display, accordingly
          playerUpdates = {
            cpID: player.id,
            selected: player[payLoad.selectedGame].selected,
            team: player[payLoad.selectedGame].team,
          };
          //call to update each player
          dispatch("setClubPlayerSelectedState", playerUpdates);
        } else {
          playerUpdates = {
            cpID: player.id,
            selected: false,
            team: 0,
          };
          //call to update each player
          dispatch("setClubPlayerSelectedState", playerUpdates);
        }
        dispatch("setClubPlayerSelectedState", playerUpdates);
      });
    },
  },
};

export default clubPlayersModule;
