<template>
  <!-- Dialog showing data that can be customized -->
  <TheStandardDialog
    dialogWidth="medium"
    :showDialog="store.getters['sch/showCustomizeGameDialog']"
    :titleText="t('customizeGame.titleText')"
    insertText="not-used"
    :explanationText="t('customizeGame.explanationText')"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('common.save')"
    @leftBtn="closeDialog"
    @rightBtn="saveCustomizableData"
  >
    <template v-slot:dSlot>
      <v-row
        v-for="(value, i) in customizableData"
        :key="i"
        :no-gutters="true"
        class="d-flex align-start my-0 pa-0"
      >
        <v-col cols="12" class="ma-0 pa-0">
          <v-checkbox
            v-if="value[1].type == 'boolean'"
            :label="t(value[1].title)"
            density="compact"
            class="pa-0 ma-0"
            :class="smAndDown ? 'w-100' : 'w-100'"
            hide-details="true"
            :model-value="value[1].value"
            v-model="value[1].value"
          >
          </v-checkbox>
          <v-text-field
            v-else
            :label="t(value[1].title)"
            class="pa-0 ma-0 mb-4"
            :class="smAndDown ? 'w-100' : 'w-75'"
            hide-details="true"
            density="compact"
            :model-value="value[1].points"
            v-model="value[1].value"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="text-caption px-2 pb-2 my-auto"
          >{{ t("" + value[1].note) }}
        </v-col>
      </v-row>
    </template>
  </TheStandardDialog>
</template>

<script setup>
  import { ref } from "vue";
  import { useStore } from "vuex";
  import TheStandardDialog from "../reusable/TheStandardDialog.vue";
  import { useI18n } from "vue-i18n";
  import { useSorted } from "@vueuse/core";
  import { useDisplay } from "vuetify";
  import { inDemoMode } from "../composables/demoModeHelpers";

  let { smAndDown } = useDisplay();

  //create function to insert i18N text
  let { t } = useI18n({});

  const store = useStore();

  //Load data from scorecard
  let scoreCard = ref(store.getters["sch/scoreCard"]);

  //create array of data to be displayed
  let customizableData = ref(Object.entries(scoreCard.value.customizable));
  //sort array by sortOrder
  useSorted(customizableData.value, (a, b) => a[1].sortOrder - b[1].sortOrder, {
    dirty: true,
  });

  //create a copy of the customizable data values to be restored if user cancels
  let restoreCustomizableData = [];

  customizableData.value.forEach((element) => {
    let v = element[1].value;
    restoreCustomizableData.push(v);
  });

  async function saveCustomizableData() {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.customizeScoreCard"), true)) {
      restoreCustomizableData.forEach((element, index) => {
        customizableData.value[index][1].value = element;
      });
      return;
    }
    //save data
    let payLoad = { customizable: scoreCard.value.customizable };
    await store.dispatch("sch/saveCustomizableData", payLoad);

    //close dialog
    store.commit("sch/showCustomizeGameDialog", false);
  }

  //close dialog
  function closeDialog() {
    //restore data
    restoreCustomizableData.forEach((element, index) => {
      customizableData.value[index][1].value = element;
    });
    //close dialog
    store.commit("sch/showCustomizeGameDialog", false);
  }
</script>

<style scoped></style>
