import { createStore } from "vuex";

// import cardClubs Module to use in createStore()
import cardClubsModule from "./cardClubs/cardClubStore";
import clubPlayersModule from "./clubPlayers/clubPlayersStore";
import initDatabaseModule from "./initDatabase/initDbStore";
import initDemoModule from "./initDatabase/initDemoUser";
import cardGamesModule from "./gamesData/gamesDataStore";
import authModule from "./auth/auth";
import scoreCardHeader from "./scoreCards/scoreCardHeaderStore";
import siteMembers from "./siteMember/siteMemberStore";

// Create references to other modules to use in
const store = createStore({
  modules: {
    cc: cardClubsModule,
    init: initDatabaseModule,
    initDemo: initDemoModule,
    cp: clubPlayersModule,
    cg: cardGamesModule,
    auth: authModule,
    sch: scoreCardHeader,
    sm: siteMembers,
  },

  state: {
    /* Variables to show and hide global dialogs */
    snackbar: { show: false, text: "No Message" },
    aboutDialog: false,
    contactInfoDialog: false,
    tAndCDialog: false,

    /* Get subsription price from local.env file */
    salesPrice: process.env.VUE_APP_ONE_YEAR_PRICE,

    //Proper number of players have been selected and now ready to play
    readyToCreateNewScoreCard: false,
  },

  getters: {
    aboutDialog(state) {
      return state.aboutDialog;
    },

    contactInfoDialog(state) {
      return state.contactInfoDialog;
    },
    tAndCDialog(state) {
      return state.tAndCDialog;
    },

    snackbarText(state) {
      return state.snackbar.text;
    },

    readyToCreateNewScoreCard(state) {
      return state.readyToCreateNewScoreCard;
    },

    salesPrice(state) {
      return state.salesPrice;
    },
  },

  mutations: {
    //Turn on the snackbar
    showSnackBar(state, msg) {
      if (state.snackbar.show === false) {
        state.snackbar.text = msg;
        state.snackbar.show = true;
      } else {
        state.snackbar.show = false;
        setTimeout(() => {
          state.snackbar.text = msg;
          state.snackbar.show = true;
        }, 2000);
      }
    },
    hideSnackbar(state) {
      state.snackbar.show = false;
    },

    readyToCreateNewScoreCard(state, value) {
      state.readyToCreateNewScoreCard = value;
    },

    aboutDialogShow(state) {
      state.aboutDialog = true;
    },
    aboutDialogHide(state) {
      state.aboutDialog = false;
    },
    contactInfoDialogShow(state) {
      state.contactInfoDialog = true;
    },
    contactInfoDialogHide(state) {
      state.contactInfoDialog = false;
    },
    tAndCDialogShow(state) {
      state.tAndCDialog = true;
    },
    tAndCDialogHide(state) {
      state.tAndCDialog = false;
    },
  },

  actions: {},
});

export default store;
