<template>
  <v-card class="card-standard">
    <v-card-title
      :class="
        props.headerLevel == 1 ? 'cardHeaderSection01' : 'cardHeaderSection02'
      "
    >
      <v-container class="pa-0" fluid>
        <v-row
          :dense="true"
          :no-gutters="true"
          class="d-flex justify-center align-center"
        >
          <v-col
            :cols="titleCols"
            :offset="titleColsOffset"
            class="text-body-1 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h3 titleTextLight-Caps font-weight-bold d-flex justify-center align-center text-wrap lineHeight_1"
          >
            <slot name="title"> </slot>
          </v-col>

          <v-col
            :cols="iconCols"
            class="d-flex align-center justify-center"
            :class="smAndDown ? 'justify-center' : 'justify-end'"
          >
            <slot name="icons"> </slot>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
  </v-card>
</template>

<script setup>
  import { ref } from "vue";
  import { useDisplay } from "vuetify";
  let { smAndDown } = useDisplay();

  const props = defineProps({
    headerLevel: { type: Number, default: 1 },
    rightColumnNumber: { type: Number, default: 1 },
  });

  let titleCols = ref(0);
  let iconCols = ref(0);
  let titleColsOffset = ref(0);

  if (props.rightColumnNumber === 3) {
    titleCols.value = 6;
    iconCols.value = 3;
    titleColsOffset.value = 3;
  } else if (props.rightColumnNumber === 2) {
    titleCols.value = 8;
    iconCols.value = 2;
    titleColsOffset.value = 2;
  } else if (props.rightColumnNumber === 1) {
    titleCols.value = 10;
    iconCols.value = 1;
    titleColsOffset.value = 1;
  }
</script>

<style lang="scss" scoped></style>
