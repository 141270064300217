import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import store from "./store";

import { loadFonts } from "./plugins/webfontloader";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = createVuetify({
  components,
  directives,
});

loadFonts();

createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(vuetify)

  .mount("#app");
