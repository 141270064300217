<template>
  <!-- Setup the delete score card dialog.  The dialog will appear with the delete score card icon is clicked.  The dialog will give the user the chance to cancel or complete the deletion -->
  <TheStandardDialog
    :showDialog="showDeleteDialog"
    :titleText="t('home.sureDelete')"
    :insertText="selectedOpenGame"
    :explanationText="t('home.delWarning')"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('common.yes')"
    @leftBtn="showDeleteDialog = false"
    @rightBtn="deleteScoreCard"
  ></TheStandardDialog>

  <!-- #region This sections is displayed if no user is logged in -->
  <div v-show="loading">
    <!--  <p class="text-h6 font-weight-bold text-center my-16">Please Wait...</p> -->
  </div>
  <div v-show="!loading">
    <div v-if="!store.state.auth.userLoggedIn">
      <v-card>
        <v-img src="mainPage.jpg" cover>
          <v-container fluid class="pa-10">
            <!-- App Title row -->
            <v-row :no-gutters="true" class="d-flex justify-start ma-0 py-auto">
              <v-col
                class="text-h6 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h2 font-weight-bold titleTextLight-Caps ma-0 pa-0"
              >
                {{ t("app.name") }} </v-col
              >>
            </v-row>

            <!-- App description rows -->
            <v-row
              :no-gutters="true"
              class="d-flex justify-start ma-0 mt-4 py-auto"
            >
              <v-col
                class="text-center text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h3 titleTextLight-noCaps ma-0 pa-0"
              >
                <p class="pa-0 ma-0">
                  {{ t("home.P1") }}
                </p>
                <br />
                <p>{{ t("home.P2") }}</p>
                <br />
                <p>{{ t("home.P3") }}</p>
                <br />
                <p>{{ t("home.P4") }}</p>
                <br />
                <p>{{ t("home.P5") }}</p>
              </v-col>
            </v-row>

            <v-row :no-gutters="true" class="d-flex justify-start ma-0 py-auto"
              ><v-col class="ma-0 mt-4 pa-0 text-center">
                <v-btn @click="loginDemoUser" class="stdBtn">{{
                  t("home.demoMode")
                }}</v-btn></v-col
              ></v-row
            >
            <v-row :no-gutters="true" class="d-flex justify-start ma-0 py-auto">
              <v-col
                class="text-left text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 text-xl-h3 titleTextLight-noCaps ma-0 mt-12 pa-0"
              >
                {{ t("home.siteStatus") }}
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-card>
    </div>
    <!-- #endregion -->

    <!-- #region This secton is displayed if a user is logged in -->
    <div v-else>
      <!-- Display the user welcome user here with the user name of the logged in user -->
      <v-card class="card-standard">
        <v-card-title
          class="card-header text-h6 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h2 font-weight-bold pt-1 pb-2"
        >
          {{ t("common.welcome") + " " + userDisplayName }}</v-card-title
        >
      </v-card>

      <!-- #region Display page header instructions and controls for selecting an existing score card or deleting the selected score card -->
      <!-- Display header to games list -->

      <v-card class="card-standard">
        <v-container class="pa-0" fluid>
          <TheStandardHeaderRow :headerLevel="1" :rightColumnNumber="2">
            <template v-slot:title>
              <select
                v-model="selectedOpenGame"
                class="my-auto selectStandard text-left"
              >
                <option
                  disabled
                  value=""
                  class="my-auto selectStandard text-left w-100"
                >
                  {{ t("home.selectAScoreCard") }}
                </option>
                <option
                  v-for="i in store.getters['sch/scoreCardNames']"
                  :key="i"
                  :value="i"
                  class="my-auto selectStandard text-left"
                >
                  {{ i }}
                </option>
              </select>
            </template>
            <template v-slot:icons>
              <v-btn
                @click="openScoreCard"
                class="elevation-2 stdBtn"
                :size="smAndDown ? 'x-small' : 'small'"
                >{{ t("home.openScoreCard") }}</v-btn
              >
              <v-tooltip location="top" open-delay="1000" class="toolTip">
                <template v-slot:activator="{ props }">
                  <v-icon
                    @click="openDeleteDialog"
                    v-bind="props"
                    icon="mdi-delete-circle-outline"
                    class="iconButton iconButton-red"
                    :size="smAndDown ? '20px' : '30px'"
                  />
                </template>
                <p class="ttText">{{ t("home.ttDeleteScoreCard") }}</p>
              </v-tooltip>
            </template>
          </TheStandardHeaderRow>

          <TheStandardRow
            :rowLevel="3"
            firstColumnSide="none"
            cardMargin="mt-1 mb-1"
            :firstColumnWidth="0"
            :borderRight="false"
            :compactLayout="false"
          >
            <template v-slot:firstColumn>
              {{ t("common.yes") }}
            </template>
            <template v-slot:remainingColumns>
              <v-col
                cols="12"
                class="ma-0 pa-0 py-auto text-center font-weight-bold borderRight"
                >{{ t("home.selectAnExisting") }}
              </v-col>
            </template>
          </TheStandardRow>
        </v-container>
      </v-card>
      <!--   #endregion -->

      <!-- #region page components are inserted below -->
      <!-- This insert is a bar that shows what game will be selected and holds a button
    that turns green when it is legal to create a new score card-->
      <ScoreCardSetupStatus></ScoreCardSetupStatus>

      <!-- Card-Clubs List and cotnrols inserted here -->
      <CardClubsInsert></CardClubsInsert>

      <!-- List of valid game data and controls inserted here -->

      <GameDataInsert></GameDataInsert>

      <!-- List of players, teams and controls inserted here -->

      <ClubPlayersInsert
        v-if="store.getters['cg/gamesDataLoaded']"
      ></ClubPlayersInsert>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->
</template>

<script setup>
  //Import standard vue components
  import { useRouter } from "vue-router";
  import { ref, watch, onMounted } from "vue";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";
  import { useDisplay } from "vuetify";

  //Import the components that will be inserted into the page
  import CardClubsInsert from "../viewInserts/CardClubsInsert.vue";
  import GameDataInsert from "../viewInserts/GameDataInsert.vue";
  import ClubPlayersInsert from "../viewInserts/ClubPlayersInsert.vue";
  import ScoreCardSetupStatus from "../viewInserts/ScoreCardSetupStatus.vue";
  import TheStandardDialog from "../reusable/TheStandardDialog.vue";
  import TheStandardHeaderRow from "../reusable/TheStandardHeaderRow.vue";
  import TheStandardRow from "../reusable/TheStandardRow.vue";
  import { inDemoMode } from "../composables/demoModeHelpers";

  /*  import pdf from "@/components/pdf/Pdfvuer.vue";
    const welcome = ref("gameInfo/WelcomeScoreCards.pdf"); */

  /* Load reactive true or false for small and down display size. Used to resize components when screen size is changes */
  let { smAndDown } = useDisplay();

  //Load i18n for working with translations
  let { t } = useI18n({});

  //Load the router for navigation between pages
  const router = useRouter();

  //Load the store for working with the vuex store
  const store = useStore();

  let userDisplayName = ref("Unknown");

  //v-model for the select control that holds the list of open score cards
  let selectedOpenGame = ref("none");

  //v-model for the dialog that asks the user if they want to delete the selected score card
  let showDeleteDialog = ref(false);

  let loading = ref(true);

  /* Watch for the display name to change (usually means the user has logged in) and update the user title bar */
  watch(
    () => store.getters["auth/userDisplayName"],
    (newName) => {
      userDisplayName.value = newName;
    }
  );

  /* Watch for user to log in.  If user is logged in, load list of open score cards
    for the user */
  watch(
    () => store.getters["auth/userLoggedIn"],
    () => {
      if (store.getters["auth/userLoggedIn"]) {
        store.dispatch("sch/getListOfScoreCards");
      }
      loading.value = false;
    }
  );

  watch(
    () => store.state.auth.userLoggedIn,
    () => {
      loading.value = false;
    }
  );

  /* Watch for list of score cards to change (user logs in or score card is created or deleted).  If list changes, reload list for score card select control  */
  watch(
    () => store.getters["sch/scoreCardNames"],
    () => {
      //Get last selected open score card and set select control to that card
      selectedOpenGame.value = store.getters["sch/getScoreCardNameFromID"](
        store.getters["sm/lastScoreCard"]
      );
    }
  );

  /* Watch for scoreCardLoaded to change. When changed, update the last scorecard
      loaded saved data to to the current scorecard and then route to the scoreCard page*/
  watch(
    () => store.getters["sch/scoreCardLoaded"],
    () => {
      if (store.getters["sch/scoreCardLoaded"]) {
        let payLoad = {
          userID: store.getters["auth/loggedInSiteMemberID"],
          lastScoreCard: store.getters["sch/scoreCardID"],
        };
        store.dispatch("sm/updateLastScoreCard", payLoad);
        router.push({ name: "scoreCard" });
      }
    }
  );

  /* Service the open score card button click.  If no score card is highlighted in the select
    control, display a message to the user and return*/
  function openScoreCard() {
    if (
      selectedOpenGame.value === undefined ||
      selectedOpenGame.value === null ||
      selectedOpenGame.value === ""
    ) {
      store.commit("showSnackBar", t("home.sbNoScoreCardSelected"));
      return;
    }

    //Get the ID of the game that was selected in the select control
    let ogID = store.getters["sch/getScoreCardIDFromName"](
      selectedOpenGame.value
    );

    /* Set flag to tell the incoming score card that the score card is reloading (not new) */
    store.commit("sch/scoreCardOrigination", "reload");

    /* To prevent the score card page from displaying before retrieving data from the database is complete, the score card loading flag is set. Once the scorecard is loaded from the database, the function sets the flag to scorecardloaded. A watcher in this component then routes to the scorecard.*/
    store.commit("sch/scoreCardLoading");

    /* load the selected score card with ID from the select control */
    store.dispatch("sch/loadScoreCard", ogID);
  }

  /**
   * Function to open the delete dialog for a selected game.
   * If no game is selected, a snackbar is shown with a message.
   */
  function openDeleteDialog() {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.deleteScoreCard"), true)) {
      return;
    }

    if (selectedOpenGame.value === "") {
      store.commit("showSnackBar", t("home.noScoreCards"));
      return;
    }
    showDeleteDialog.value = true;
  }

  /*  This function is responsible for deleting a scorecard and refreshing the list of scorecards. */
  function deleteScoreCard() {
    // Set the value of the showDeleteDialog to false to hide the delete dialog box.
    showDeleteDialog.value = false;

    // Get the original ID of the selected open game from the store.
    let ogID = store.getters["sch/getScoreCardIDFromName"](
      selectedOpenGame.value
    );

    // Dispatch an action to delete the scorecard with the original ID.
    store.dispatch("sch/deleteScoreCard", ogID);

    // Dispatch an action to get the updated list of open games.
    store.dispatch("sch/getListOfScoreCards");
  }

  //Function to login demo user
  async function loginDemoUser() {
    //Log in the demo user.
    let creds = {
      email: "demouser@demo.com",
      password: "demouser01",
    };

    //Call login function and get result
    await store.dispatch("auth/signinUserWithEmail", creds);
    //Get login result from store
    let loginResult = store.getters["auth/loginResult"];

    //Result is a message and a boolean indicating if login was successful. Message is the key for the i18n text.
    store.commit("showSnackBar", t(loginResult.message));
  }

  /* If user logged in, get list of the current users score cards and
    load the user name for display on screen.  Both are needed to properly
    refresh view when return to view from another page (eg. scorecard) */
  onMounted(() => {
    if (store.state.auth.userLoggedIn) {
      store.dispatch("sch/getListOfScoreCards");
      userDisplayName.value = store.getters["auth/userDisplayName"];
    }
    if (loading.value === true) {
      setTimeout(() => {
        loading.value = false;
      }, 500);
    }
  });
</script>

<style lang="scss"></style>
