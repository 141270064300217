import {
  getDocs,
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";

import { db } from "../firebaseInit";

import {
  getStorage,
  saveStorage,
} from "../../components/composables/localStorage";

const cardClubsCollection = collection(db, "cardClubs");

const cardClubsModule = {
  namespaced: true,
  state() {
    return {
      //Loaded from database (page mount). Holds list of card clubs displayed on page
      cardClubs: [
        {
          teams: [
            { name: "Team 1d" },
            { name: "Team 2d" },
            { name: "Team 3d" },
            { name: "Team 4d" },
          ],
        },
      ],

      /* Set to true when data is finished loading from DB.  Monitored by
      watchers to drive next steps in loading process.  Note change from true to false will also trigger watchers */
      cardClubsDataLoaded: false,

      /* Holds the ID of the currently selected card club. */
      cardClubID: null,

      /* Save last game selected on load so gamesData can select the proper game */
      lastGameSelected: null,
    };
  },

  getters: {
    //Getters for Card Clubs

    //Return the card clubs array
    cardClubs(state) {
      return state.cardClubs;
    },

    //Returns the selected card club object
    selectedCardClub(state) {
      let ccSelected = state.cardClubs.filter(
        (cc) => cc.id === state.cardClubID
      );
      if (ccSelected.length === 0) {
        return "";
      }
      return ccSelected[0];
    },

    //Returns the ID of the selected card club
    cardClubID(state) {
      return state.cardClubID;
    },

    /* Returns cardClubDataLoaded value.  Will be true true if card club data has been loaded from the database */
    cardClubsDataLoaded(state) {
      return state.cardClubsDataLoaded;
    },

    /* Return the name of the currently sellected card club */
    cardClubName(state) {
      let ccSelected = state.cardClubs.filter(
        (cc) => cc.id === state.cardClubID
      );
      if (ccSelected.length === 0) {
        return "";
      }
      return ccSelected[0].name;
    },

    /* Check to see if a club already exists with the name passed in.  Return true if name is a duplicate */
    isCardClubNameDuplicate: (state) => (name) => {
      let ccSelected = state.cardClubs.filter(
        (cc) => cc.name.toUpperCase() === name.toUpperCase()
      );
      if (ccSelected.length === 0) {
        return false;
      }
      return true;
    },

    /* Return the team names written in club */
    cardClubTeamNames(state) {
      let ccSelected = state.cardClubs.filter(
        (cc) => cc.id === state.cardClubID
      );
      if (ccSelected.length === 0) {
        return "";
      }

      return ccSelected[0].teams;
    },
  },

  mutations: {
    /* Load Card Clubs to display object. Function should be called after Card Clubs are loaded from database */
    loadCardClubs(state, payLoad) {
      //If payload is false, no card clubs were found.  Set card clubs to empty array and return
      if (payLoad === false) {
        state.cardClubs = [];
        state.cardClubID = null;
        state.cardClubsDataLoaded = true;
        return;
      }

      //Sort card club array by club name
      payLoad.ccs.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      //Copy card clubs from database to display object
      state.cardClubs = payLoad.ccs;
      //Set the selected card club to the one passed in
      state.cardClubID = payLoad.id;
      //Set flag to indicate card club data has been loaded
      state.cardClubsDataLoaded = true;
    },

    /* Add a Card Club to the display object. Make same changes in display object that were made in database */
    addCardClub(state, cc) {
      //cc is a card club returned from the db.  Add it to the display object
      state.cardClubs.push({ ...cc.data(), id: cc.id });
      state.cardClubID = cc.id;

      //Sort card club array by club name
      state.cardClubs.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },

    /* Delete a Card Club from the display object.  */
    deleteCardClub(state, id) {
      //filter all card clubs that do not match the passed in id
      state.cardClubs = state.cardClubs.filter((cc) => cc.id !== id);
    },

    /* Update a Card Club name and description in the display object  */
    updateCardClub(state, updatedItem) {
      let updateCCItem = state.cardClubs.filter(
        (cc) => cc.id === updatedItem.id
      );
      updateCCItem[0].name = updatedItem.name;
      updateCCItem[0].description = updatedItem.description;
    },

    /* Update the selected status of a Card Club in the display object */
    setcardClubID(state, id) {
      //For each card club in the display object, set the selected status to false except for the one passed in
      state.cardClubs.forEach((cc) => {
        if (cc.id === id) {
          cc.selected = true;
        } else {
          cc.selected = false;
        }
      });

      //Set the selected card club to the one passed in
      state.cardClubID = id;
    },

    /* Update the last game selected field in the display object */
    updateLastGameSelected(state, payLoad) {
      //If payload is false, no card clubs were found so return
      if (payLoad === false) {
        return;
      }
      //
      let updateCCItem = state.cardClubs.filter((cc) => cc.id === payLoad.ccID);
      updateCCItem[0].lastGameSelected = payLoad.cgID;
    },
  },

  /*  Database reads/writes are in actions.  Each database action needs to be reflected in the objects being displayed and so each database action should be paired with a mutation that updates the display object */
  actions: {
    /*Load the card club collection from the database (called when cardclubs.vue is mounted)*/
    async loadCardClubs({ state, commit, rootGetters }) {
      let tempCardClubID = null;
      //Get the id of the logged in site member
      let idQ = rootGetters["auth/loggedInSiteMemberID"];

      //Create query to find all card clubs where the logged in site member is a manager
      const siteMemberCardClubs = query(
        cardClubsCollection,
        where("managers", "array-contains", { id: idQ })
      );
      // Load the logged in users card clubs from the database
      const snapshot = await getDocs(siteMemberCardClubs).catch(() => {
        //If error reading from database commit an empty payload then return
        return;
      });
      if (snapshot === undefined || snapshot.docs.length === 0) {
        //If no card clubs found commit an empty payload then return
        let payLoad = false;
        commit("loadCardClubs", payLoad);
        return;
      }

      let cc = [];
      //In case there is no selected club in the database, set default to the first record
      let selectedCardClubFound = false;

      snapshot.docs.forEach((doc) => {
        //add club club to card club array
        cc.push({ ...doc.data(), id: doc.id });

        if (doc.data().selected === true) {
          /*set the selected card club to variable so proper radio button
          can be set when screen is first loaded */
          tempCardClubID = doc.id;
          state.lastGameSelected = doc.data().lastGameSelected;
          //selected club found so no need to use default
          selectedCardClubFound = true;
        }

        /* Export JSON file of new club */
        /* const fileName = doc.data().name;
        const json = JSON.stringify({ ...doc.data(), id: doc.id });
        const blob = new Blob([json], { type: "application/json" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + ".json";
        link.click(); */
      });

      //Sort card club array by club name
      cc.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      //If selected card club not found load the default
      if (!selectedCardClubFound) {
        // If no selected card club found, set the selected card club to the first one in the array
        tempCardClubID = cc[0].id;
      }

      /*call to copy array to store array used on screen.  */
      let payLoad = { ccs: cc, id: tempCardClubID };
      commit("loadCardClubs", payLoad);
    },

    /* Add a Card Club to the database */
    async addCardClub({ commit, dispatch }, NewCardClub) {
      //Add the new card club to the database
      const docRef = await addDoc(cardClubsCollection, NewCardClub);

      //after new record written get the id of the new record
      NewCardClub.id = docRef.id;

      //Get the new record from the database
      let newCC = await getDoc(docRef);

      //Commit the new club to the display object
      commit("addCardClub", newCC);

      //Set the new card club as the selected card club. This keeps no card club from being selected when adding the first card club
      dispatch("updateSelected", NewCardClub.id);
    },

    /* Delete a Card Club from the database, including all players and score cards associated with the club  */
    async deleteCardClub({ commit, dispatch }, id) {
      //Delete all players associated with the club
      await dispatch("cp/deleteAllClubPlayers", id, { root: true });

      //Then delete all score cards associated with the club
      await dispatch("sch/deleteAllScoreCards", id, { root: true });

      //Then delete the card club
      const docRef = doc(cardClubsCollection, id);
      await deleteDoc(docRef);

      //Then delete the card club from the display object
      commit("deleteCardClub", id);

      //Reload the card clubs from the database
      await dispatch("loadCardClubs");
    },

    /* Update name and/or description of a card club in the database */
    async updateCardClub({ commit }, updatedItem) {
      //to be more responsive, update display array before changing database
      commit("updateCardClub", updatedItem);

      //Get reference to item being updated, then update with data passed in
      const docRef = doc(db, "cardClubs", updatedItem.id);
      await updateDoc(docRef, {
        name: updatedItem.name,
        description: updatedItem.description,
      });
    },

    /* Update the Selected status of a Card Club in the database.  Make the passed in club selected, make sure all others are not selected */
    async updateSelected({ commit, state }, id) {
      // If no card clubs exist, return
      if (state.cardClubs.length === 0) {
        return;
      }

      // Update display array before changing database
      commit("setcardClubID", id);

      //create query to find all records that are currently selected
      const selectedCardClubsQuery = query(
        cardClubsCollection,
        where("selected", "==", true)
      );

      //Get a snapshot of all the records found
      const querySnapshot = await getDocs(selectedCardClubsQuery);
      //convert query collection to docs collection
      const allDocs = querySnapshot.docs;

      //For each document found that is selected update to unselected
      allDocs.forEach((docResult) => {
        const docRef = doc(db, "cardClubs", `${docResult.id}`);
        updateDoc(docRef, { selected: false }).then(() => {});
      });

      //For the CC club with the id pass in, turn on selected
      const docRef2 = doc(db, "cardClubs", id);
      updateDoc(docRef2, { selected: true }).then(() => {});
    },

    /* Update the lastGameSelected field.  This function will be called 
    when the selected card game is changed  */
    async updateLastGameSelected({ commit, state }, payLoad) {
      // If no card clubs exist, return
      if (state.cardClubs.length === 0) {
        payLoad = false;
        commit("updateLastGameSelected", payLoad);
        return;
      }

      //Get reference to item being updated, then update with game id passed in
      const docRef = doc(db, "cardClubs", payLoad.ccID);
      await updateDoc(docRef, { lastGameSelected: payLoad.cgID });

      //update the display object
      commit("updateLastGameSelected", payLoad);
    },

    /* Add/update the teams names associated with the active club.  Team names will be written when a new score card is created or any changes are made to the team names*/
    async writeTeamNames({ state }, payload) {
      //Get team names from local storage.  If local storage does not exist, create it
      let savedTeamNames = [];
      savedTeamNames = JSON.parse(getStorage("teamNames", "name"));

      if (savedTeamNames === null || savedTeamNames === undefined) {
        savedTeamNames = [];
      }

      //Fill in saved teams names if needed to make 4
      if (savedTeamNames != null || savedTeamNames != undefined) {
        for (let i = savedTeamNames.length; i < 4; i++) {
          savedTeamNames.push({ name: `t ${i + 1}` });
        }

        saveStorage("teamNames", savedTeamNames);
      }

      //Fill in payload if needed to make 4.  Playload will have the team names that are being used by the score card being created.  This could be 2, 3, 4 teams.  If the score card does not use teams, payload will have one unused team
      if (payload.length === 3) {
        payload.push(savedTeamNames[3]);
      }
      if (payload.length === 2) {
        payload.push(savedTeamNames[2]);
        payload.push(savedTeamNames[3]);
      }
      if (payload.length === 1) {
        payload.push(savedTeamNames[1]);
        payload.push(savedTeamNames[2]);
        payload.push(savedTeamNames[3]);
      }

      //Get reference to item being updated, then update with data passed in
      const docRef = doc(db, "cardClubs", state.cardClubID);
      await updateDoc(docRef, {
        teams: payload,
      });
    },
  },
};

export default cardClubsModule;
