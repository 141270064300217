/* Function to save and restore user entered data so that the data can be preserved between
refreshes.  Used to restore previously entered data after returning to the home screen */

// Sets an item with a Key to local storage
const saveStorage = function (key, data) {
  localStorage.setItem(key, JSON.stringify(data));
};

// Looks for a local storage item and returns if present
const getStorage = function (key) {
  /* Data is returned in JSON format and may need to use JSON.parse() No error checking is done on value returned.*/
  const data = localStorage.getItem(key);
  return data;
};

// Clear a single item or the whole local storage
const clearStorage = function (key = "false") {
  if (key) {
    localStorage.removeItem(key);
  } else {
    localStorage.clear();
  }
};
// Exports all avaliable functions on the script
export { getStorage, saveStorage, clearStorage };
