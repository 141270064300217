<template>
  <div>
    <input
      type="text"
      class="elevation-1 text-truncate htmlTextFieldStandard"
      :placeholder="placeHolderText"
      v-model="searchString"
    />
    <v-tooltip location="end" open-delay="1000">
      <template v-slot:activator="{ props }">
        <v-icon
          v-bind="props"
          @click="clearSearchBox"
          :size="smAndDown ? '20px' : '30px'"
          class="iconButton iconButton-light ml-2"
          >mdi-backspace-outline
        </v-icon>
      </template>
      <p class="ttText">
        {{ t("standardSearch.clickToClearSearch") }}
      </p>
    </v-tooltip>
  </div>
</template>

<script setup>
  import { ref, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useDisplay } from "vuetify";
  let { smAndDown } = useDisplay();

  //create function to insert i18N text
  let { t } = useI18n({});

  const props = defineProps({
    sortArray: { type: Array },
    translateNames: { type: Boolean, default: false },
    placeHolderText: { type: String, default: "" },
    clearFilter: { type: Number, default: 0 },
  });

  //Declare props and emits
  const emit = defineEmits(["updatedArray"]);

  //create a variable to hold the search string typed in the search box
  let searchString = ref("");
  let filteredArray = [];

  //Watch for changes to the clearFilter prop.  If the clearFilter changes, clear the search string
  watch(
    () => props.clearFilter,
    () => {
      searchString.value = "";
    }
  );

  //Watch for changes to the sortArray prop.  If the sortArray changes, clear the search string
  watch(
    () => props.sortArray,
    () => {
      searchString.value = "";
    }
  );

  //Watch for changes to the search string.  If the search string changes, filter the gamesData list
  watch(
    () => searchString.value,
    (newString) => {
      // Trim the search string
      newString = newString.trim();

      // If the search string is null, set it to an empty string.  Controls clear icon makes the search string null when cleared
      if (
        searchString.value === null ||
        searchString.value === undefined ||
        newString === ""
      ) {
        newString = "";
        searchString.value = "";
      }

      // Filter the gamesData list based on the search string
      filteredArray = props.sortArray.filter((game) => {
        if (props.translateNames) {
          return t(game.name).toLowerCase().includes(newString.toLowerCase());
        } else {
          return game.name.toLowerCase().includes(newString.toLowerCase());
        }
      });

      updatedArray();
    }
  );

  //Clear the search box
  function clearSearchBox() {
    searchString.value = "";
  }

  function updatedArray() {
    emit("updatedArray", filteredArray);
  }
</script>

<style lang="scss" scoped></style>
