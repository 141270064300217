<template>
  <!-- This is the scorecard vue file to load.  -->
  <scoreCardGameFile> </scoreCardGameFile>

  <!-- Insert game customization dialog here so it can be called from any game -->
  <ss-game-customization-dialog></ss-game-customization-dialog>
</template>

<script setup>
  import { defineAsyncComponent } from "vue";
  import ssGameCustomizationDialog from "../scoreCardParts/ssGameCustomizationDialog.vue";

  import { useStore } from "vuex";
  const store = useStore();

  //Get the name of the vue file to load
  let vueFileName = store.getters["sch/vueFileToLoad"];

  //Load the vue file
  const scoreCardGameFile = defineAsyncComponent(() =>
    import("../scoreCards/" + vueFileName)
  );
</script>

<style lang="scss"></style>
