<template>
  <v-container fluid class="flex ma-0 pa-0 w-100">
    <!-- Row for title message -->
    <v-row
      :no-gutters="true"
      class="d-flex mt-2 ma-0 justify-start align-start w-100"
    >
      <v-col cols="9">
        <p
          class="text-h5 font-weight-bold mb-2 titleTextDark"
          :class="membershipExpired ? 'alertText' : ''"
        >
          {{ membershipMessage }}
        </p>
      </v-col>
    </v-row>

    <!-- Row for sales message and paypal buttons -->
    <v-row
      :no-gutters="true"
      class="d-flex ma-0 justify-start align-start w-100"
      ><v-col
        cols="12"
        sm="9"
        class="d-flex justify-start align-self-start ma-0"
      >
        <!-- Sales message displayed here -->
        <v-textarea
          :model-value="membershipSubMessage"
          auto-grow
          :hide-details="true"
          readonly
          class="text-body-1 ml-0 mt-0 ma-2 text-center elevation-1"
          >{{ membershipSubMessage }}</v-textarea
        >
      </v-col>
      <v-col cols="12" sm="3">
        <!-- If the user is not a member and has not paid for memebership -->
        <div v-if="!paidFor">
          <p class="alertText">Payments not live, using PayPal sandbox</p>
          <div ref="paypal" class="mr-2 elevation-1"></div>
        </div>

        <!-- If the user is not a member and has just now paid for the membership via payPal -->
        <div v-if="paidFor">
          <p class="text-h6 mr-2">{{ $t("payPal.thankYou") }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { watch } from "vue";

  export default {
    name: "PayPal",
    props: ["email"],

    // Data is a function that returns an object
    data: function () {
      return {
        loaded: false,
        paidFor: false,
        membershipExpired: false,
        membershipMessage: "test",
        membershipSubMessage: "testSub",

        membershipExpiredDate: this.$store.getters["sm/subscriptionExpiresOn"],
        // This object contains the product details.  Price is defined in the store.
        product: {
          price: this.$store.getters["salesPrice"],
          description: this.$t("payPal.purchaseDescription"),
        },

        subscriptionExpiresOn: this.$store.getters["sm/subscriptionExpiresOn"],

        daysToExpire: this.daysBetweenDates(
          new Date(),
          this.$store.getters["sm/subscriptionExpiresOn"]
        ),
      };
    },

    // This is a lifecycle hook that is called when the component is mounted
    mounted: function () {
      this.createMembershipMessage();
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=" +
        process.env.VUE_APP_PAYPAL_CLIENT_ID;
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
      this.handleEmail();

      // Watch for changes to the subscriptionExpiresOn value in the store and update the membership message
      watch(
        () => this.$store.getters["sm/subscriptionExpiresOn"],
        () => {
          // Call createMembershipMessage when the value changes
          this.$store.dispatch(
            "sm/loadSiteMember",
            this.$store.getters["auth/loggedInSiteMemberID"]
          );
          this.createMembershipMessage();
        }
      );
    },

    methods: {
      //function to create membership message
      createMembershipMessage() {
        this.daysToExpire = this.daysBetweenDates(
          new Date(),
          this.$store.getters["sm/subscriptionExpiresOn"]
        );
        this.membershipExpiredDate =
          this.$store.getters["sm/subscriptionExpiresOn"];

        if (this.daysToExpire < 0) {
          this.membershipExpired = true;

          /* Create membership message when membership is expired */
          this.membershipMessage =
            this.$t("payPal.yourMemebershipExpiredOn") +
            this.membershipExpiredDate.toLocaleDateString();
          this.membershipSubMessage =
            "For $" +
            this.product.price +
            this.$t("payPal.youCanPurchase") +
            this.email +
            this.$t("payPal.allowsCreateSave") +
            this.addToCurrentDate(1, 0) +
            this.$t("payPal.subscriptionDoesNotAutoRenew") +
            this.product.price +
            this.$t("payPal.allPurchasesFinal");

          //If the user has never subscribed, display a different message
          if (!this.$store.getters["sm/hasUserEverSubscribed"]) {
            this.membershipMessage = this.$t("payPal.youDontHaveAMembership");
          }
        } else {
          this.membershipExpired = false;

          /* Create membership message for member not expired */
          this.membershipMessage = "Your membership is active";

          this.membershipSubMessage =
            this.$t("payPal.yourMembershipExpiresIn") +
            " " +
            this.daysToExpire +
            this.$t("payPal.days") +
            this.membershipExpiredDate.toLocaleDateString() +
            this.$t("payPal.subscriptionDoesNotAutoRenew") +
            this.product.price +
            this.$t("payPal.renewalAppliedTo") +
            this.email +
            this.$t("payPal.allowsCreateSave") +
            this.addToExpiresDate(1, 0) +
            this.$t("payPal.allPurchasesFinal");
        }
      },

      // This function is called when the PayPal script is loaded
      setLoaded: function () {
        this.loaded = true;
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: this.product.description,
                    amount: {
                      currency_code: "USD",
                      value: this.product.price,
                    },
                  },
                ],
              });
            },
            // This code is an event handler for when a PayPal payment is approved
            // It is an asynchronous function that takes in two parameters: data and actions
            onApprove: async (data, actions) => {
              // The order is captured and stored in a variable called "order"
              const order = await actions.order.capture();
              // The "paidFor" property of the current object is set to true
              this.paidFor = true;

              // A payload object is created with transaction details and the user ID
              let payLoad = {
                transaction: {
                  datePurchase: this.addToCurrentDate(0, 0),
                  dateExpires: this.addToExpiresDate(1, 0),
                  userEmail: this.email,
                  amountPaid: 12,
                  ppOrder: order,
                },
                userID: this.$store.getters["auth/loggedInSiteMemberID"],
              };

              // The payload is dispatched to the store to write a membership transaction
              this.$store.dispatch("sm/writeMembershipTransaction", payLoad);
              this.createMembershipMessage();
            },
            onError: () => {},
          })
          .render(this.$refs.paypal);
      },

      //function to handle email
      handleEmail: function () {},

      //function to add years and days to current date
      addToCurrentDate: function (years, days) {
        let newDate = new Date();
        newDate.setFullYear(newDate.getFullYear() + years);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
      },

      // This function takes in two arguments: years and days and adds them to the subscriptionExpiresOn value from the store
      addToExpiresDate: function (years, days) {
        // Create a new Date object using the subscriptionExpiresOn value from the store
        let newDate = new Date(this.$store.getters["sm/subscriptionExpiresOn"]);

        if (
          this.isDateInThePast(this.$store.getters["sm/subscriptionExpiresOn"])
        ) {
          // If the subscription has expired, set the subscriptionExpiresOn value to today's date
          newDate = new Date();
        }

        // Add the specified number of years to the newDate object
        newDate.setFullYear(newDate.getFullYear() + years);

        // Add the specified number of days to the newDate object
        newDate.setDate(newDate.getDate() + days);

        // Return the newDate object as a JSON string, formatted as "YYYY-MM-DD"
        return newDate;
      },

      // This function takes in two date strings and returns the number of days between them
      daysBetweenDates: function (startDate, endDate) {
        // Calculate the difference in milliseconds
        const diffInMs = endDate - startDate;

        // Convert milliseconds to days and round up to the nearest day
        const days = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

        return days;
      },

      // This function takes in a date  and returns true if the date is in the past
      isDateInThePast: function (date) {
        /* const inputDate = new Date(date); */
        const currentDate = new Date();

        return date < currentDate;
      },
    },
  };
</script>
