<template>
  <!-- Delete Dialog -->
  <TheStandardDialog
    :showDialog="showDeleteDialog"
    :titleText="t('cpInsert.sureDelete')"
    :insertText="internalItem?.name"
    :explanationText="t('cpInsert.delWarning')"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('common.yes')"
    @leftBtn="showDeleteDialog = false"
    @rightBtn="deleteClubPlayer(internalItem.id)"
  >
  </TheStandardDialog>

  <!-- Edit Dialog -->
  <TheStandardDialog
    :showDialog="showEditDialog"
    :titleText="$t('cpInsert.editPlayer') + ' ' + restorableName"
    insertText="not-used"
    explanationText="not-used"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('common.update')"
    @leftBtn="cancelUpdateClubPlayer(internalItem)"
    @rightBtn="updateClubPlayer(internalItem)"
  >
    <template v-slot:dSlot>
      <v-text-field
        v-model="internalItem.name"
        :autofocus="true"
        :label="t('cpInsert.updatePlayerName')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
        @keyup.enter="updateClubPlayer(internalItem)"
      ></v-text-field>

      <v-text-field
        v-model="internalItem.nickname"
        :label="t('cpInsert.updatePlayerNickname')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
        @keyup.enter="updateClubPlayer(internalItem)"
      >
      </v-text-field>
    </template>
  </TheStandardDialog>

  <!-- Add Dialog -->
  <TheStandardDialog
    :showDialog="showAddDialog"
    :titleText="$t('cpInsert.addPlayer')"
    insertText="not-used"
    explanationText="not-used"
    :leftBtnText="t('common.done')"
    :rightBtnText="t('common.add')"
    @leftBtn="showAddDialog = false"
    @rightBtn="AddClubPlayer(newClubPlayer)"
  >
    <template v-slot:dSlot>
      <v-text-field
        v-model="newClubPlayer.name"
        :autofocus="true"
        :label="t('cpInsert.newName')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
        @keyup.enter="AddClubPlayer(newClubPlayer)"
      ></v-text-field>

      <v-text-field
        v-model="newClubPlayer.nickname"
        :label="t('cpInsert.newNickname')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
      >
      </v-text-field>
    </template>
  </TheStandardDialog>

  <!-- Show the top two lines of the players section.  The first row will be the title, status icon and icon to add a player. The second row will only show if game is played by teams.    -->
  <TheStandardHeaderRow :headerLevel="2" :rightColumnNumber="3">
    <template v-slot:title>
      <v-tooltip location="top" open-delay="1000">
        <template v-slot:activator="{ props }">
          <!-- Show the ready to create icon. Color and size of icon changes depending on whether or not the proper number of teams and players have been selected so that a score card can be creaeted -->
          <v-icon
            v-bind="props"
            class="pr-6"
            :class="
              store.getters['readyToCreateNewScoreCard']
                ? 'iconButton-active'
                : 'iconButton-notActive-red'
            "
            :size="smAndDown ? '20px' : '30px'"
          >
            {{
              store.getters["readyToCreateNewScoreCard"]
                ? "mdi-checkbox-marked-outline"
                : "mdi-checkbox-blank-off-outline"
            }}
          </v-icon>
        </template>

        <p class="ttText">
          <!-- Show tooltip depending on weather the icon is read or green -->
          {{
            store.getters["readyToCreateNewScoreCard"]
              ? t("cpInsert.ttReadyToCreateScoreCard")
              : t("cpInsert.ttNotReadyToCreateScoreCard")
          }}
        </p>
      </v-tooltip>
      <!-- This is the title of the section consisting of pieces of text put together based on what the user has selected for teams and players -->
      {{ playersTitle }}
    </template>
    <template v-slot:icons>
      <v-tooltip location="start" open-delay="1000">
        <template v-slot:activator="{ props }">
          <v-icon
            @click="ShowAddClubPlayerDialog"
            v-bind="props"
            :size="smAndDown ? '20px' : '30px'"
            class="iconButton iconButton-light pr-6"
          >
            mdi-plus-circle-outline
          </v-icon>
          <TheStandardSearchBox
            :sortArray="playersData"
            :translateNames="false"
            :clearFilter="store.getters['cp/clubPlayerSelectedChanged']"
            :placeHolderText="t('cpInsert.searchPlayers')"
            @updatedArray="updatedArray"
          ></TheStandardSearchBox>
        </template>
        <p class="ttText">{{ t("cpInsert.ttClickToAddPlayer") }}</p>
      </v-tooltip>
    </template>
  </TheStandardHeaderRow>

  <!-- Team names shown if teams game -->
  <TheStandardRow
    v-if="store.getters['cg/gdsIsGameTypeTeam']"
    :rowLevel="2"
    firstColumnSide="none"
    cardMargin="mt-1 mb-0"
    :firstColumnWidth="0"
    :borderRight="false"
    :compactLayout="true"
  >
    <template v-slot:firstColumn> </template>

    <template v-slot:remainingColumns>
      <!-- column 1 diplays first team -->
      <v-row :no-gutters="true" class="d-flex ma-0 justify-left align-center">
        <!-- List player name here -->
        <v-col
          :cols="store.getters['cg/gdsMaxTeams'] > 2 ? 2 : 4"
          class="text-center"
        >
          <input
            type="text"
            class="elevation-1 text-truncate"
            :class="[
              teamNamesInputError[0]?.inputError
                ? 'inputError'
                : 'htmlTextFieldStandard',
              smAndDown ? 'font-weight-bold' : 'font-weight-bold',
              smAndDown
                ? 'textFieldStandard-text-small'
                : 'textFieldStandard-text-normal',
            ]"
            :placeholder="xs == true ? '' : t('cpInsert.nameTeam')"
            v-model="teamNames[0].name"
            @change="checkTeamName(0)"
            @click="inDemoMode(t('demoMode.editTeamNames'), true)"
            :readonly="inDemoModeTest()"
          />
        </v-col>

        <!-- column 2 diplays second team -->
        <v-col :cols="store.getters['cg/gdsMaxTeams'] > 2 ? 2 : 4">
          <input
            type="text"
            class="elevation-1 text-truncate"
            :class="[
              teamNamesInputError[1]?.inputError
                ? 'inputError'
                : 'htmlTextFieldStandard',
              smAndDown ? 'font-weight-bold' : 'font-weight-bold',
              smAndDown
                ? 'textFieldStandard-text-small'
                : 'textFieldStandard-text-normal',
            ]"
            :placeholder="xs == true ? '' : t('cpInsert.nameTeam')"
            v-model="teamNames[1].name"
            @change="checkTeamName(1)"
            @click="inDemoMode(t('demoMode.editTeamNames'), true)"
            :readonly="inDemoModeTest()"
          />
        </v-col>

        <!-- column 3 diplays third team -->
        <v-col cols="2">
          <input
            v-if="store.getters['cg/gdsMaxTeams'] > 2"
            type="text"
            class="elevation-1 text-truncate"
            :class="[
              teamNamesInputError[2]?.inputError
                ? 'inputError'
                : 'htmlTextFieldStandard',
              smAndDown ? 'font-weight-bold' : 'font-weight-bold',
              smAndDown
                ? 'textFieldStandard-text-small'
                : 'textFieldStandard-text-normal',
            ]"
            :placeholder="xs == true ? '' : t('cpInsert.nameTeam')"
            v-model="teamNames[2].name"
            @change="checkTeamName(2)"
            @click="inDemoMode(t('demoMode.editTeamNames'), true)"
            :readonly="inDemoModeTest()"
          />
        </v-col>

        <!-- column 4 diplays fourth team -->
        <v-col cols="2">
          <input
            v-if="store.getters['cg/gdsMaxTeams'] > 2"
            type="text"
            class="elevation-1 text-truncate"
            :class="[
              teamNamesInputError[3]?.inputError
                ? 'inputError'
                : 'htmlTextFieldStandard',
              smAndDown ? 'font-weight-bold' : 'font-weight-bold',
              smAndDown
                ? 'textFieldStandard-text-small'
                : 'textFieldStandard-text-normal',
            ]"
            :placeholder="xs == true ? '' : t('cpInsert.nameTeam')"
            v-model="teamNames[3].name"
            @change="checkTeamName(3)"
            @click="inDemoMode(t('demoMode.editTeamNames'), true)"
            :readonly="inDemoModeTest()"
          />
        </v-col>
      </v-row>
    </template>
  </TheStandardRow>

  <!-- List of players to select -->
  <v-card class="cardBodySection04">
    <div class="scrollZone">
      <v-list
        v-for="(item, index) in playersDataFiltered"
        :key="item.id"
        :index="index"
        lines="one"
        hide-details="true"
        density="compact"
        class="pb-0 pt- card-body"
      >
        <v-list-item
          density="compact"
          :class="item.selected ? 'listItemRow-selected' : 'listItemRow'"
        >
          <!-- Row to display individual players to select -->
          <v-row
            :no-gutters="true"
            class="d-flex ma-0 justify-left align-center"
          >
            <!-- column for the first team checkbox -->
            <v-col
              :cols="store.getters['cg/gdsMaxTeams'] > 2 ? 2 : 4"
              class="ma-0 pa-0 d-flex justify-center"
              ><div>
                <v-tooltip location="top" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-checkbox
                      v-bind="props"
                      :ref="item.id"
                      name="0"
                      @click="checkBoxChecked(item.id, $event)"
                      density="compact"
                      hide-details="true"
                      lines="one"
                      class="ma-0 pa-0 d-flex justify-center"
                      :model-value="item.selected && item.team == 0"
                    ></v-checkbox>
                  </template>
                  <p
                    v-if="store.getters['cg/gdsIsGameTypeTeam']"
                    class="ttText"
                  >
                    {{
                      t("cpInsert.ttClickToSelect") +
                      " " +
                      item.name +
                      " " +
                      t("common.for") +
                      " " +
                      teamNames[0].name
                    }}
                  </p>
                  <p v-else class="ttText">
                    {{ t("cpInsert.ttClickToSelect") + " " + item.name }}
                  </p></v-tooltip
                >
              </div>
            </v-col>

            <!-- column for second team checkbox -->
            <v-col
              v-if="store.getters['cg/gdsIsGameTypeTeam']"
              :cols="store.getters['cg/gdsMaxTeams'] > 2 ? 2 : 4"
              class="ma-0 pa-0 d-flex justify-center"
            >
              <div>
                <v-tooltip location="top" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-checkbox
                      v-bind="props"
                      :ref="item.id"
                      name="1"
                      @click="checkBoxChecked(item.id, $event)"
                      density="compact"
                      hide-details="true"
                      lines="one"
                      class="ma-0 pa-0 d-flex justify-center"
                      :model-value="item.selected && item.team == 1"
                    >
                    </v-checkbox>
                  </template>
                  <p
                    v-if="store.getters['cg/gdsIsGameTypeTeam']"
                    class="ttText"
                  >
                    {{
                      t("cpInsert.ttClickToSelect") +
                      " " +
                      item.name +
                      " " +
                      t("common.for") +
                      " " +
                      teamNames[1].name
                    }}
                  </p>
                </v-tooltip>
              </div>
            </v-col>

            <!-- Column for third team checkbox if used -->
            <v-col
              v-if="
                store.getters['cg/gdsIsGameTypeTeam'] &&
                store.getters['cg/gdsMaxTeams'] > 2
              "
              cols="2"
              class="ma-0 pa-0 d-flex justify-center"
              ><div>
                <v-tooltip location="top" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-checkbox
                      v-bind="props"
                      :ref="item.id"
                      name="2"
                      @click="checkBoxChecked(item.id, $event)"
                      density="compact"
                      hide-details="true"
                      lines="one"
                      class="ma-0 pa-0 d-flex justify-center"
                      :model-value="item.selected && item.team == 2"
                    >
                    </v-checkbox>
                  </template>
                  <p
                    v-if="store.getters['cg/gdsIsGameTypeTeam']"
                    class="ttText"
                  >
                    {{
                      t("cpInsert.ttClickToSelect") +
                      " " +
                      item.name +
                      " " +
                      t("common.for") +
                      " " +
                      teamNames[2].name
                    }}
                  </p>
                </v-tooltip>
              </div>
            </v-col>

            <!-- Column for fourth team checkbox if used -->
            <v-col
              v-if="
                store.getters['cg/gdsIsGameTypeTeam'] &&
                store.getters['cg/gdsMaxTeams'] > 3
              "
              cols="2"
              class="ma-0 pa-0 d-flex justify-center"
            >
              <div>
                <v-checkbox
                  :ref="item.id"
                  name="3"
                  @click="checkBoxChecked(item.id, $event)"
                  density="compact"
                  hide-details="true"
                  lines="one"
                  class="ma-0 pa-0 d-flex justify-center"
                  :model-value="item.selected && item.team == 3"
                >
                  <v-tooltip activator="parent" location="top" open-delay="1000"
                    ><p
                      v-if="store.getters['cg/gdsIsGameTypeTeam']"
                      class="ttText"
                    >
                      {{
                        t("cpInsert.ttClickToSelect") +
                        " " +
                        item.name +
                        " " +
                        t("common.for") +
                        " " +
                        teamNames[3].name
                      }}
                    </p>
                    <p v-else class="ttText">
                      {{ t("cpInsert.ttClickToSelect") + " " + item.name }}
                    </p>
                  </v-tooltip>
                </v-checkbox>
              </div>
            </v-col>

            <!-- Column for the player name and edit/delete icons -->
            <v-col
              cols="4"
              class="d-flex justify-start align-self-right text-right ma-0 pa-0 pl-4"
            >
              <v-tooltip location="top" open-delay="1000">
                <template v-slot:activator="{ props }">
                  <v-list-item-title
                    class="text-body-2 text-body-2 text-md-h6 text-lg-h5 text-xl-h4 text-start"
                    :class="
                      item.selected
                        ? 'listItemText-selected-NoCaps'
                        : 'listItemText-NoCaps'
                    "
                    v-bind="props"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </template>
                <p class="ttText">
                  {{
                    item.nickname == ""
                      ? t("cpInsert.noNickname")
                      : item.nickname
                  }}
                </p>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip location="top" open-delay="1000">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    :size="smAndDown ? '20px' : '30px'"
                    class="iconButton iconButton-green"
                    @click="ShowEditClubPlayerDialog(item)"
                    >mdi-pencil-outline</v-icon
                  >
                </template>
                <p class="ttText">
                  {{ t("cpInsert.ttClickToEdit") + " " + item.name }}
                </p>
              </v-tooltip>

              <!-- Delete player icon button.  Button make dialog visible that allows deleting -->
              <v-tooltip location="top" open-delay="1000">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    @click="ShowDeleteCardClubDialog(item)"
                    :size="smAndDown ? '20px' : '30px'"
                    class="iconButton iconButton-red pr-4"
                    >mdi-delete-circle-outline</v-icon
                  >
                </template>
                <p class="ttText">
                  {{ t("cpInsert.ttClickToDelete") + " " + item.name }}
                </p>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script setup>
  /* import components to insert */
  import TheStandardDialog from "../reusable/TheStandardDialog.vue";
  import TheStandardHeaderRow from "../reusable/TheStandardHeaderRow.vue";
  import TheStandardRow from "../reusable/TheStandardRow.vue";
  import TheStandardSearchBox from "../reusable/TheStandardSearchBox.vue";

  import { ref, watch, onBeforeMount } from "vue";
  import { useStore } from "vuex";
  import { saveStorage, getStorage } from "../composables/localStorage";
  import { inDemoMode, inDemoModeTest } from "../composables/demoModeHelpers";
  import { useDisplay } from "vuetify";
  import { useI18n } from "vue-i18n";
  import { watchEffect } from "vue";
  import { onMounted } from "vue";

  //create function to insert i18N text
  let { t } = useI18n({});

  //Get store
  const store = useStore();

  //Setup display size.  Used to determine if we are on a small screen or not
  let { xs, smAndDown } = useDisplay();

  //Variable to hold the card club that is currently selected
  let selectedCardClub = ref([]);

  //Create and load the players list
  let playersData = ref(store.getters["cp/clubPlayers"]);
  let playersDataFiltered = ref(store.getters["cp/clubPlayers"]);

  /* Load default team names in case nothing has been written to a card club or
      to local storage */
  let teamNames = ref([
    { name: t("cpInsert.team1") },
    { name: t("cpInsert.team2") },
    { name: t("cpInsert.team3") },
    { name: t("cpInsert.team4") },
  ]);

  //Variables to hold a flag in case an illegal (blank, too short or duplicate) team name is entered.
  let teamNamesInputError = ref([]);

  /* variables to hold state of players and team names. If true names are legal and a score card can be created, if not user has to fix the team names or player names. */
  let teamNamesReady = true;
  let playersSelectedReady = true;

  // Corrosponding dialogs are shown if value true
  let showDeleteDialog = ref(false);
  let showEditDialog = ref(false);
  let showAddDialog = ref(false);

  //Used when editing the club player name or nickname
  let internalItem = ref({ name: "undefinded", nickname: "undefinded" });
  let restorableName = ref(null);
  let restorableNickName = ref(null);

  //Used to input when user creating a new club player
  let newClubPlayer = ref({
    name: "",
    nickname: "",
    email: null,
    dateCreated: null,
    selected: false,
  });

  //player status line text (pslt)
  let pslt = ref({
    prefix: t("common.select") + "  " + t("common.between"),
    min: 0,
    middle: t("common.and"),
    max: 0,
    suffix: t("common.players"),
  });

  /* Check for demo mode */
  //let disableTeamNameChanges = ref(inDemoMode(false));

  //Watch for changes to the card club ID.  When it changes, load the active teams list
  watchEffect(() => {
    //Make sure card club data is loaded before setting selected
    if (!store.getters["cc/cardClubsDataLoaded"]) {
      loadActiveTeamsList(store.getters["cc/cardClubID"]);
    }
  });

  //Watch for changes to the selected player.  When it changes, update the title bar display
  watch(
    () => store.getters["cp/clubPlayerSelectedChanged"],
    () => {
      //Upate the title bar display
      updateClubPlayersTitle();
    }
  );

  /* When card game changes, update display items */
  watch(
    () => store.getters["cg/gameDataID"],
    (newGameID) => {
      let payLoad = { selectedGame: newGameID };
      store.dispatch("cp/updatePlayerForNewGameSelected", payLoad);
      if (!store.getters["cg/gdsIsGameTypeTeam"]) {
        teamNamesReady = true;
      }
      //Update the title bar display
      updateClubPlayersTitle();
    }
  );

  /* Watch for games played by (teams or individual) to change */
  watch(
    () => store.getters["cg/gdsGamePlayedBy"],
    () => {
      //Upate the title bar display
      updateClubPlayersTitle();
    }
  );

  /* Watch for data to be loaded or the clubPlayers array to be changed */
  watch(
    () => [store.getters["cp/clubPlayerSelectedChanged"]],
    () => {
      //Create and load the players list
      playersData.value = store.getters["cp/clubPlayers"];
      playersDataFiltered.value = store.getters["cp/clubPlayers"];
    }
  );

  /* Load the teams list from the active club to the display variable */
  function loadActiveTeamsList(ccID) {
    store.dispatch("cp/loadClubPlayers", ccID);

    /* load the active card club.  In case the card club does not exist or does not contain a full list of team names setup the default team name list.  */
    selectedCardClub.value = store.getters["cc/selectedCardClub"];

    /* Load last written team names for local storage.  If the team names exist load as default team names */
    let savedTeamNames = JSON.parse(getStorage("teamNames", "name"));
    if (savedTeamNames != null || savedTeamNames != undefined) {
      if (savedTeamNames.length < 4) {
        savedTeamNames = [
          { name: t("cpInsert.team1") },
          { name: t("cpInsert.team2") },
          { name: t("cpInsert.team3") },
          { name: t("cpInsert.team4") },
        ];
      }
    }

    /* If the club contains a list of at least four team names, use the names and write them to the local storage */
    if (
      selectedCardClub.value === "" ||
      selectedCardClub.value.teams.length < 3
    ) {
      selectedCardClub.value = {
        teams: [
          { name: teamNames.value[0].name },
          { name: teamNames.value[1].name },
          { name: teamNames.value[2].name },
          { name: teamNames.value[3].name },
        ],
      };
      saveStorage("teamNames", teamNames.value);
    }
    /*Add the list of team names to the display/v-model list */
    teamNames.value = selectedCardClub.value.teams;

    /* Create a parallel list of error flags for each team name.  Used in case the user makes an illegal edit (duplicate or blank) to the team name */
    teamNames.value.forEach(() => {
      let tie = { inputError: false };
      teamNamesInputError.value.push(tie);
    });
  }

  // Variable for display player selection status
  let playersTitle = ref("");

  /* Update the display variable that has the concantenated title */
  function updateClubPlayersTitleVar() {
    playersTitle.value =
      pslt.value.prefix +
      " " +
      pslt.value.min +
      " " +
      pslt.value.middle +
      " " +
      pslt.value.max +
      " " +
      pslt.value.suffix;
  }

  /* Check to see if the players are selected such that a new score card can be
          created - correct number of players for the game, for teams etc. */
  function updateClubPlayersTitle() {
    // Check if the game type is a team game\

    if (store.getters["cg/gdsIsGameTypeTeam"]) {
      //Get game data needed to check if selections are valid
      let playersT1 = store.getters["cp/cpNumberSelectedT"]("0");
      let playersT2 = store.getters["cp/cpNumberSelectedT"]("1");
      let playersT3 = store.getters["cp/cpNumberSelectedT"]("2");
      let playersT4 = store.getters["cp/cpNumberSelectedT"]("3");
      let minTeamPlayers = store.getters["cg/gdsMinTeamPlayers"];
      let maxTeamPlayers = store.getters["cg/gdsMaxTeamPlayers"];
      let minTeams = store.getters["cg/gdsMinTeams"];
      let maxTeams = store.getters["cg/gdsMaxTeams"];
      let teamsWithCorrectPlayers = 0;
      let numPlayersIncorrect = false;

      //Update the player selection status line text (pslt)
      pslt.value.suffix = " " + t("fiveHundred.playersPerTeam");
      pslt.value.min = minTeamPlayers;
      pslt.value.max = maxTeamPlayers;
      pslt.value.teamMin = minTeams;

      updateClubPlayersTitleVar();

      // Check if all teams have the same number of players
      let playersPerTeam = [];
      if (playersT1 != 0) {
        playersPerTeam.push(playersT1);
      }
      if (playersT2 != 0) {
        playersPerTeam.push(playersT2);
      }
      if (playersT3 != 0) {
        playersPerTeam.push(playersT3);
      }
      if (playersT4 != 0) {
        playersPerTeam.push(playersT4);
      }
      if (playersPerTeam.some((v) => v !== playersPerTeam[0])) {
        numPlayersIncorrect = true;
      }

      // Check if each team has the correct number of players
      if (playersT1 >= minTeamPlayers && playersT1 <= maxTeamPlayers) {
        teamsWithCorrectPlayers++;
      } else if (playersT1 != 0) {
        numPlayersIncorrect = true;
      }
      if (playersT2 >= minTeamPlayers && playersT2 <= maxTeamPlayers) {
        teamsWithCorrectPlayers++;
      } else if (playersT2 != 0) {
        numPlayersIncorrect = true;
      }
      if (playersT3 >= minTeamPlayers && playersT3 <= maxTeamPlayers) {
        teamsWithCorrectPlayers++;
      } else if (playersT3 != 0) {
        numPlayersIncorrect = true;
      }
      if (playersT4 >= minTeamPlayers && playersT4 <= maxTeamPlayers) {
        teamsWithCorrectPlayers++;
      } else if (playersT4 != 0) {
        numPlayersIncorrect = true;
      }

      // Determine if the player selections are valid
      if (numPlayersIncorrect === true) {
        playersSelectedReady = false;
      } else if (
        teamsWithCorrectPlayers >= minTeams &&
        teamsWithCorrectPlayers <= maxTeams
      ) {
        playersSelectedReady = true;
      } else {
        playersSelectedReady = false;
      }

      // Call function to check if selections allow creating a new scorecard
      checkReadyToCreateScoreCard(teamNamesReady, playersSelectedReady);
    } else {
      // Update title for individual player and game data
      let playersSelected = store.getters["cp/cpNumberSelectedT"]("0");

      // Update the player selection status line text (pslt)
      pslt.value.suffix = t("common.players");
      pslt.value.min = store.getters["cg/gdsMinIndividualPlayers"];
      pslt.value.max = store.getters["cg/gdsMaxIndividualPlayers"];

      updateClubPlayersTitleVar();

      // Check if the number of players selected is valid
      if (
        playersSelected >= pslt.value.min &&
        playersSelected <= pslt.value.max
      ) {
        playersSelectedReady = true;
      } else {
        playersSelectedReady = false;
      }
    }

    // Call function to check if selections allow creating a new scorecard
    checkReadyToCreateScoreCard(teamNamesReady, playersSelectedReady);
  }

  /* Check to make sure teams names are legal, the right number of players have been selected and that at least one club has been created and is active */
  function checkReadyToCreateScoreCard(teamNamesReady, playersSelectedReady) {
    if (
      !teamNamesReady ||
      !playersSelectedReady ||
      store.getters["cc/cardClubID"] === null
    ) {
      store.commit("readyToCreateNewScoreCard", false);
    } else {
      store.commit("readyToCreateNewScoreCard", true);
    }
  }

  /* Processes click of checkbox including writing checked status to db */
  function checkBoxChecked(cpID, e) {
    let payLoad = {
      cpID: cpID, //Player ID of row in which checkbox was clicked
      selected: e.target.checked, //whether player is selected or not

      /* checkboxes are name numerically with column number they are in so name is index into list of team names */
      team: e.target.name,
    };
    // Call action to update the club player selected state
    store.dispatch("cp/setClubPlayerSelectedState", payLoad);
  }

  /* Show deleted dialog.  Make selected item info available to deleted dialog */
  function ShowDeleteCardClubDialog(item) {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.deletePlayer"), true)) {
      return;
    }

    internalItem.value = item;
    showDeleteDialog.value = true;
  }

  /* Process confirm delete click in delete dialog.  Club player is deleted from db and delete dialog closed */
  function deleteClubPlayer(id) {
    store.dispatch("cp/deleteClubPlayer", id);
    showDeleteDialog.value = false;
  }

  /* Show the edit dialog.  Ccopy the passed in selected item to working variables that are displayed on the dialog fields. InternalItemSavedName used as title for edit dialog */
  function ShowEditClubPlayerDialog(item) {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.editPlayer"), true)) {
      return;
    }

    internalItem.value = item;
    restorableName.value = internalItem.value.name;
    restorableNickName.value = internalItem.value.nickname;

    showEditDialog.value = true;
  }

  /* Restore orginal values of player name and description and close dialog */
  function cancelUpdateClubPlayer(internalItem) {
    internalItem.name = restorableName.value;
    internalItem.nickname = restorableNickName.value;
    showEditDialog.value = false;
  }

  /* Process update of player edits, name or nickname, after user confirms in edit dialog.  Updates are saved to db and updated in display object */
  function updateClubPlayer(internalItem) {
    //Check for blank player name.  If blank show error message and don't add player
    if (internalItem.name.trim().length < 3) {
      store.commit("showSnackBar", t("cpInsert.playerNameTooShort"));
      return;
    }

    //check for duplicate player name.  If duplicate show error message and don't add player
    if (store.getters["cp/isPlayerNameDuplicate"](internalItem.name) > 1) {
      store.commit("showSnackBar", t("cpInsert.playerNameDuplicate"));
      return;
    }

    store.dispatch("cp/updateClubPlayer", internalItem);
    showEditDialog.value = false;
  }

  /* Show the add player dialog */
  function ShowAddClubPlayerDialog() {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.addPlayer"), true)) {
      return;
    }

    //if no club exists, don't allow players to be added
    if (store.getters["cc/cardClubID"] === null) {
      store.commit("showSnackBar", t("cpInsert.addClubBeforePlayers"));
      return;
    }
    showAddDialog.value = true;
  }

  /* Add player with data from add player dialog after user confirms. Make new player selected */
  function AddClubPlayer(newClubPlayer) {
    //Check for blank player name.  If blank show error message and don't add player
    if (newClubPlayer.name.trim().length < 3) {
      store.commit("showSnackBar", t("cpInsert.playerNameTooShort"));
      return;
    }

    //check for duplicate player name.  If duplicate show error message and don't add player
    if (store.getters["cp/isPlayerNameDuplicate"](newClubPlayer.name) > 0) {
      store.commit("showSnackBar", t("cpInsert.playerNameDuplicate"));
      return;
    }

    //Add new player
    newClubPlayer.selected = true;
    newClubPlayer.dateCreated = new Date();

    let payLoad = {
      ccID: store.getters["cc/cardClubID"],
      newClubPlayer,
    };

    store.dispatch("cp/addClubPlayer", payLoad);

    //Leave dialog open so user can add more than one player
    //Clear name nickname so they aren't shown on next view of add dialog
    newClubPlayer.name = "";
    newClubPlayer.nickname = "";
  }

  /* Checks if the team name is valid and sets the ready to play status. */
  function checkTeamName(i) {
    // Check for blank team name or all spaces
    if (teamNames.value[i].name.trim().length < 3) {
      store.commit("showSnackBar", t("cpInsert.teamNameTooShort"));
      teamNamesInputError.value[i].inputError = true;
      teamNamesReady = false;
    } else {
      teamNamesInputError.value[i].inputError = false;
      teamNamesReady = true;
    }

    // If name is not blank, check for duplicate name
    if (teamNamesReady) {
      let tn = teamNames.value.slice(0, store.getters["cg/gdsMaxTeams"]);
      let dupNames = tn.filter(
        (cc) => cc.name.toUpperCase() === teamNames.value[i].name.toUpperCase()
      );

      if (dupNames.length > 1) {
        store.commit("showSnackBar", t("cpInsert.teamNameDuplicate"));
        teamNamesInputError.value[i].inputError = true;
        teamNamesReady = false;
      } else {
        teamNamesInputError.value[i].inputError = false;
        teamNamesReady = true;
      }
    }

    // Check for an existing error with any of the team names
    let tne = teamNamesInputError.value.filter((e) => e.inputError === true);
    if (tne.length > 0) {
      teamNamesReady = false;
    }

    // If all team names are valid, save them to local storage
    if (teamNamesReady) {
      saveStorage("teamNames", teamNames.value);
      store.dispatch("cc/writeTeamNames", teamNames.value);
    }

    // Set ready to play status based on playersSelectedReady and teamNamesReady
    if (!playersSelectedReady || !teamNamesReady) {
      store.commit("readyToCreateNewScoreCard", false);
    } else {
      store.commit("readyToCreateNewScoreCard", true);
    }
  }

  /* Called by the filter array component when the list of games has been changed.  Copy the new filtered list over the list being displayed to update the display */
  const updatedArray = (filteredArray) => {
    playersDataFiltered.value = filteredArray;
  };

  /* Reload team names if a card club is selected.  This is needed when returning to the home screen so that team names will be reloaded */
  onBeforeMount(() => {
    let ccID = store.getters["cc/cardClubID"];
    if (ccID === null) {
      return;
    }
    loadActiveTeamsList(ccID);
  });

  /* Update the title bar display when the component is mounted */
  onMounted(() => {
    updateClubPlayersTitle();
  });
</script>

<style lang="scss" scoped></style>
