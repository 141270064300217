<template>
  <!-- component shows up as three dots on the far right of the main app menu.  The component is only displayed if the user is an "admin" -->
  <v-menu location="start">
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-icon class="iconButton iconButton-light" icon="mdi-dots-vertical" />
      </v-btn>
    </template>
    //display list of menu items in array
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :value="index"
        density="compact"
      >
        <v-container class="pa-0 ma-0 fluid">
          <v-row :dense="true" :no-gutters="true" class="d-flex ma-0 pa-0">
            <v-col class="ma-0 pa-0" @click="itemClicked(item.selected)">
              {{ item.title }}</v-col
            >
          </v-row>
        </v-container>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
  import { useStore } from "vuex";
  import { ref } from "vue";
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";

  //create function to insert i18N text
  let { t } = useI18n({});
  const store = useStore();
  const router = useRouter();

  let items = ref([
    { title: t("initDbMenu.initGamesDb"), selected: "initGames" },
    { title: t("initDbMenu.createAdminUser"), selected: "createAdmin" },
    { title: t("initDbMenu.createDemoUser"), selected: "createDemoUser" },
    { title: t("initDbMenu.initDemoDemoUser"), selected: "initDemoUser" },
    { title: t("initDbMenu.UpdateClubs"), selected: "updateClubs" },
    { title: t("initDbMenu.UpdateScoreCards"), selected: "updateScoreCards" },
  ]);

  function itemClicked(selected) {
    if (selected === "initGames") {
      store.dispatch("init/initGameData");
    } else if (selected === "createAdmin") {
      router.push({ name: "admin" });
    } else if (selected === "updateClubs") {
      store.dispatch("init/updateClubs");
    } else if (selected === "createDemoUser") {
      createDemoUser();
    } else if (selected === "updateScoreCards") {
      store.dispatch("init/updateScoreCards");
    } else if (selected === "initDemoUser") {
      store.dispatch("initDemo/initDemoUser");
    }
  }

  function createDemoUser() {
    let creds = {
      email: "demouser@demo.com",
      password: "demouser01",
      displayName: "Demo User",
    };
    store.dispatch("auth/registerUserWithEmail", creds);
  }
</script>
