import { getDoc, doc, updateDoc, arrayUnion, setDoc } from "firebase/firestore";
import { db } from "../firebaseInit";

const siteMembers = {
  namespaced: true,
  state() {
    return {
      //Loaded from database.  Info on siteMember that is not part of Auth data
      lastScoreCard: null,
      expiresDate: null,
      isUserSubscriber: false,
    };
  },

  //Getters for site member data
  getters: {
    //Used to get the last score used by the user
    lastScoreCard(state) {
      if (state.siteMember === undefined) {
        return null;
      }
      return state.siteMember.lastScoreCard;
    },

    //Used to get user subscription status, returns true if user is a subscriber
    isUserSubscriber(state) {
      if (state.siteMember === undefined) {
        return false;
      }
      return state.siteMember.isUserSubscriber;
    },

    subscriptionExpiresOn(state) {
      if (state.siteMember === undefined) {
        return null;
      }
      return state.siteMember.expiresDate;
    },

    hasUserEverSubscribed(state) {
      if (state.siteMember === undefined) {
        return false;
      }
      return state.siteMember.hasUserEverSubscribed;
    },
  },

  mutations: {
    /* Load site member data to memory should be called after site member data is loaded from database */
    loadSiteMember(state, payLoad) {
      state.siteMember = payLoad;
    },
  },

  /*  Database reads/writes are in actions. */
  actions: {
    /*Load sitemember (users) data from the db*/
    async loadSiteMember({ commit, dispatch }, userID) {
      //Load siteMember data from db
      const docRef = doc(db, "siteMembers", userID);
      const siteMember = await getDoc(docRef);

      //if siteMember exists, store data in memorty
      if (siteMember.exists) {
        const siteMemberData = siteMember.data();

        //Get the membership expiration date.  If user has not purchases a membership, the date will be in the past
        let expiresDate =
          siteMemberData.transaction[
            siteMemberData.transaction.length - 1
          ].dateExpires.toDate();

        let hasUserEverSubscribed = false;
        if (siteMemberData.transaction.length > 1) {
          hasUserEverSubscribed = true;
        }

        //Store last score card and membership expiration date in memory.  Also store a boolean value that indicates if the user is a subscriber
        let payLoad = {
          lastScoreCard: siteMemberData?.lastScoreCard,
          expiresDate: expiresDate,
          isUserSubscriber: isDateInFuture(expiresDate),
          hasUserEverSubscribed: hasUserEverSubscribed,
        };
        //Update memory
        commit("loadSiteMember", payLoad);
        return;

        //If siteMemeber doesn't exist, create empty site member document with an empty ID for last score card
      } else {
        let payLoad = { userID: userID, lastScoreCard: null };
        dispatch("updateLastScoreCard", payLoad);
      }
    },

    /* update the last score card to the database  */
    async updateLastScoreCard({ dispatch }, payLoad) {
      //Write data to siteMember.
      await updateDoc(doc(db, "siteMembers", payLoad.userID), {
        lastScoreCard: payLoad.lastScoreCard,
      });
      //Reload from the database and update memory
      dispatch("loadSiteMember", payLoad.userID);
    },

    /* Write a membership transaction to the database */
    async writeMembershipTransaction({ dispatch }, payLoad) {
      //Write data to siteMember.
      await updateDoc(doc(db, "siteMembers", payLoad.userID), {
        transaction: arrayUnion(payLoad.transaction),
      });
      //Reload from the database and update memory
      dispatch("loadSiteMember", payLoad.userID);
    },

    //Write the default site member info to the database
    async writeDefaultSiteMemberInfo({ dispatch }, payLoad) {
      //Write data to siteMember.
      await setDoc(doc(db, "siteMembers", payLoad.userID), {
        transaction: payLoad.transaction,
      });
      //Reload from the database and update memory
      dispatch("loadSiteMember", payLoad.userID);
    },
  },
};

//Function to determine if a date is in the future
function isDateInFuture(dateString) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  // Set the time to 00:00:00 to compare only the date part
  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  return inputDate >= currentDate;
}

export default siteMembers;
