import {
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
  deleteUser,
} from "firebase/auth";

/* Import firebase functions*/
import {
  collection,
  where,
  query,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";

import { db } from "../firebaseInit";
import { auth } from "../firebaseInit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../store/firebaseInit";

const cardClubsCollection = collection(db, "cardClubs");
const siteMembersCollection = collection(db, "siteMembers");

const authModule = {
  namespaced: true,
  state() {
    return {
      user: "no user signed in",
      regErrorCode: null,
      regErrorMessage: null,
      userLoggedIn: false,
      showLoginLogOutDialog: false,
      showRegisterDialog: false,
      showAccountDialog: false,
      userIsAdmin: false,
      loginSuccess: false,
      loginMessage: "",
      passwordResetResult: "mlb-Password",
      registerSuccess: false,
      registerMessage: "mlb-register",
    };
  },

  getters: {
    //Getters for auth
    loginResult(state) {
      let result = {
        loggedIn: state.loginSuccess,
        message: state.loginMessage,
      };
      return result;
    },

    registerResult(state) {
      let result = {
        registered: state.registerSuccess,
        message: state.registerMessage,
      };
      return result;
    },

    passwordResetResult(state) {
      return state.passwordResetResult;
    },

    userDisplayName(state) {
      if (!state.user) {
        return "";
      }
      return state.user.displayName;
    },

    userEmailAddress(state) {
      if (!state.user) {
        return "";
      }
      return state.user.email;
    },

    userIsAdmin(state) {
      return state.userIsAdmin;
    },

    /* userIsSubsriber(state) {
      return state.userIsSubsriber;
    }, */

    loggedInSiteMemberID(state) {
      if (state.user) {
        return state.user.uid;
      }
      return null;
    },

    showLoginLogOutDialog(state) {
      return state.showLoginLogOutDialog;
    },

    showRegisterDialog(state) {
      return state.showRegisterDialog;
    },

    showAccountDialog(state) {
      return state.showAccountDialog;
    },

    userLoggedIn(state) {
      return state.userLoggedIn;
    },
  },

  mutations: {
    writeUserLoggedIn(state, payLoad) {
      state.userLoggedIn = payLoad;
    },

    writeLoginResult(state, payLoad) {
      state.loginSuccess = payLoad.loggedIn;
      state.loginMessage = payLoad.message;
    },

    writeRegisterResult(state, payLoad) {
      state.registerSuccess = payLoad.registered;
      state.registerMessage = payLoad.message;
    },

    writePasswordResetResult(state, payLoad) {
      state.passwordResetResult = payLoad;
    },

    updateUserDisplayName(state, newName) {
      state.user.displayName = newName;
    },

    updateEmail(state, newEmail) {
      state.user.email = newEmail;
    },

    showLoginLogOutDialog(state) {
      state.showLoginLogOutDialog = true;
    },

    hideLoginLogOutDialog(state) {
      state.showLoginLogOutDialog = false;
    },

    showRegisterDialog(state) {
      state.showRegisterDialog = true;
    },

    hideRegisterDialog(state) {
      state.showRegisterDialog = false;
    },

    showAccountDialog(state) {
      state.showAccountDialog = true;
    },

    hideAccountDialog(state) {
      state.showAccountDialog = false;
    },
  },

  /*  Database reads/writes are in actions.  */
  actions: {
    // This function is called when a user clicks the "Change Password" button on the account dialog
    async updatePassword({ commit }, payload) {
      // Update the password
      await updatePassword(auth.currentUser, payload.newPassword)
        .then(() => {
          // Show snackbar with success message
          commit("showSnackBar", payload.successText, { root: true });
        })
        .catch(() => {
          // Show snackbar with fail message
          commit("showSnackBar", payload.failText, { root: true });
        });
    },

    // This function is called when a user clicks the "Change Email" button on the account dialog
    async updateEmail({ commit }, payload) {
      let text = "";
      //
      await updateEmail(auth.currentUser, payload.newEmail)
        .then(() => {
          //Get success message from payload
          text = payload.successText;
          commit("updateEmail", payload.newEmail);
        })
        .catch(() => {
          // Get error message from payload
          text = payload.failText;
        });
      // Show snackbar with success or fail message
      commit("showSnackBar", text, { root: true });
    },

    // This function is called when a user clicks the "Change Display Name" button on the account dialog
    async updateProfile({ commit }, payload) {
      let text = "";
      await updateProfile(auth.currentUser, {
        displayName: payload.newDisplayName,
      })
        .then(() => {
          // Get success message from payload
          text = payload.successText;
        })
        .catch(() => {
          // Get error message from payload
          text = payload.failText;
        });
      // Show snackbar with success or fail message
      commit("showSnackBar", text, { root: true });
    },

    /* This function is called to register a new user */
    async registerUserWithEmail({ state, dispatch, commit }, creds) {
      let newUserCreated = false;
      let newUserSiteMemberPayload = null;

      //Create new user
      await createUserWithEmailAndPassword(auth, creds.email, creds.password)
        .then((userCredentials) => {
          state.user = userCredentials.user;

          newUserCreated = true;
          let payLoad = {
            registered: true,
            // This msg text is comming from the i18n file even though i18n is not directly imported in this file.
            message: "register.registerSuccess",
          };
          commit("writeRegisterResult", payLoad);
        })
        .catch((error) => {
          newUserCreated = false;
          state.regErrorCode = error.code;
          state.regErrorMessage = error.message;

          let msg = state.regErrorCode;
          if (state.regErrorCode.includes("use")) {
            // This msg text is comming from the i18n file even though i18n is not directly imported in this file.
            msg = "register.emailAlreadyRegistered";
          }
          let payLoad = {
            registered: false,
            message: msg,
          };
          commit("writeRegisterResult", payLoad);
        });

      //If new user successfully created add new user screen name to profile
      if (newUserCreated) {
        await updateProfile(auth.currentUser, {
          displayName: creds.displayName,
        })
          .then(() => {
            commit("updateUserDisplayName", creds.displayName);
            newUserCreated = true;
          })
          .catch(() => {
            newUserCreated = false;
          });
      }
      /* if new user successfully created add default claims to user.  If user is mike or demouser make admin */
      if (newUserCreated) {
        if (
          creds.email === "mikeblomquist57@hotmail.com" ||
          creds.email === "demouser@demo.com"
        ) {
          let payload = {
            email: creds.email,
            admin: true,
          };

          if (creds.email === "demouser@demo.com") {
            payload.admin = false;
          }

          await dispatch("addProvidedClaims", payload)
            .then(() => {
              //special case for admin user. Set membership to 50 years and 0 days
              newUserSiteMemberPayload = {
                uid: state.user.uid,
                years: 50,
                days: 0,
              };

              newUserCreated = true;
            })
            .catch(() => {
              newUserCreated = false;
            });
        } else {
          await dispatch("addDefaultClaims", creds.email);
          //default case for non-admin user. Set membership to 0 years and -1 days
          newUserSiteMemberPayload = {
            uid: state.user.uid,
            years: 0,
            days: -1,
          };
        }
        //Log user out and then log back in so that updated credentials will
        //take effect
        await dispatch("writeDefaultMembership", newUserSiteMemberPayload);
        await dispatch("signOut");
        await dispatch("signinUserWithEmail", creds);
      }
    },

    /* Add defualt claims to user.  Used to make user a default user with no admin rights */
    // eslint-disable-next-line
    async addDefaultClaims({}, email) {
      let payLoad = {
        email: email,
        admin: false,
      };

      const addAdminRole = httpsCallable(functions, "updateClaims");
      await addAdminRole(payLoad);
    },

    /* Add/change user claims per provided input */
    // eslint-disable-next-line
    async addProvidedClaims({}, payLoad) {
      const addAdminRole = httpsCallable(functions, "updateClaims");
      await addAdminRole(payLoad);
    },

    /* Sign in user with provided email */
    async signinUserWithEmail({ state, commit }, creds) {
      await signInWithEmailAndPassword(auth, creds.email, creds.password)
        .then((userCredentials) => {
          state.user = userCredentials.user;

          let payLoad = {
            loggedIn: true,
            message: "logInOut.userLoggedIn",
          };
          commit("writeLoginResult", payLoad);
          commit("writeUserLoggedIn", true);
        })
        .catch((error) => {
          state.regErrorCode = error.code;
          state.regErrorMessage = error.message;

          let msg = "";
          if (state.regErrorCode.includes("user")) {
            // This msg text is comming from the i18n file even though i18n is not directly imported in this file.
            msg = "logInOut.emailNotFound";
          }
          if (state.regErrorCode.includes("password")) {
            // This msg text is comming from the i18n file even though i18n is not directly imported in this file.
            msg = "logInOut.passwordIncorrect";
          }

          let payLoad = {
            loggedIn: false,
            message: msg,
          };
          commit("writeLoginResult", payLoad);
        });
    },

    /* Send password reset email to the provide email */
    async sendPswdResetEmail({ commit }, email) {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          commit("writePasswordResetResult", "logInOut.passwordResetEmailSent");
        })
        .catch((error) => {
          commit("writePasswordResetResult", error.code);
        });
    },

    //Function to watch for changes in auth state
    authObserver({ state }) {
      onAuthStateChanged(auth, async (user) => {
        //default values used if no one logged in
        state.userLoggedIn = false;
        state.userIsAdmin = false;
        state.userIsSubscriber = false;

        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User

          //user logged in so save state
          state.user = user;
          state.userLoggedIn = true;

          //Get claims for user and set claims state in memory
          let idTokenResult = await state.user.getIdTokenResult();

          if (idTokenResult.claims.admin === true) {
            state.userIsAdmin = true;
          }
        } else {
          //state will be null if user not logged in
          state.user = user;
        }
      });
    },

    async signOut() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch(() => {});
    },

    /* Function to delete user.  This function is called from the account dialog */
    async deleteUser({ state, dispatch }) {
      /* Get a list of all the card clubs associated with a user.
       For each card club, delete the card club.  Deleting the card club deletes all the players and score cards associated with the card club.*/
      const clubsForUser = query(
        cardClubsCollection,
        where("managers", "array-contains", { id: state.user.uid })
      );
      //Get a snapshot of all the records found
      const querySnapshot = await getDocs(clubsForUser);
      //if query returns a result
      if (!querySnapshot.empty) {
        //convert query collection to docs collection
        const allDocs = querySnapshot.docs;

        for (const club of allDocs) {
          await dispatch("cc/deleteCardClub", club.id, { root: true });
        }
      }

      /* Delete the site members record  */
      const docRef = doc(siteMembersCollection, state.user.uid);
      await deleteDoc(docRef);

      /* Delete the Auth user */
      deleteUser(auth.currentUser)
        .then(() => {
          // User deleted.
          dispatch("signOut");
        })
        .catch(() => {});
    },

    /* Function to write the default site member info. This is called when a new user is created. And sets the membership expiration date to 1 day in the past. */
    writeDefaultMembership({ state, dispatch }, payLoad) {
      let smPayLoad = {
        transaction: [
          {
            datePurchase: addToCurrentDate(0, 0),
            dateExpires: addToCurrentDate(payLoad.years, payLoad.days),
            userEmail: state.user.email,
            amountPaid: 0,
            ppOrder: { transaction: null },
          },
        ],
        userID: payLoad.uid,
      };

      dispatch("sm/writeDefaultSiteMemberInfo", smPayLoad, { root: true });
    },
  },
};

//Function to add years and days to current date
function addToCurrentDate(years, days) {
  let newDate = new Date();
  newDate.setFullYear(newDate.getFullYear() + years);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export default authModule;
