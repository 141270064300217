import {
  collection,
  doc,
  getDocs,
  updateDoc,
  writeBatch,
  //deleteField,
} from "firebase/firestore";
import { db } from "../firebaseInit";
//import { FieldValue } from "firebase/firestore";
const cardClubsCollection = collection(db, "cardClubs");
const scoreCardsCollection = collection(db, "scoreCards"); //Used to update score cards

const initDatabaseModule = {
  namespaced: true,
  state() {
    return {
      gameDataTestData: [
        //500
        {
          //Fields common to all games in this section
          name: "gdInsert.cust500",
          deckType: "standard",
          vueFileToLoad: "FiveHundred.vue",
          infoLink: "https://en.wikipedia.org/wiki/500_(card_game)",
          gamePlayedByTeams: true,
          gamePlayedByIndividuals: false,
          minTeamPlayers: 2,
          maxTeamPlayers: 2,
          minTeams: 2,
          maxTeams: 2,
          minIndividualPlayers: -1,
          maxIndividualPlayers: -1,
          notesFileName: "500-info",
          selected: false,
          moreRows: { hasMoreRows: false, name: "500" },

          //Game specific data fields in this section
          bids: [
            {
              bidName: "commonGame.Spades",
              bidNumber: 6,
              bidSuit: "S",
              points: 40,
              bidID: 1,
            },
            {
              bidName: "commonGame.Clubs",
              bidNumber: 6,
              bidSuit: "C",
              points: 60,
              bidID: 2,
            },
            {
              bidName: "commonGame.Diamonds",
              bidNumber: 6,
              bidSuit: "D",
              points: 80,
              bidID: 3,
            },
            {
              bidName: "commonGame.Hearts",
              bidNumber: 6,
              bidSuit: "H",
              points: 100,
              bidID: 4,
            },
            {
              bidName: "commonGame.NoTrump",
              bidNumber: 6,
              bidSuit: "NT",
              points: 120,
              bidID: 5,
            },

            {
              bidName: "commonGame.Spades",
              bidNumber: 7,
              bidSuit: "S",
              points: 140,
              bidID: 6,
            },
            {
              bidName: "commonGame.Clubs",
              bidNumber: 7,
              bidSuit: "C",
              points: 160,
              bidID: 7,
            },
            {
              bidName: "commonGame.Diamonds",
              bidNumber: 7,
              bidSuit: "D",
              points: 180,
              bidID: 8,
            },
            {
              bidName: "commonGame.Hearts",
              bidNumber: 7,
              bidSuit: "H",
              points: 200,
              bidID: 9,
            },
            {
              bidName: "commonGame.NoTrump",
              bidNumber: 7,
              bidSuit: "NT",
              points: 220,
              bidID: 10,
            },

            {
              bidName: "commonGame.Spades",
              bidNumber: 8,
              bidSuit: "S",
              points: 240,
              bidID: 11,
            },

            {
              bidName: "commonGame.Nula",
              bidNumber: 0,
              bidSuit: "-",
              points: 250,
              bidID: 12,
            },

            {
              bidName: "commonGame.Clubs",
              bidNumber: 8,
              bidSuit: "C",
              points: 260,
              bidID: 13,
            },
            {
              bidName: "commonGame.Diamonds",
              bidNumber: 8,
              bidSuit: "D",
              points: 280,
              bidID: 14,
            },
            {
              bidName: "commonGame.Hearts",
              bidNumber: 8,
              bidSuit: "H",
              points: 300,
              bidID: 15,
            },
            {
              bidName: "commonGame.NoTrump",
              bidNumber: 8,
              bidSuit: "NT",
              points: 320,
              bidID: 16,
            },

            {
              bidName: "commonGame.Spades",
              bidNumber: 9,
              bidSuit: "S",
              points: 340,
              bidID: 17,
            },
            {
              bidName: "commonGame.Clubs",
              bidNumber: 9,
              bidSuit: "C",
              points: 360,
              bidID: 18,
            },
            {
              bidName: "commonGame.Diamonds",
              bidNumber: 9,
              bidSuit: "D",
              points: 380,
              bidID: 19,
            },
            {
              bidName: "commonGame.Hearts",
              bidNumber: 9,
              bidSuit: "H",
              points: 400,
              bidID: 20,
            },
            {
              bidName: "commonGame.NoTrump",
              bidNumber: 9,
              bidSuit: "NT",
              points: 420,
              bidID: 21,
            },

            {
              bidName: "commonGame.Spades",
              bidNumber: 10,
              bidSuit: "S",
              points: 440,
              bidID: 22,
            },
            {
              bidName: "commonGame.Clubs",
              bidNumber: 10,
              bidSuit: "C",
              points: 460,
              bidID: 23,
            },
            {
              bidName: "commonGame.Diamonds",
              bidNumber: 10,
              bidSuit: "D",
              points: 480,
              bidID: 24,
            },
            {
              bidName: "commonGame.Hearts",
              bidNumber: 10,
              bidSuit: "H",
              points: 500,
              bidID: 25,
            },
            {
              bidName: "commonGame.DoubleNula",
              bidNumber: 0,
              bidSuit: "-",
              points: 500,
              bidID: 26,
            },
            {
              bidName: "commonGame.NoTrump",
              bidNumber: 10,
              bidSuit: "NT",
              points: 520,
              bidID: 27,
            },
          ],

          customizable: {
            bidToWin: {
              sortOrder: 1,
              title: "fiveHundred.custMustBidToWin",
              value: true,
              note: "fiveHundred.custMustBidToWinNote",
              type: "boolean",
            },
            playNula: {
              sortOrder: 2,
              title: "fiveHundred.playNula",
              value: false,
              note: "fiveHundred.playNulaNote",
              type: "boolean",
            },

            playDoubleNula: {
              sortOrder: 3,
              title: "fiveHundred.playDoubleNula",
              value: false,
              note: "fiveHundred.playDoubleNulaNote",
              type: "boolean",
            },

            allowSixBid: {
              sortOrder: 4,
              title: "fiveHundred.allowSixBid",
              value: false,
              note: "fiveHundred.allowSixBidNote",
              type: "boolean",
            },
          },
        },

        //5 Crowns
        {
          //Fields common to all games in this section
          name: "gdInsert.cust5Crowns",
          deckType: "custom",
          vueFileToLoad: "FiveCrowns.vue",
          infoLink: "https://en.wikipedia.org/wiki/Five_crowns",
          gamePlayedByTeams: false,
          gamePlayedByIndividuals: true,
          minTeamPlayers: -1,
          maxTeamPlayers: -1,
          minTeams: -1,
          maxTeams: -1,
          minIndividualPlayers: 2,
          maxIndividualPlayers: 8,
          notesFileName: "5crowns-info",
          selected: false,
          moreRows: { hasMoreRows: false, name: "5Crowns" },

          //Game specific data fields in this section
          hands: [
            { cards: 3, wildcard: "3" },
            { cards: 4, wildcard: "4" },
            { cards: 5, wildcard: "5" },
            { cards: 6, wildcard: "6" },
            { cards: 7, wildcard: "7" },
            { cards: 8, wildcard: "8" },
            { cards: 9, wildcard: "9" },
            { cards: 10, wildcard: "10" },
            { cards: 11, wildcard: "Jack" },
            { cards: 12, wildcard: "Queen" },
            { cards: 13, wildcard: "King" },
          ],
          customizable: {},
        },

        //Hand and foot Teams
        {
          name: "gdInsert.custHandAndFoot",
          deckType: "standard",
          vueFileToLoad: "HandAndFoot.vue",
          infoLink:
            "https://en.wikipedia.org/wiki/Canasta#Hand_and_Foot_Canasta",
          gamePlayedByTeams: true,
          gamePlayedByIndividuals: true,
          minTeamPlayers: 2,
          maxTeamPlayers: 4,
          minTeams: 2,
          maxTeams: 4,
          minIndividualPlayers: 2,
          maxIndividualPlayers: 8,
          notesFileName: "handandfoot-info",
          selected: false,
          moreRows: { hasMoreRows: false, name: "HandAndFoot" },

          /* Game specific data fields in this section
          labels are constants that are inserted into the score card
          customizable are title/value pairs that are inserted into the score card and will be editable in the score card.  A note can 0ptionally be added to a title/value pair */

          customizable: {
            playPerfectCut: {
              sortOrder: 1,
              title: "handAndFoot.custPlayWithPerfectCut",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            perfectCut: {
              sortOrder: 2,
              title: "handAndFoot.custPointsPerfectCut",
              value: 100,
              note: "genericScoreCard.custNoNote",
            },
            goingOut: {
              sortOrder: 3,
              title: "handAndFoot.custPointsGoingOut",
              value: 100,
              note: "genericScoreCard.custNoNote",
            },
            cleanBook: {
              sortOrder: 4,
              title: "handAndFoot.custPointsCleanBook",
              value: 500,
              note: "genericScoreCard.custNoNote",
            },
            dirtyBook: {
              sortOrder: 5,
              title: "handAndFoot.custPointsDirtyBook",
              value: 300,
              note: "genericScoreCard.custNoNote",
            },
            roundsPerGame: {
              sortOrder: 6,
              title: "handAndFoot.custRoundsPerGame",
              value: 5,
              note: "handAndFoot.custRoundsPerGameNote",
            },
            minMeldR0: {
              sortOrder: 7,
              title: "handAndFoot.custRound1MinMeld",
              value: 50,
              note: "genericScoreCard.custNoNote",
            },
            minMeldR1: {
              sortOrder: 8,
              title: "handAndFoot.custRound2MinMeld",
              value: 75,
              note: "genericScoreCard.custNoNote",
            },
            minMeldR2: {
              sortOrder: 9,
              title: "handAndFoot.custRound3MinMeld",
              value: 100,
              note: "genericScoreCard.custNoNote",
            },
            minMeldR3: {
              sortOrder: 10,
              title: "handAndFoot.custRound4MinMeld",
              value: 125,
              note: "genericScoreCard.custNoNote",
            },
            minMeldR4: {
              sortOrder: 11,
              title: "handAndFoot.custRound5MinMeld",
              value: 150,
              note: "genericScoreCard.custNoNote",
            },
          },
        },

        //Canasta
        {
          name: "canasta.gameName",
          deckType: "standard",
          vueFileToLoad: "CanastaAmerican.vue",
          infoLink:
            "https://en.wikipedia.org/wiki/Canasta#Hand_and_Foot_Canasta",
          gamePlayedByTeams: true,
          gamePlayedByIndividuals: true,
          minTeamPlayers: 2,
          maxTeamPlayers: 4,
          minTeams: 2,
          maxTeams: 4,
          minIndividualPlayers: 2,
          maxIndividualPlayers: 8,
          notesFileName: "canasta-info",
          selected: false,
          moreRows: { hasMoreRows: false, name: "Canasta" },

          /* Game specific data fields in this section
          labels are constants that are inserted into the score card
          customizable are title/value pairs that are inserted into the score card and will be editable in the score card.  A note can 0ptionally be added to a title/value pair */

          customizable: {
            goingOut: {
              sortOrder: 10,
              title: "handAndFoot.custPointsGoingOut",
              value: 100,
              note: "genericScoreCard.custNoNote",
            },

            //#region - Aces
            /* Variable to show/hide Aces Natural on Score Card */
            scoreAcesNatural: {
              sortOrder: 20,
              title: "canasta.custPlayAcesNatural",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for Aces Natural points */
            acesNatural: {
              sortOrder: 21,
              title: "canasta.custAcesNatural",
              value: 2500,
              note: "genericScoreCard.custNoNote",
            },

            /* Variable to show/hide Aces open on Score Card */
            scoreAcesOpen: {
              sortOrder: 22,
              title: "canasta.custPlayAcesOpen",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for Aces open points */
            acesOpen: {
              sortOrder: 23,
              title: "canasta.custAcesOpen",
              value: -2500,
              note: "genericScoreCard.custNoNote",
            },

            /* Variable to show/hide aces more than two in hand on Score Card */
            scoreAcesMoreThanTwo: {
              sortOrder: 24,
              title: "canasta.custPlayAcesMoreThanTwo",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for Aces More Than Two in hand points */
            acesMoreThanTwo: {
              sortOrder: 25,
              title: "canasta.custAcesMoreThanTwo",
              value: -1500,
              note: "genericScoreCard.custNoNote",
            },
            //#endregion

            //#region - Sevens
            /* Variable to show/hide Sevens Natural on Score Card */
            scoreSevensNatural: {
              sortOrder: 30,
              title: "canasta.custPlaySevensNatural",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for Aces Natural points */
            sevensNatural: {
              sortOrder: 31,
              title: "canasta.custSevensNatural",
              value: 2500,
              note: "genericScoreCard.custNoNote",
            },

            //Variable to show/hide sevens open on Score Card
            scoreSevensOpen: {
              sortOrder: 32,
              title: "canasta.custPlaySevensOpen",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            //Variable for sevens open points
            sevensOpen: {
              sortOrder: 33,
              title: "canasta.custSevensOpen",
              value: -2500,
              note: "genericScoreCard.custNoNote",
            },

            /* Variable to show/hide sevens more than two in hand on Score Card */
            scoreSevensMoreThanTwo: {
              sortOrder: 34,
              title: "canasta.custPlaySevensMoreThanTwo",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for sevens More Than Two in hand points */
            sevensMoreThanTwo: {
              sortOrder: 35,
              title: "canasta.custSevensMoreThanTwo",
              value: -1500,
              note: "genericScoreCard.custNoNote",
            },

            //#endregion

            //#region - Wilds
            /* Variable to show/hide wilds Canasta on Score Card */
            scoreWildCanasta: {
              sortOrder: 40,
              title: "canasta.custPlayWildCanasta",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for wild Canasta points */
            wildCanasta: {
              sortOrder: 41,
              title: "canasta.custWildCanasta",
              value: 2000,
              note: "genericScoreCard.custNoNote",
            },

            /* Variable to show/hide wilds Canasta with 4 jokers on Score Card */
            scoreWildCanasta4J: {
              sortOrder: 42,
              title: "canasta.custPlayWildCanasta4J",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for wild Canasta points */
            wildCanasta4J: {
              sortOrder: 43,
              title: "canasta.custWildCanasta4J",
              value: 2500,
              note: "genericScoreCard.custNoNote",
            },

            /* Variable to show/hide Deuces Canasta on Score Card */
            scoreDeucesCanasta: {
              sortOrder: 44,
              title: "canasta.custPlayDuecesCanasta",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for Deuces Canasta points */
            deucesCanasta: {
              sortOrder: 45,
              title: "canasta.custDeucesCanasta",
              value: 3000,
              note: "genericScoreCard.custNoNote",
            },

            /* Variable to show/hide open wild on Score Card */
            scoreOpenWild: {
              sortOrder: 46,
              title: "canasta.custPlayOpenWild",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            /* Variable for Deuces Canasta points */
            openWild: {
              sortOrder: 47,
              title: "canasta.custOpenWild",
              value: -2000,
              note: "genericScoreCard.custNoNote",
            },
            //endregion

            /* Variable to show/hide threes scoring on Score Card */
            scoreThrees: {
              sortOrder: 50,
              title: "canasta.custPlayThrees",
              value: true,
              type: "boolean",
              note: "genericScoreCard.custNoNote",
            },

            cleanBook: {
              sortOrder: 51,
              title: "canasta.custPointsCleanBook",
              value: 500,
              note: "genericScoreCard.custNoNote",
            },
            dirtyBook: {
              sortOrder: 52,
              title: "canasta.custPointsDirtyBook",
              value: 300,
              note: "genericScoreCard.custNoNote",
            },
            level1Threshold: {
              sortOrder: 62,
              title: "canasta.custLevel1Threshold",
              value: 3000,
              note: "genericScoreCard.custNoNote",
            },
            level1MinMeld: {
              sortOrder: 61,
              title: "canasta.custLevel1MeldPoints",
              value: 125,
              note: "genericScoreCard.custNoNote",
            },
            level2Threshold: {
              sortOrder: 64,
              title: "canasta.custLevel2Threshold",
              value: 5000,
              note: "genericScoreCard.custNoNote",
            },
            level2MinMeld: {
              sortOrder: 63,
              title: "canasta.custLevel2MeldPoints",
              value: 155,
              note: "genericScoreCard.custNoNote",
            },
            level3MinMeld: {
              sortOrder: 65,
              title: "canasta.custLevel3MeldPoints",
              value: 180,
              note: "genericScoreCard.custNoNote",
            },
            gameEndsScore: {
              sortOrder: 66,
              title: "canasta.custGameEndsScore",
              value: 8500,
              note: "genericScoreCard.custNoNote",
            },
            roundsToPrint: {
              sortOrder: 70,
              title: "canasta.custRoundsToPrint",
              value: 10,
              note: "canasta.custRoundsToPrintNote",
            },
          },
        },

        //Generic score card
        {
          name: "gdInsert.custGeneric",
          deckType: "standard",
          vueFileToLoad: "GenericScoreCard.vue",
          infoLink: "",
          gamePlayedByTeams: true,
          gamePlayedByIndividuals: true,
          minTeamPlayers: 2,
          maxTeamPlayers: 4,
          minTeams: 2,
          maxTeams: 4,
          minIndividualPlayers: 1,
          maxIndividualPlayers: 8,
          notesFileName: "generic-info",
          selected: false,
          moreRows: { hasMoreRows: true, name: "generic" },

          /* Game specific data fields in this section
          labels are constants that are inserted into the score card
          customizable are title/value pairs that are inserted into the score card and will be editable in the score card.  A note can optionally be added to a title/value pair */

          customizable: {
            rowsToPrint: {
              sortOrder: 1,
              title: "genericGame.rowsToPrint",
              value: 10,
              note: "genericGame.rowsToPrintNote",
            },
          },
        },
      ],
    };
  },

  getters: {},
  mutations: {},

  //Database reads/writes are in actions
  actions: {
    initGameData({ state }) {
      const batch = writeBatch(db);
      state.gameDataTestData.forEach((cc, index) => {
        let myID = "id" + index;
        const docRef = doc(db, "gamesData", myID);
        batch.set(docRef, cc);
      });

      batch.commit();
    },

    //Updates card clubs to ensure that all card clubs have the teams field.
    async updateClubs() {
      const snapshot = await getDocs(cardClubsCollection).catch(() => {
        return;
      });

      if (snapshot === undefined || snapshot.docs.length === 0) {
        //If no card clubs found return
        return;
      }
      let payload = [
        { name: "team 1" },
        { name: "team 2" },
        { name: "team 3" },
        { name: "team 4" },
      ];

      snapshot.docs.forEach((d) => {
        /* Checks to see if card clubs has proper data.  Used to ensure that card club data is fields/format is out of date */
        if (d.data().teams === undefined) {
          //Get reference to item being updated, then update with data passed in
          const docRef = doc(db, "cardClubs", d.id);
          updateDoc(docRef, { teams: payload });
        }
      });
    },

    async updateScoreCards() {
      const snapshot = await getDocs(scoreCardsCollection);

      const batchAdd = writeBatch(db);
      snapshot.forEach((d) => {
        if (
          d.data().customizable &&
          d.data().customizable.roundsToPrint.value === 10
        ) {
          batchAdd.update(d.ref, {
            "customizable.roundsToPrint": {
              sortOrder: 70,
              title: "canasta.custRoundsToPrint",
              value: 5,
              note: "canasta.custRoundsToPrintNote",
            },
          });
        }
      });
      //await batchDel.commit();
      await batchAdd.commit();
    },
  },
};

export default initDatabaseModule;
