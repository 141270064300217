<template>
  <!-- Register dialog uses the standard dialog with an extensive insert to the slot -->
  <TheStandardDialog
    :showDialog="store.getters['auth/showRegisterDialog']"
    :titleText="t('register.register')"
    insertText="not-used"
    explanationText="not-used"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('register.register')"
    :leftBtnDisabled="false"
    :rightBtnDisabled="registerBtnDisabled"
    @leftBtn="closeDialog"
    @rightBtn="register"
  >
    <!-- Input fields for register dialog -->
    <template v-slot:dSlot>
      <v-container class="pa-0" fluid>
        <!-- Display name -->
        <v-form v-model="displayNameValid">
          <v-text-field
            prepend-inner-icon="mdi-account-circle"
            v-model="displayName"
            :rules="[nameRules.required, nameRules.counter]"
            :label="t('register.displayName')"
            hide-details="auto"
            density="compact"
            class="mx-auto ma-4"
          ></v-text-field>
        </v-form>
        <!-- Email  -->
        <v-form v-model="emailValid">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            :label="t('register.email')"
            required
            hide-details="auto"
            density="compact"
            class="mx-auto ma-4"
          ></v-text-field>
        </v-form>
        <!-- Password -->
        <v-form v-model="passwordValid">
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="showPassword = !showPassword"
            :rules="passwordRules"
            :label="t('register.password')"
            required
            hide-details="auto"
            density="compact"
            class="mx-auto ma-4"
          ></v-text-field>

          <!-- Message tell user if passwords do not match -->
          <v-row :dense="true" :no-gutters="true" class="d-flex">
            <v-col cols="12" class="d-flex justify-center text-center my-auto">
              <v-label class="text-h6 font-weight-bold inputError-noBox">{{
                passwordMatchMsg
              }}</v-label>
            </v-col>
          </v-row>

          <!-- Confirmation password -->
          <v-text-field
            v-model="retypePassword"
            :type="showPasswordReType ? 'text' : 'password'"
            :append-inner-icon="showPasswordReType ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="showPasswordReType = !showPasswordReType"
            :rules="passwordRules"
            :label="t('register.retypePassword')"
            required
            hide-details="auto"
            density="compact"
          ></v-text-field>
        </v-form>

        <!-- Captcha input field and captch refresh button and captcha display -->
        <v-row
          :no-gutters="true"
          class="d-flex ma-0 justify-left align-center mt-2"
        >
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="captchaInputValue"
              :label="t('register.enterCaptcha')"
              hide-details="auto"
              density="compact"
              class="mx-auto ma-4"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex ma-0 justify-center align-center"
          >
            <VueClientRecaptcha
              :value="captchaInputValue"
              :hideLines="false"
              count="4"
              @getCode="getCaptchaCode"
              @isValid="checkValidCaptcha"
              canvasClass="captchaCanvas"
              width="145"
              height="40"
              class="d-flex"
            >
            </VueClientRecaptcha>
          </v-col>
        </v-row>

        <!-- Display terms and conditions button -->
        <v-row
          ><v-col cols="12" class="text-center">
            <v-btn
              @click="store.commit('tAndCDialogShow')"
              size="small"
              class="stdBtn elevation-1"
            >
              {{ t("register.termsAndConditions") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </TheStandardDialog>
</template>
<script setup>
  import TheStandardDialog from "../reusable/TheStandardDialog.vue";
  import { useStore } from "vuex";
  import { ref, watchEffect } from "vue";
  import VueClientRecaptcha from "vue-client-recaptcha";
  import { useI18n } from "vue-i18n";

  //create function to insert i18N text
  let { t } = useI18n({});

  //create function to access vuex store
  const store = useStore();

  //create control when register button is disabled
  let registerBtnDisabled = ref(true);

  //create variable to tell if display name is valid
  let displayNameValid = ref(false);

  //create variable to tell if email and password are valid
  let emailValid = ref(false);
  let passwordValid = ref(false);

  //setup input and rules variables for display name
  let displayName = ref("");
  let nameRules = {
    required: (v) => !!v || t("register.displayNameRequired"),
    counter: (v) =>
      (v && v.length <= 30 && v.length >= 3) ||
      t("register.displayNameInvalid"),
  };

  //setup input and rules variables for email
  let email = ref("");
  let emailRules = [
    (v) => !!v || t("register.emailRequired"),
    (v) =>
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || t("register.emailInvalid"),
  ];

  //setup input and rules variables for password
  let password = ref("");
  let retypePassword = ref("");
  let passwordMatchMsg = ref("");
  let showPassword = ref(false);
  let showPasswordReType = ref(false);
  let passwordRules = [
    (v) => !!v || t("register.passwordRequired"),
    (v) =>
      /(?=.*\d)(?=.*[a-zA-Z]).{6,}/.test(v) || t("register.passwordInvalid"),
  ];

  /* pass value to captcha  */
  const captchaInputValue = ref();
  let captchaPass = ref(false);

  /* Watch for inputs to change and update validity status of each */
  watchEffect(() => {
    if (
      displayNameValid.value === true &&
      emailValid.value === true &&
      passwordValid.value == true &&
      password.value === retypePassword.value &&
      captchaPass.value === true
    ) {
      registerBtnDisabled.value = false;
      passwordMatchMsg.value = "";
    } else {
      registerBtnDisabled.value = true;
      if (password.value != retypePassword.value) {
        passwordMatchMsg.value = t("register.passwordsDoNotMatch");
      } else {
        passwordMatchMsg.value = "";
      }
    }
  });

  function checkValidCaptcha(value) {
    /* If user input is the same as the captcha value, value is true otherwise false */
    captchaPass.value = value;
  }

  function getCaptchaCode() {}

  function closeDialog() {
    store.commit("auth/hideRegisterDialog");
  }

  /* Register the new user */
  async function register() {
    let creds = {
      email: email.value.toLowerCase(),
      password: password.value,
      displayName: displayName.value,
    };
    await store.dispatch("auth/registerUserWithEmail", creds);

    let registerResult = store.getters["auth/registerResult"];
    store.commit("showSnackBar", t(registerResult.message));

    if (registerResult.registered) {
      store.commit("auth/hideRegisterDialog");
    }
  }
</script>

<style scoped lang="scss">
  @import url("/node_modules/vue-client-recaptcha/dist/style.css");
</style>
