<template>
  <TheStandardDialog
    :showDialog="store.getters['auth/showLoginLogOutDialog']"
    :titleText="
      store.getters['auth/userLoggedIn']
        ? t('logInOut.logout')
        : t('logInOut.login')
    "
    insertText="not-used"
    explanationText="not-used"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="
      store.getters['auth/userLoggedIn']
        ? t('logInOut.logout')
        : t('logInOut.login')
    "
    :leftBtnDisabled="false"
    :rightBtnDisabled="
      store.getters['auth/userLoggedIn'] ? false : loginBtnDisabled
    "
    @leftBtn="closeDialog"
    @rightBtn="loginOut"
  >
    <template v-slot:dSlot>
      <div v-if="!store.getters['auth/userLoggedIn']">
        <v-form v-model="formEmailValid">
          <v-text-field
            prepend-icon="mdi-account-circle"
            v-model="email"
            autofocus
            :rules="emailRules"
            :label="t('logInOut.email')"
            required
            hide-details="auto"
            density="compact"
            class="card-field-width mx-auto pa-0 ma-0"
          ></v-text-field>
        </v-form>
        <v-form v-model="formPasswordValid">
          <v-text-field
            prepend-icon="mdi-lock"
            v-model="password"
            @keyup.enter="login"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            :label="t('logInOut.password')"
            required
            hide-details="auto"
            density="compact"
            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="showPassword = !showPassword"
            class="card-field-width mx-auto pa-0 ma-0"
          ></v-text-field>
        </v-form>
        <v-row>
          <v-col class="text-center">
            <v-btn
              @click="sendResetPasswordEmail"
              varient="text"
              size="x-small"
              class="stdBtn mt-4"
              :disabled="fpBtnDisabled"
              >{{ t("logInOut.forgotPassword") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </TheStandardDialog>
</template>
<script setup>
  import TheStandardDialog from "../reusable/TheStandardDialog.vue";
  import { useStore } from "vuex";
  import { ref, watchEffect } from "vue";
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";

  //create function to insert i18N text
  let { t } = useI18n({});

  //create function to use router
  const router = useRouter();

  //Create function to use vuex store
  const store = useStore();

  //Setup for email and password input
  let email = ref("");
  //email rules, must be valid formate and is required
  let emailRules = [
    (v) => !!v || t("logInOut.emailRequired"),
    (v) =>
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || t("logInOut.emailInvalid"),
  ];
  //used to disable the login button if email or password is invalid
  let formEmailValid = ref(false);
  let formPasswordValid = ref(false);
  //used to disable the forgot password button if email is invalid
  let fpBtnDisabled = ref(true);
  let loginBtnDisabled = ref(true);

  let password = ref("");
  /* password rules, must be at least 6 characters and contain at least one number and one letter */
  let passwordRules = [
    (v) => !!v || t("logInOut.passwordRequired"),
    (v) =>
      /(?=.*\d)(?=.*[a-zA-Z]).{6,}/.test(v) || t("logInOut.passwordInvalid"),
  ];
  //used when user clicks on the eye icon to show password
  let showPassword = ref(false);

  /* Watch for changes to the email and password entered values */
  watchEffect(() => {
    //watch for changes to email and update the disabled state of the forgot password button.  Button is disabled if email is invalid
    if (email.value === "" || formEmailValid.value === false) {
      fpBtnDisabled.value = true;
    } else {
      fpBtnDisabled.value = false;
    }

    //watch for changes to email and password and update the disabled state of the login button.  Button is disabled if email or password is invalid
    if (
      email.value === "" ||
      formEmailValid.value === false ||
      password.value === "" ||
      formPasswordValid.value === false
    ) {
      loginBtnDisabled.value = true;
    } else {
      loginBtnDisabled.value = false;
    }
  });

  //Function to close dialog
  function closeDialog() {
    store.commit("auth/hideLoginLogOutDialog");
  }

  //With standard dialog, the right button is used for login and logout.  This function determines which to do and calls the appropriate function.
  function loginOut() {
    if (store.getters["auth/userLoggedIn"]) {
      logOut();
    } else {
      login();
    }
  }

  //Function to logout user
  function logOut() {
    //Logout user and close dialog box
    store.dispatch("auth/signOut");
    store.commit("auth/hideLoginLogOutDialog");
    //Redirect to home page
    router.push({ name: "home" });
  }

  //Function to login user
  async function login() {
    //Get login credentials from form
    let creds = {
      email: email.value.toLowerCase(),
      password: password.value,
    };

    //Call login function and get result
    await store.dispatch("auth/signinUserWithEmail", creds);
    //Get login result from store
    let loginResult = store.getters["auth/loginResult"];

    //Result is a message and a boolean indicating if login was successful. Message is the key for the i18n text.
    store.commit("showSnackBar", t(loginResult.message));

    //If login was successful, close dialog box and clear form
    if (loginResult.loggedIn) {
      store.commit("auth/hideLoginLogOutDialog");
      email.value = "";
      password.value = "";
    }
  }

  async function sendResetPasswordEmail() {
    await store.dispatch(
      "auth/sendPswdResetEmail",
      email.value.toLocaleLowerCase()
    );
    let passwordResetResult = store.getters["auth/passwordResetResult"];
    store.commit("showSnackBar", t(passwordResetResult));
  }
</script>
<style scoped></style>
