import store from "../../store/index";

/* Flag to override demo mode during dev */
let demoModeOverride = false;

function inDemoMode(message, showSnackBar = true) {
  if (demoModeOverride) {
    return;
  }

  /* Check for demo mode.  If user email is not demo user email return false*/
  if (store.getters["auth/userEmailAddress"] != "demouser@demo.com") {
    return false;
  }

  /* If demo mode is enabled and showSnackBar is true, show snackbar and return true */
  if (showSnackBar) {
    store.commit("showSnackBar", message);
  }
  /* Return true even if snackbar not shown */
  return true;
}

function inDemoModeTest() {
  if (demoModeOverride) {
    return;
  }

  /* Check for demo mode.  If user email is not demo user email return false*/
  if (store.getters["auth/userEmailAddress"] != "demouser@demo.com") {
    return false;
  } else {
    return true;
  }
}

export { inDemoMode, inDemoModeTest };
