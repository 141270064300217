export default {
  "common": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
    "Player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["player"])},
    "Players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players"])},
    "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["players"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["between"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NA"])},
    "The": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The"])},
    "the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the"])},
    "Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])}
  },
  "commonGame": {
    "bidder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidder"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid"])},
    "tricksTaken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tricks Taken"])},
    "wins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wins: "])},
    "Wins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wins: "])},
    "win!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Win!"])},
    "wins!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Wins!"])},
    "Losses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Losses: "])},
    "winners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winner(s)"])},
    "mostWins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Wins"])},
    "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
    "L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "WinsSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wins Summary"])},
    "WinningPercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Wins"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game "])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
    "gameOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Over"])},
    "haveTied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" have tied for the Win!"])},
    "moreHaveTied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game over - more than three people have tied for the Win!"])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand "])},
    "hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hand"])},
    "clickToDeleteHands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to delete hands and restart current game"])},
    "Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "Scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score"])},
    "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scores"])},
    "winner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winner"])},
    "dateFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "winBecause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Win because "])},
    "wentOutBackwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" went out backwards"])},
    "selectBiddingTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bidding team"])},
    "selectBid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bid"])},
    "selectTricksTaken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select tricks taken"])},
    "sureToRestart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to restart the game?"])},
    "editCellValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Score"])},
    "jack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jack"])},
    "queen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queen"])},
    "king": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["King"])},
    "saveHand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Hand"])},
    "resumingSavedGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resuming Saved Game"])},
    "scoreInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The score entered score is not valid"])},
    "onlyPlayedRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only scores in rows that have been played can be edited"])},
    "fixBeforeSaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix the highlighted score before saving"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deal"])},
    "wild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wild"])},
    "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
    "gameTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Totals"])},
    "gameTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Total"])},
    "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teams"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])},
    "Teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "roundTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round Total"])},
    "Round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round"])},
    "saveRound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Round"])},
    "meldIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld is:"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Cumulative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulative"])},
    "roundScores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round Scores"])},
    "completedGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Games"])},
    "winsWithScoreOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wins with a score of"])},
    "scoringReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Reference"])},
    "loses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loses"])},
    "gameEndsWhenP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game ends when a"])},
    "gameEndsWhenP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reaches"])},
    "gameEndsWhenP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game ends when"])},
    "gameEndsWhenP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rounds have been played"])},
    "whichScoreWinsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with the"])},
    "whichScoreWinsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score wins."])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
    "highest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["highest"])},
    "lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lowest"])},
    "spades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spades"])},
    "Spades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spades"])},
    "clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clubs"])},
    "Clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clubs"])},
    "hearts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hearts"])},
    "Hearts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hearts"])},
    "diamonds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diamonds"])},
    "Diamonds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamonds"])},
    "NoTrump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Trump"])},
    "Nula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nula"])},
    "DoubleNula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Nula"])}
  },
  "app": {
    "nameTM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Cards™"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Cards"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ttGetHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click for Help"])},
    "ttChangeLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to change language"])},
    "ttUpdateAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to update your account information"])},
    "aboutScoreCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Score Cards"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
    "showTandC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Terms and Conditions"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Score Cards"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Cards is a web application that allows you to create and manage score cards for any purpose."])}
  },
  "contactInfo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Cards"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have Questions or comments, please contact by email at:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin at Score Cards"])}
  },
  "home": {
    "sureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Score Card?"])},
    "delWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The score card will be permanently deleted!"])},
    "noScoreCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Score Cards to delete"])},
    "P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immerse yourself in the ultimate gaming experience with Score Cards, the fun and effortless solution to keep track of all your scores. We offer a unique platform where you can create personalized score cards for a growing list of games."])},
    "P2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you part of a group that plays regularly? Create clubs and keep everyone's scores updated with just a few clicks. Scores are saved so, over time, you can see who really has the score to brag about."])},
    "P3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For those who prefer the traditional way, we've got you covered too! You can easily print out a custom score card for the game your playing or a score card for each player to use while playing."])},
    "P5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to revolutionize your gaming experience? Register or log in to get started, or click below to explore our site as a demo user."])},
    "P4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the future of gaming with Score Cards!"])},
    "funEasyL7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplify your gaming experience and never lose track of scores again!"])},
    "logInOrRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get started, register, Log In or click below to explore the site as a demo user."])},
    "siteStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently this site is in early Beta testing and may have some glitches. However, feel free to register and use the site. Score Cards for additional games will be added over time."])},
    "selectAnExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an existing score card above or select a club, game and players below to create a new Score Card"])},
    "openScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Score Card"])},
    "ttDeleteScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Score Card"])},
    "sbNoScoreCardSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First, select or create a score card to open"])},
    "selectAScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Score Card"])},
    "demoMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo Mode"])}
  },
  "scSetupStatus": {
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Card"])},
    "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Card"])},
    "freeAccounta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users with a free account can have only one Score Card. \n To create another Score Card, purchase a one year subscription for $"])},
    "freeAccountb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".  Click the account icon on the top menu bar to upgrade to a paid account or delete your existing Score Card"])}
  },
  "ccInsert": {
    "sureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete"])},
    "delWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The club will be permanently deleted!"])},
    "editClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Club"])},
    "addClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Club"])},
    "updateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Name"])},
    "updateDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Description (optional)"])},
    "newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a New Club Name"])},
    "newDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a New Club Description (optional)"])},
    "ttAddNewClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Add a New Club"])},
    "ttClickToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Select"])},
    "ttClickToEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Edit"])},
    "ttClickToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Delete"])},
    "ttNoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has no description"])},
    "selectAClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Club"])},
    "addAClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click the + to Add a Club"])},
    "ttClubNameTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club Name must be at least 3 characters long"])},
    "ttClubNameDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please re-enter.  Duplicate club names not allowed"])},
    "searchClubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Clubs List"])}
  },
  "gdInsert": {
    "selectAGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Game"])},
    "ttClickToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Select"])},
    "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Info"])},
    "playWithTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play with Teams"])},
    "playWithIndividuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play with Individuals"])},
    "ttSelectToCreateTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select to create a score card with teams"])},
    "ttSelectToCreateIndividuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select to create a score card with individuals"])},
    "ttcreateWinnerHighestScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a score card where the winner has the highest score"])},
    "ttcreateWinnerLowestScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a score card where the winner has the lowest score"])},
    "highScoreWins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High Score Wins"])},
    "lowScoreWins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Score Wins"])},
    "endsAfterRounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends After Rounds = "])},
    "endsWhenPointsReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends When Points = "])},
    "ttEndsAfterRounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a score card with a fixed number of rounds to play"])},
    "ttEndsWhenPointsReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a score card where the game is played to a fixed score"])},
    "searchGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Games List"])},
    "cust500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500"])},
    "cust5Crowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Crowns"])},
    "custHandAndFoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand and Foot"])},
    "custGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Game"])}
  },
  "cpInsert": {
    "sureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete"])},
    "delWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The player will be permanently deleted!"])},
    "editPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Player"])},
    "updatePlayerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Player Name"])},
    "updatePlayerNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Player Nick Name (optional)"])},
    "addPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Player"])},
    "newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a New Player Name"])},
    "newNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a New Player Nick Name (optional)"])},
    "nameTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Team"])},
    "ttNotReadyToCreateScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When this icon is red, the proper number of teams and/or players have not been selected and a new score card cannot be created"])},
    "ttReadyToCreateScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When this icon is green, the proper number of teams and/or players have been selected and a new score card can be created"])},
    "ttClickToAddPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add a new player"])},
    "ttClickToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Select"])},
    "ttForThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for the"])},
    "ttClickToEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to edit"])},
    "ttClickToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to delete"])},
    "playerNameTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Name must be at least 3 characters long"])},
    "playerNameDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please re-enter.  Duplicate player names not allowed"])},
    "addClubBeforePlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before adding players, you must first add a Club"])},
    "noNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Nickname"])},
    "teamNameTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Name must be at least 3 characters long"])},
    "teamNameDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please re-enter.  Duplicate team names not allowed"])},
    "team1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team 1"])},
    "team2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team 2"])},
    "team3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team 3"])},
    "team4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team 4"])},
    "searchPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Players List"])}
  },
  "ssScoreCardNameHeader": {
    "clickToRename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to rename score card"])},
    "clickToCustomize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to customize settings for this score card"])}
  },
  "logInOut": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?  Click to send reset email"])},
    "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
    "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is invalid"])},
    "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
    "passwordInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain at least 6 characters and contain a least one number"])},
    "userLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Successfully Logged In"])},
    "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not found, try agian"])},
    "passwordIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password incorrect, try again or click Forgot Password to reset your password"])},
    "passwordResetEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset email sent"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name (required)"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email (required)"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password (required)"])},
    "retypePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retype Password (required)"])},
    "enterCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Captcha (required)"])},
    "refreshCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh Captcha"])},
    "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view terms and conditions"])},
    "registerSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Successful"])},
    "emailAlreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already registered, try again"])},
    "passwordInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain at least 6 characters and contain a least one letter and one number"])},
    "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is invalid"])},
    "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
    "displayNameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name is required"])},
    "displayNameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name must contain at least 3 characters but no more than 30 characters"])},
    "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
    "passwordsDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])}
  },
  "initDbMenu": {
    "initGamesDb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize games data collection"])},
    "createAdminUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create admin and paid users"])},
    "UpdateClubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Clubs to latest version"])},
    "createDemoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create demo user"])},
    "UpdateScoreCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Score Cards to latest version"])},
    "initDemoDemoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize demo user"])}
  },
  "adminView": {
    "updateClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Admin/Claims"])},
    "pageNotAccessable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page is not accessable without admin privileges"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email of user to update"])},
    "adminFunctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Functions"])},
    "removeAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Admin"])},
    "makeAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Admin"])},
    "removeSubscriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Subscriber"])},
    "makeSubscriber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Subscriber"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "pageNotFound": {
    "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found.  Click HOME on menu bar to return"])}
  },
  "accountInsert": {
    "updateAccountTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your account settings"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password"])},
    "retypePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retype New Password"])},
    "changeDisplayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Display Name"])},
    "newDisplayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Display Name"])},
    "changeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Email"])},
    "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Email"])},
    "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
    "cautionCannotBeUndone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution: This action cannot be undone!"])},
    "deleteConfirmationPhrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I'm Sure"])},
    "deleteConfirmationPhraseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type - 'Yes, I'm Sure'"])},
    "nameIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
    "nameMustBeAtLeast3Chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name must be at least 3 characters and less than 30 characters"])},
    "displayNameUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name Successfully Updated"])},
    "displayNameNotUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name Not Updated, something went wrong, try again"])},
    "emailIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
    "emailIsInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is invalid"])},
    "emailUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Successfully Updated"])},
    "emailNotUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Not Updated, something went wrong, try again"])},
    "passwordsDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "passwordIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
    "passwordIsInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must contain at least 6 characters and contain a least one letter and one number"])},
    "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Successfully Updated"])},
    "passwordNotUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Not Updated, something went wrong, try again"])},
    "mustEnterVerificationPhrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter the verification phrase"])},
    "verificationPhraseIsIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification phrase is incorrect"])}
  },
  "payPal": {
    "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your purchase"])},
    "yourMemebershipExpiredOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your membership expired on "])},
    "youCanPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" you can purchase a one year subscription to Score Cards. The subscription will be applied to the "])},
    "yourMembershipExpiresIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your membership expires in"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" day(s) on "])},
    "subscriptionDoesNotAutoRenew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".\n\nYour subscription does not automatically renew each year, but if you like Score Cards you can renew your subsription at any time for $"])},
    "renewalAppliedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".\n\nA subscription renewal will be applied to the "])},
    "allowsCreateSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" login and allows you to create and save an unlimitied number of Score Cards. If purchased today, the subscription will last until "])},
    "allPurchasesFinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".\n\nAll purchases final."])},
    "youDontHaveAMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have a membership"])},
    "purchaseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One year subscription to Score Cards"])}
  },
  "fiveHundred": {
    "sureToRestart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to restart the game?"])},
    "ttClickToSaveHand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to save the hand"])},
    "resumingSavedGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resuming Saved Game"])},
    "bidderMustBeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bidder must be selected"])},
    "bidMustBeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bid must be selected"])},
    "tricksTakenMustBeSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of tricks taken must be selected"])},
    "500ProgressiveScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500 Progressive Score Card"])},
    "playersPerTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["players per team"])},
    "custMustBidToWin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must bid to win"])},
    "custMustBidToWinNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked only the bidding team can win the game"])},
    "playNula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Nula"])},
    "playNulaNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked, The Nula bid will be added"])},
    "playDoubleNula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Double Nula"])},
    "playDoubleNulaNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked, The Double Nula bid will be added"])},
    "allowSixBid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Six Bid"])},
    "allowSixBidNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked, The Six bid will be added"])}
  },
  "ssDealOrder": {
    "setPlayingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Order"])},
    "ttSortPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to move players into a new playing order and set the first dealer or player"])},
    "dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Playing Order"])},
    "setAsFirstOrNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as Next"])},
    "ttAsNextPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" as the next player"])},
    "ttClickToSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to set "])},
    "gameIsStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game has started so dragging to change the order of the players is disabled."])},
    "gameIsNotStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click and drag to change the order of the players."])},
    "selectFirstPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the first player"])}
  },
  "ssFixedRowGrid": {
    "ttClickToDeleteHands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to delete played hands and restart current game"])}
  },
  "fiveCrowns": {
    "ttSaveHand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to save the hand "])},
    "ttPrintGamesPlayedHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to print games played history"])},
    "ttDownloadJSONHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to download a JSON file of the games played history"])}
  },
  "tsSaveJSONFileDialog": {
    "titleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download a JSON file?"])},
    "insertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click OK to download a JSON file of the score card's game results"])},
    "explanationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The JSON file can be used to import the score card's game results into a spreadsheet or other application.  The file will be saved to the default download location using the name of the score card and the date and time the file was created.  The file can be renamed and moved to a different location after it is downloaded."])}
  },
  "print": {
    "printAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print a single blank score sheet for the game"])},
    "printIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print a blank score sheet for each team or player"])},
    "printGamesPlayedHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print games played history"])},
    "customPrintedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Printed at https://scorecards.games for"])},
    "printProgressiveScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Progressive Score Card"])},
    "printBlankScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Blank Score Card"])},
    "ttPrintBlankScoreCardGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to print a single blank score card for the game"])},
    "ttPrintBlankScoreCardIndividual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to print a blank score card for individual teams or players"])}
  },
  "handAndFoot": {
    "ttSaveHand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to save the hand"])},
    "ttRestartGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to delete the hands played and restart the game"])},
    "dirtyBookNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirty book value is not valid"])},
    "cleanBookNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean book value is not valid"])},
    "perfectCutNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect cut value is not valid"])},
    "cardCountNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card count value is not valid"])},
    "negativeCardCountNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative card count value is not valid"])},
    "fixHighlightedErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix the highlighted input error(s) before saving"])},
    "custPointsPerfectCut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for a Perfect Cut"])},
    "custPlayWithPerfectCut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Perfect Cut to Score Card"])},
    "custPointsGoingOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for Going Out"])},
    "custPointsCleanBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for a Clean Book"])},
    "custPointsDirtyBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for a Dirty Book"])},
    "custRoundsPerGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rounds per Game"])},
    "custRoundsPerGameNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 4 and Maximum 5"])},
    "custRound1MinMeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round 1 Minimum Meld"])},
    "custRound2MinMeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round 2 Minimum Meld"])},
    "custRound3MinMeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round 3 Minimum Meld"])},
    "custRound4MinMeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round 4 Minimum Meld"])},
    "custRound5MinMeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round 5 Minimum Meld"])},
    "perfectCut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect Cut - "])},
    "goingOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Going Out - "])},
    "cleanBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean Book (Red): "])},
    "dirtyBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirty Book (Black): "])},
    "cardCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Count "])},
    "cardCountNegative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Count Negative"])},
    "playingRound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing Round "])},
    "minMeldIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" -- Minimum Meld is "])}
  },
  "genericScoreCard": {
    "custNoNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "customizeGame": {
    "titleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Game"])},
    "explanationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit values to customize the game"])}
  },
  "demoMode": {
    "defaultMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control is disabled in demo mode"])},
    "addPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to add players, but is disabled in demo mode"])},
    "deletePlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to delete a player, but is disabled in demo mode"])},
    "editPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to edit player information, but is disabled in demo mode"])},
    "editTeamNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to edit team names, but is disabled in demo mode"])},
    "openAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to open and edit your account information, but is disabled in demo mode"])},
    "deleteCardClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to delete a club, but is disabled in demo mode"])},
    "editCardClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to edit a club, but is disabled in demo mode"])},
    "addCardClub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to add a club, but is disabled in demo mode"])},
    "createNewScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to create a new score card, but is disabled in demo mode"])},
    "deleteScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to delete a score card, but is disabled in demo mode"])},
    "renameScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to rename a score card, but is disabled in demo mode"])},
    "customizeScoreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to save customizations for this game, but is disabled in demo mode"])},
    "saveHandRound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to save a hand or round, but is disabled in demo mode"])},
    "restartGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This control allows you to restart the game, but is disabled in demo mode"])}
  },
  "genericGame": {
    "rowsToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Blank Rows"])},
    "rowsToPrintNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When printing a blank score card when the game is played by points, the number of blank rows to print"])}
  },
  "canasta": {
    "gameName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canasta"])},
    "acesNatural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aces Natural: "])},
    "acesOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aces Open: "])},
    "acesMoreThanTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aces More Than Two: "])},
    "sevensNatural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sevens Natural: "])},
    "sevensOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sevens Open: "])},
    "sevensMoreThanTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sevens More Than Two: "])},
    "wildCanasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wild Canasta: "])},
    "openWild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Wild: "])},
    "wildCanasta4J": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wild Canasta 4 Jokers: "])},
    "deucesCanasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuces Canasta: "])},
    "Threes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threes +/- score: "])},
    "minimumMeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Meld: "])},
    "cardCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Count "])},
    "cleanBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean Canasta (Red): "])},
    "dirtyBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirty Canasta (Black): "])},
    "cardCountNegative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Count Negative"])},
    "custAcesNatural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for natural Aces"])},
    "custPlayAcesNatural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural Aces - show on Score Card"])},
    "custAcesOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for open Aces"])},
    "custPlayAcesOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Aces when round ends - show on Score Card"])},
    "custSevensNatural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for natural Sevens"])},
    "custPlaySevensNatural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural Sevens - show on Score Card"])},
    "custSevensOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for open Sevens"])},
    "custPlaySevensOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Sevens when round ends - show on Score Card"])},
    "custAcesMoreThanTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty for more than two Aces"])},
    "custPlayAcesMoreThanTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than two Aces in hand when round ends - show on Score Card"])},
    "custSevensMoreThanTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty for more than two Sevens"])},
    "custPlaySevensMoreThanTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than two Sevens in hand when round ends - show on Score Card"])},
    "custWildCanasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for Wild Canasta"])},
    "custPlayWildCanasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wild Canasta - show on Score Card"])},
    "custWildCanasta4J": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for Wild Canasta with 4 Jokers"])},
    "custPlayWildCanasta4J": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wild Canasta with 4 Jokers - show on Score Card"])},
    "custDeucesCanasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for Deuces Canasta"])},
    "custPlayDuecesCanasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuces Canasta - show on Score Card"])},
    "custOpenWild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for open Wild"])},
    "custPlayOpenWild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Wild when round ends - show on Score Card"])},
    "custPlayThrees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threes +/- score - show on Score Card"])},
    "custPointsCleanBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for a Clean Canasta"])},
    "custPointsDirtyBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points for a Dirty Canasta"])},
    "custLevel1Threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 1 Points threshold"])},
    "custLevel2Threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 2 Points threshold"])},
    "custLevel1MeldPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld points required for points between 0 and Level 1 threshold"])},
    "custLevel2MeldPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld points required for points between Level 1 and Level 2 thresholds"])},
    "custLevel3MeldPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld points required for points above the Level 2 threshold"])},
    "custGameEndsScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game ends when score reaches"])},
    "custRoundsToPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of blank rounds to print"])},
    "custRoundsToPrintNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When printing a blank score card, the number of blank rounds to print"])}
  },
  "standardSearch": {
    "clickToClearSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to clear search"])}
  }
}