<template>
  <!-- #region this section has the dialogs for this component. Add, Edit and Delete clubs dialogs -->
  <!-- Delete Club Dialog -->
  <TheStandardDialog
    :showDialog="showDeleteDialog"
    :titleText="t('ccInsert.sureDelete')"
    :insertText="clubData.name"
    :explanationText="t('ccInsert.delWarning')"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('common.yes')"
    @leftBtn="showDeleteDialog = false"
    @rightBtn="deleteCardClub(clubData.id)"
  >
  </TheStandardDialog>

  <!-- Edit Club dialog -->
  <TheStandardDialog
    :showDialog="showEditDialog"
    :titleText="$t('ccInsert.editClub')"
    insertText="not-used"
    explanationText="not-used"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('common.update')"
    @leftBtn="cancelEdit"
    @rightBtn="updateCardClub(clubData)"
  >
    <template v-slot:dSlot>
      <v-text-field
        v-model="clubData.name"
        :autofocus="true"
        :label="t('ccInsert.updateName')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
      ></v-text-field>

      <v-text-field
        v-model="clubData.description"
        :label="t('ccInsert.updateDescription')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
      >
      </v-text-field>
    </template>
  </TheStandardDialog>

  <!-- Add Club Dialog -->
  <TheStandardDialog
    :showDialog="showAddDialog"
    :titleText="$t('ccInsert.addClub')"
    insertText="not-used"
    explanationText="not-used"
    :leftBtnText="t('common.cancel')"
    :rightBtnText="t('common.add')"
    @leftBtn="showAddDialog = false"
    @rightBtn="AddCardClub"
  >
    <template v-slot:dSlot>
      <v-text-field
        v-model="newName"
        :autofocus="true"
        :label="t('ccInsert.newName')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
        @keyup.enter="AddCardClub"
      ></v-text-field>

      <v-text-field
        v-model="newDescription"
        :label="t('ccInsert.newDescription')"
        hide-details="true"
        class="ma-0 pa-0 my-4 w-100"
      >
      </v-text-field>
    </template>
  </TheStandardDialog>
  <!-- #endregion -->

  <!-- #region Display the list of Card Clubs including controls to add, edit, delete and select a card club-->
  <TheStandardHeaderRow :headerLevel="2" :rightColumnNumber="3">
    <template v-slot:title> {{ title }} </template>
    <template v-slot:icons
      ><v-tooltip location="start" open-delay="1000">
        <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            @click="ShowAddCardClubDialog"
            class="iconButton iconButton-light pr-6"
            :size="smAndDown ? '20px' : '30px'"
          >
            mdi-plus-circle-outline
          </v-icon>
          <TheStandardSearchBox
            :sortArray="clubsList"
            :translateNames="false"
            :clearFilter="clearFilterTrigger"
            :placeHolderText="t('ccInsert.searchClubs')"
            @updatedArray="updatedArray"
          ></TheStandardSearchBox>
        </template>
        <p class="ttText">{{ t("ccInsert.ttAddNewClub") }}</p>
      </v-tooltip></template
    >
  </TheStandardHeaderRow>

  <!-- All the clubs assocaited with a player are listed here.  Each line in the list has a radio button to select the club, an edit Icon to allow ediiting the clubs name or description and a delete icon to allow deleting the club -->
  <v-card class="card-body card-standard">
    <!-- List all the clubs -->
    <div class="scrollZone">
      <v-list
        v-for="(item, index) in clubsListFiltered"
        :key="item.id"
        :index="index"
        lines="one"
        hide-details="true"
        density="compact"
        class="pb-0 pt-0 card-body"
      >
        <v-list-item
          :class="item.selected ? 'listItemRow-selected' : 'listItemRow'"
          density="compact"
          ><v-container class="pa-0" fluid>
            <v-row :dense="true" :no-gutters="true" class="d-flex">
              <v-col sm="2" md="1" class="text-left my-auto">
                <!-- Radio button to select club.  The selected club is used when creating a new score card -->
                <v-radio-group v-model="radioGroup" hide-details="true">
                  <v-tooltip location="top right" open-delay="1000">
                    <template v-slot:activator="{ props }">
                      <v-radio
                        v-bind="props"
                        :value="item.id"
                        @click="radioSelected(item.id)"
                      >
                      </v-radio>
                    </template>
                    <p class="ttText">
                      {{ t("ccInsert.ttClickToSelect") + " " + item.name }}
                    </p>
                  </v-tooltip>
                </v-radio-group>
              </v-col>

              <!-- Name of the club listed here -->
              <v-col sm="7" md="8" lg="9" class="text-left my-auto">
                <v-tooltip location="top left" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-list-item-title
                      hide-details="true"
                      v-bind="props"
                      class="text-body-2 text-body-2 text-md-h6 text-lg-h5 text-xl-h4 font-weight-bold"
                      :class="
                        item.selected
                          ? 'listItemText-selected-NoCaps'
                          : 'listItemText-NoCaps'
                      "
                    >
                      {{ item.name }}</v-list-item-title
                    >
                  </template>
                  <!-- The description of the club is listed as a tooltip on the club.  If there is no description the no description message is shown -->
                  <p class="ttText">
                    {{
                      item.description == null || item.description == ""
                        ? item.name + " " + t("ccInsert.ttNoDescription")
                        : item.description
                    }}
                  </p>
                </v-tooltip>
              </v-col>

              <!-- The edit and delete club icons are shown in this column -->
              <v-col sm="3" md="3" lg="2" class="text-right my-auto">
                <v-tooltip location="left" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      @click="ShowEditCardClubDialog(item, index)"
                      class="iconButton iconButton-green"
                      :size="smAndDown ? '20px' : '25px'"
                      >mdi-pencil-outline</v-icon
                    >
                  </template>

                  <p class="ttText">
                    {{ t("ccInsert.ttClickToEdit") + " " + item.name }}
                  </p>
                </v-tooltip>

                <v-tooltip location="top left" open-delay="1000">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      @click="ShowDeleteCardClubDialog(item)"
                      class="iconButton iconButton-red"
                      :size="smAndDown ? '20px' : '30px'"
                      >mdi-delete-circle-outline</v-icon
                    >
                  </template>
                  <p class="ttText">
                    {{ t("ccInsert.ttClickToDelete") + " " + item.name }}
                  </p>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
  <!-- #endregion -->
</template>

<script setup>
  /* import components to insert */
  import TheStandardDialog from "../reusable/TheStandardDialog.vue";
  import TheStandardHeaderRow from "../reusable/TheStandardHeaderRow.vue";
  import TheStandardSearchBox from "../reusable/TheStandardSearchBox.vue";
  import { inDemoMode } from "../composables/demoModeHelpers";

  /* import needed vue utilities */
  import { ref, watch, onMounted } from "vue";
  import { useStore } from "vuex";
  import { getStorage } from "../composables/localStorage";
  import { useDisplay } from "vuetify";
  import { useI18n } from "vue-i18n";

  //create function to insert i18N text
  let { t } = useI18n({});

  //Setup vuex store
  const store = useStore();

  //Setup display size.  Used to determine if we are on a small screen or not
  let { smAndDown } = useDisplay();

  //Setup Title bar text varies if there are no clubs or if there are clubs
  let title = ref("");
  setupTitleBarText();

  //variable to open and close the add, edit and delete dialogs
  let showDeleteDialog = ref(false);
  let showEditDialog = ref(false);
  let showAddDialog = ref(false);

  //Create and load the players list
  let clubsList = ref(store.getters["cc/cardClubs"]);
  let clubsListFiltered = ref(store.getters["cc/cardClubs"]);
  let clearFilterTrigger = ref(0);

  //variable to hold the card club being edited
  let clubData = ref({ name: "", description: "", id: "" });

  /* variables to hold the name and description of a new club as it is being created */
  let newName = ref(null);
  let newDescription = ref(null);

  /* varable to hold the selected radio button */
  let radioGroup = ref(null);

  /* Watch for card club data to be loaded, then set radio button
      to the card club last selected in the database */
  watch(
    () => store.getters["cc/cardClubsDataLoaded"],
    () => {
      //set the radio button to the last selected club
      radioGroup.value = store.getters["cc/cardClubID"];
      clubsList.value = store.getters["cc/cardClubs"];
      clubsListFiltered.value = store.getters["cc/cardClubs"];
    }
  );

  /* Watch for the selected card club to change.  When the club changes pass the radio button service function the changed ID.  This watch is requried because adding or deleting a club changes the selected club, without clicking a radion button */
  watch(
    () => store.getters["cc/cardClubID"],
    (newID) => {
      radioSelected(newID);
    }
  );

  /* Watch for changes in the number of card clubs.  If the number changes call routine to update the clubs title bar.  Done to handle the special case message when there are zero clubs */
  watch(
    () => store.getters["cc/cardClubs"].length,
    () => {
      setupTitleBarText();
      clubsList.value = store.getters["cc/cardClubs"];
      clubsListFiltered.value = store.getters["cc/cardClubs"];
    }
  );

  /* Watch for data to be loaded or the clubPlayers array to be changed */
  watch(
    () => [store.getters["cc/cardClubs"]],
    () => {
      //Create and load the players list
      clubsList.value = store.getters["cc/cardClubs"];
      clubsListFiltered.value = store.getters["cc/cardClubs"];
    }
  );

  /** Sets the text of the title bar based on the number of card clubs in the store.
   * If there are no card clubs, the title will display "Add a Club".
   * If there are card clubs, the title will display "Select a Club".
   */
  function setupTitleBarText() {
    // Check if there are any card clubs in the store
    if (store.getters["cc/cardClubs"].length === 0) {
      // If there are no card clubs, set the title to "Add a Club"
      title.value = t("ccInsert.addAClub");
    } else {
      // If there are card clubs, set the title to "Select a Club"
      title.value = t("ccInsert.selectAClub");
    }
  }

  /** Displays a delete confirmation dialog for a card club item. */
  function ShowDeleteCardClubDialog(item) {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.deleteCardClub"), true)) {
      return;
    }

    // Set the value of the clubData variable to the name item being deleted so the name can be displayed in the delete dialog.
    clubData.value = item;
    // Set the value of the showDeleteDialog variable to true to display the delete confirmation dialog.
    showDeleteDialog.value = true;
  }

  /* This function runs when the user confirms they want to delete a club */
  async function deleteCardClub(id) {
    /* Call function to delete the club indentfied by the passed in ID.  This routine also takes care of deleting all players that had been created for the club being deleted */
    store.dispatch("cc/deleteCardClub", id);
    /* Set the value of the showDeleteDialog variable to false to hide the delete confirmation dialog. */
    showDeleteDialog.value = false;
  }

  /* This function shows an edit dialog for the selected card club. The function takes in the card club object as a parameter */
  function ShowEditCardClubDialog(item) {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.editCardClub"), true)) {
      return;
    }

    /*The selected item's name, description, and id are copied to working variables in clubData and then used in the edit dialog as the inital values */
    clubData.value.name = item.name;
    clubData.value.description = item.description;
    clubData.value.id = item.id;

    // The edit dialog is set to be shown
    showEditDialog.value = true;
  }

  /* This function cancels the edit club dialog */
  function cancelEdit() {
    /* The edit dialog is set to be hidden.  No other action is taken since the original data is still in the list item and was not modified*/
    showEditDialog.value = false;
  }

  /* This function writes updated club data to the database and closes the dialog */
  function updateCardClub(updatedItem) {
    store.dispatch("cc/updateCardClub", updatedItem);
    // Reset the clearFilterTrigger to trigger the search box to clear the search string
    clearFilterTrigger.value = Math.random();
    showEditDialog.value = false;
  }

  /* This function shows the add club dialog */
  function ShowAddCardClubDialog() {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.addCardClub"), true)) {
      return;
    }
    showAddDialog.value = true;
  }

  /* This function adds a new card club using the user entered data from the add club dialog */
  function AddCardClub() {
    //Check to make sure card club name is at least 3 characters
    if (newName.value.length < 3) {
      // If the card club name is less than 3 characters, show a snackbar with an error message and return
      store.commit("showSnackBar", t("ccInsert.ttClubNameTooShort"));
      return;
    }

    // Check if the card club name already exists
    if (store.getters["cc/isCardClubNameDuplicate"](newName.value) === true) {
      // If the card club name already exists, show a snackbar with an error message and return
      store.commit("showSnackBar", t("ccInsert.ttClubNameDuplicate"));
      return;
    }

    // Create a new card club object with the user entered data
    let newCardClub = {
      name: newName.value,
      description: newDescription.value,
      selected: false,
      createdDate: new Date(),
      lastGameSelected: null,
      managers: [{ id: store.getters["auth/loggedInSiteMemberID"] }],
      teams: [
        { name: "Team 1", inputError: false },
        { name: "Team 2", inputError: false },
        { name: "Team 3", inputError: false },
        { name: "Team 4", inputError: false },
      ],
    };

    // Get saved team names from local storage and update the new card club object with them
    let savedTeamNames = getStorage("teamNames");
    if (savedTeamNames != undefined) {
      savedTeamNames = JSON.parse(savedTeamNames);
      newCardClub.teams[0].name = savedTeamNames[0].name;
      newCardClub.teams[1].name = savedTeamNames[1].name;
      newCardClub.teams[2].name = savedTeamNames[2].name;
      newCardClub.teams[3].name = savedTeamNames[3].name;
    }

    // Dispatch an action to add the new card club to the store
    store.dispatch("cc/addCardClub", newCardClub);

    // Reset the input fields and hide the add club dialog
    newName.value = "";
    newDescription.value = "";
    showAddDialog.value = false;
  }

  // Change the Card Club Selected to the one selected by the radio button
  function radioSelected(id) {
    // Check if the passed id is the same as the current radio group value
    if (id === radioGroup.value) {
      // Return early since there is no change
      return;
    }

    // Update the radio group value to the passed id
    radioGroup.value = id;

    // Dispatch an action to update the selected card club in the store
    store.dispatch("cc/updateSelected", id);

    /*  Use id to filter and find the previously selected card game in the newly selected card club */
    let ccSelected = store.state.cc.cardClubs.filter((cc) => cc.id === id);

    // Check if the game data is loaded
    if (store.getters["cg/gamesDataLoaded"] === true) {
      // If there is a selected card club
      if (ccSelected.length !== 0) {
        // Update the game data ID in the store based on the last game selected in the newly selected card club
        store.commit("cg/gameDataID", ccSelected[0].lastGameSelected);
      } else {
        // If there is no selected card club, set the game data ID to "none"
        store.commit("cg/gameDataID", "none");
      }
    }
  }

  /* Called by the filter array component when the list of games has been changed.  Copy the new filtered list over the list being displayed to update the display */
  const updatedArray = (filteredArray) => {
    clubsListFiltered.value = filteredArray;
  };

  onMounted(() => {
    store.dispatch("cc/loadCardClubs");
    //Refresh the radio button to show selected card club
    radioGroup.value = store.getters["cc/cardClubID"];

    //Refresh Title bar text
    setupTitleBarText();
  });
</script>

<style scoped></style>
