import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../components/views/HomeView.vue";
import HelpView from "../components/views/HelpView.vue";
import ScoreCardView from "../components/views/ScoreCardView.vue";
import AdminView from "../components/views/AdminView.vue";
import PageNotFound from "../components/views/PageNotFound.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/scorecard",
    name: "scoreCard",
    component: ScoreCardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  /* If this route requires auth, check if logged in and
     if not, redirect to home page. */
  let userStatus = store.getters["auth/userLoggedIn"];
  if (to.meta.requiresAuth && !userStatus) {
    return { name: "home" };
  }
});

export default router;
