<template>
  <TheStandardDialog
    :showDialog="store.state.auth.showAccountDialog"
    :titleText="t('accountInsert.updateAccountTitle')"
    insertText="not-used"
    explanationText="not-used"
    leftBtnText="not-used"
    :rightBtnText="t('common.close')"
    :leftBtnDisabled="false"
    :rightBtnDisabled="false"
    @leftBtn="closeDialog"
    @rightBtn="closeDialog"
  >
    <!-- Input fields for register dialog -->
    <template v-slot:dSlot>
      <v-container fluid class="flex ma-0 pa-0 w-100">
        <!-- Change password row -->
        <v-form v-model="passwordValid">
          <v-row
            :no-gutters="true"
            class="d-flex ma-0 justify-start align-start w-100"
          >
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-start ma-0"
            >
              <v-btn
                class="stdBtn elevation-1 pa-0 mt-3"
                :class="[
                  passwordBtnDisabled ? 'stdBtn-disabled' : 'stdBtn-enabled',
                ]"
                :width="smAndDown ? '100%' : '60%'"
                @click="changePassword"
                :disabled="passwordBtnDisabled"
              >
                {{ t("accountInsert.changePassword") }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-start ma-0"
            >
              <v-text-field
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword"
                :rules="passwordRules"
                :label="t('accountInsert.newPassword')"
                required
                hide-details="auto"
                density="compact"
                class="textFieldStandard pa-2"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Retype password row -->
          <v-row
            :no-gutters="true"
            class="d-flex ma-0 mb-2 justify-start w-100"
          >
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-start ma-0"
            >
              <v-label class="alertText ma-0 pa-2 body-1 text-sm-h6">{{
                passwordMatchMsg
              }}</v-label></v-col
            >
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-center ma-0"
            >
              <v-text-field
                v-model="reTypedPassword"
                :type="showPasswordReType ? 'text' : 'password'"
                :append-inner-icon="
                  showPasswordReType ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append-inner="showPasswordReType = !showPasswordReType"
                :rules="passwordRules"
                :label="t('accountInsert.retypePassword')"
                required
                hide-details="auto"
                density="compact"
                class="textFieldStandard pa-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <!-- Display name update row -->
        <v-form v-model="displayNameValid">
          <v-row
            :no-gutters="true"
            class="d-flex ma-0 mb-2 justify-start w-100"
          >
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-center ma-0"
            >
              <v-btn
                class="stdBtn elevation-1 pa-2"
                :class="
                  displayNameBtnDisabled ? 'stdBtn-disabled' : 'stdBtn-enabled'
                "
                :width="smAndDown ? '100%' : '60%'"
                @click="changeDisplayName"
                :disabled="displayNameBtnDisabled"
              >
                {{ t("accountInsert.changeDisplayName") }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-center"
            >
              <v-text-field
                v-model="displayName"
                :rules="[nameRules.required, nameRules.counter]"
                :label="t('accountInsert.newDisplayName')"
                hide-details="auto"
                persistent-hint
                density="compact"
                class="textFieldStandard pa-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <!-- Change Email -->
        <v-form v-model="emailValid">
          <v-row
            :no-gutters="true"
            class="d-flex ma-0 mb-2 justify-start w-100"
          >
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-center ma-0"
            >
              <v-btn
                class="stdBtn elevation-1 pa-2"
                :class="emailBtnDisabled ? 'stdBtn-disabled' : 'stdBtn-enabled'"
                :width="smAndDown ? '100%' : '60%'"
                @click="changeEmail"
                :disabled="emailBtnDisabled"
              >
                {{ t("accountInsert.changeEmail") }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-center"
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :label="t('accountInsert.newEmail')"
                required
                hide-details="auto"
                density="compact"
                class="textFieldStandard pa-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <!-- Delete account row -->
        <v-form v-model="imSureValid">
          <v-row
            :no-gutters="true"
            class="d-flex ma-0 mb-2 justify-start w-100"
          >
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-start align-self-center"
            >
              <v-row :no-gutters="true" class="d-flex ma-0 justify-start">
                <v-col cols="12" class="d-flex justify-start align-self-center">
                  <v-btn
                    class="stdBtn elevation-1 pa-2"
                    :class="
                      imSureBtnDisabled ? 'stdBtn-disabled' : 'stdBtn-enabled'
                    "
                    :width="smAndDown ? '100%' : '60%'"
                    @click="deleteAccount"
                    :disabled="imSureBtnDisabled"
                  >
                    {{ t("accountInsert.deleteAccount") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="d-flex justify-start align-self-center">
                  <p class="font-weight-bold text-body-2 mt-1 alertText">
                    {{ t("accountInsert.cautionCannotBeUndone") }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="text-center py-auto rowTextDarkBold">
              <v-text-field
                v-model="imSure"
                :rules="imSureRules"
                :label="t('accountInsert.deleteConfirmationPhraseLabel')"
                required
                hide-details="auto"
                density="compact"
                class="textFieldStandard pa-2 W-100"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <!-- Become a member - payPal inserted here-->
        <v-row :no-gutters="true" class="d-flex ma-2 justify-start w-100">
          <v-col cols="12" class="text-center py-auto rowTextDarkBold">
            <PayPal :email="email"></PayPal>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </TheStandardDialog>
</template>
<script setup>
  import TheStandardDialog from "../reusable/TheStandardDialog.vue";
  import PayPal from "../viewInserts/PayPal.vue";
  import { useStore } from "vuex";
  import { ref, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useDisplay } from "vuetify";

  //Setup display size variables
  let { smAndDown } = useDisplay();

  //create function to insert i18N text
  let { t } = useI18n({});

  //Get the store
  const store = useStore();

  // #region handle changing the display name
  //Setup display name variables
  let displayName = ref(store.getters["auth/userDisplayName"]);
  let displayNameRestore = displayName.value;
  let displayNameBtnDisabled = ref(true);
  let displayNameValid = ref(false);

  //Setup rules for display name
  let nameRules = {
    required: (v) => !!v || t("accountInsert.nameIsRequired"),
    counter: (v) =>
      (v && v.length <= 30 && v.length >= 3) ||
      t("accountInsert.nameMustBeAtLeast3Chars"),
  };

  //Handle changing the display name
  function changeDisplayName() {
    //The payload is the new display name and the text to display on success or failure
    let payload = {
      newDisplayName: displayName.value,
      successText: t("accountInsert.displayNameUpdated"),
      failText: t("accountInsert.displayNameNotUpdated"),
    };

    //Call the store action to update the display name in db
    store.dispatch("auth/updateProfile", payload);
    //Update the display name in the store and disable the change name button
    store.commit("auth/updateUserDisplayName", payload.newDisplayName);
    displayNameBtnDisabled.value = true;
  }

  //Watch the display name and the validity of the display name that the user has entered
  watch(
    () => [displayName.value, displayNameValid.value],
    () => {
      if (
        /* // if display name input is empty, to long,
              or the same as the original, disable change button */
        displayName.value === store.getters["auth/userDisplayName"] ||
        displayNameValid.value === false
      ) {
        displayNameBtnDisabled.value = true;
      } else {
        displayNameBtnDisabled.value = false;
      }
    }
  );

  // #endregion */

  // #region handle changing the email */
  //Setup email variables
  let email = ref(null);
  email.value = store.getters["auth/userEmailAddress"];
  let emailRestore = email.value;
  let emailBtnDisabled = ref(true);
  let emailValid = ref(false);

  //Setup rules for email
  let emailRules = [
    (v) => !!v || t("accountInsert.emailIsRequired"),
    (v) =>
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || t("accountInsert.emailIsInvalid"),
  ];

  //Handle changing the email
  async function changeEmail() {
    //Add the new email to the payload and the text to display on success or failure
    let payload = {
      newEmail: email.value,
      successText: t("accountInsert.emailUpdated"),
      failText: t("accountInsert.emailNotUpdated"),
    };

    /* directly setting the user email to null then updating it with the new email address was the only way I could find to make the getter use the new value instead of the cached value.  For some reason changing from one email address to another did not cause the getter to refresh */
    store.state.auth.user.email = null;
    //Call the store action to update the email in db
    await store.dispatch("auth/updateEmail", payload);
  }

  //Watch the email and the validity of the email that the user has entered
  watch(
    () => [email.value, emailValid.value],
    () => {
      if (
        // if email input is empty or the same as the original, disable change button
        email.value === "" ||
        email.value === store.getters["auth/userEmailAddress"] ||
        emailValid.value === false
      ) {
        emailBtnDisabled.value = true;
      } else {
        emailBtnDisabled.value = false;
      }
    }
  );
  // #endregion

  // #region handle changing the password */
  //Setup password variables
  let password = ref(null);
  let reTypedPassword = ref(null);
  let passwordBtnDisabled = ref(true);
  let passwordValid = ref(false);
  let passwordMatchMsg = ref(t("accountInsert.passwordsDoNotMatch"));
  let showPassword = ref(false);
  let showPasswordReType = ref(false);

  //Rules the same for registering and loging in
  let passwordRules = [
    (v) => !!v || t("accountInsert.passwordIsRequired"),
    (v) =>
      /(?=.*\d)(?=.*[a-zA-Z]).{6,}/.test(v) ||
      t("accountInsert.passwordIsInvalid"),
  ];

  //Handle changing the password
  function changePassword() {
    //Add the new password to the payload and the text to display on success or failure
    let payload = {
      newPassword: password.value,
      successText: t("accountInsert.passwordUpdated"),
      failText: t("accountInsert.passwordNotUpdated"),
    };

    //
    store.dispatch("auth/updatePassword", payload);

    //clear passwords for security
    password.value = null;
    reTypedPassword.value = null;
  }

  //Watch the password and the validity of the password that the user has entered
  watch(
    () => [password.value, reTypedPassword.value, passwordValid.value],
    () => {
      //Cover case where both passwords are null when first opening the dialog
      if (password.value === null && reTypedPassword.value === null) {
        passwordBtnDisabled.value = true;
        passwordMatchMsg.value = "";
        return;
      }
      if (
        /* if passwords don't match or is not valid - valid is
              min 6 characters, mix of letters and numbers */
        password.value != reTypedPassword.value ||
        passwordValid.value === false
      ) {
        passwordBtnDisabled.value = true;
      } else {
        passwordBtnDisabled.value = false;
      }

      //Setup must match message
      if (password.value != reTypedPassword.value) {
        passwordMatchMsg.value = t("accountInsert.passwordsDoNotMatch");
      } else {
        passwordMatchMsg.value = "";
      }
    }
  );
  // #endregion

  // #region Handle deleting the account
  //Setup delete account variables
  let imSure = ref("");
  let imSureBtnDisabled = ref(true);
  let imSureValid = ref(false);

  let verificationPhrase = t("accountInsert.deleteConfirmationPhrase");

  //Setup rules for delete account
  let imSureRules = [
    (v) => !!v || verificationPhrase,
    (v) =>
      new RegExp(`^${verificationPhrase}$`).test(v) ||
      t("accountInsert.verificationPhraseIsIncorrect"),
  ];

  function deleteAccount() {
    //For the current user, delete all cardclubs, club players, score cards and the user
    store.dispatch("auth/deleteUser");

    closeDialog();
  }

  //Watch the delete account phrase and the validity of the phrase that the user has entered
  watch(
    () => [imSure.value, imSureValid.value],
    () => {
      if (
        /* // if display name input is empty, to long,
              or the same as the original, disable change button */
        imSure.value === "" ||
        imSureValid.value === false
      ) {
        imSureBtnDisabled.value = true;
      } else {
        imSureBtnDisabled.value = false;
      }
    }
  );

  //#endregion

  // Handle closing the dialog
  function closeDialog() {
    // if user closes dialog without making changes, restore original values
    displayName.value = displayNameRestore;
    email.value = emailRestore;

    store.commit("auth/hideAccountDialog");
  }
</script>

<style scoped lang="scss"></style>
