import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  updateDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";

import { db } from "../firebaseInit";

const scoreCardsCollection = collection(db, "scoreCards");

const scoreCardHeader = {
  namespaced: true,

  state() {
    return {
      /* Some of the score card structure is defined here, but it may not be an exact match for what is created when a score card is created for a game */

      scoreCard: {
        //linked club id
        ccID: null,
        //linked club name
        ccName: null,
        //date score card created
        dateCreated: null,
        //id of game this score card is for
        gdID: null,
        //this link is used to determine which vue file to load
        vueFileToLoad: null,
        //Name of game being scored
        gdName: null,
        //players on score card
        players: [{ id: null, name: null, lastDealer: 0 }],
        //Record of last dealer
        lastDealer: 0,
        //Name of score card shown screen.
        scoreCardName: null,
        //id of member owner of score card
        siteMemberName: null,
        //Name of member owner of score card
        siteMemberID: null,
        //array of on score card. Not applicable to every scorecard
        teams: [{ id: null, name: null }],

        /* Games and hands are used by every score card, but will have data
        specific to the game being scored */
        games: [],
        hands: [],
      },
      //List of score cards for current user
      scoreCardList: [],
      scoreCardNames: [],
      gameData: null,
      scoreCardLoaded: false,
      scoreCardID: null,
      showDealOrderDialog: false,
      showCustomizeGameDialog: false,

      /* This data will be set to "new" if a new game had just been created
      and to loaded if a saved score card is being loaded. */
      scoreCardOrigination: null,
    };
  },

  //Getters for scoreCards
  getters: {
    //Return the scoreCardList
    scoreCardList(state) {
      return state.scoreCardList;
    },

    //Return the list of scoreCardNames, used in UI select scorecard control
    scoreCardNames(state) {
      if (state.scoreCardNames.length === 0) {
        state.scoreCardNames.push("---");
      }
      return state.scoreCardNames;
    },

    //Returns the ID of the last dealer
    lastDealer(state) {
      return state.scoreCard.lastDealer;
    },

    //Returns the state of the showDealOrderDialog variable
    showDealOrderDialog(state) {
      return state.showDealOrderDialog;
    },

    //Returns the state of the showCustomizeGameDialog variable
    showCustomizeGameDialog(state) {
      return state.showCustomizeGameDialog;
    },

    //Returns the state of the scoreCardLoaded variable
    scoreCardLoaded(state) {
      return state.scoreCardLoaded;
    },

    //Returns the full score card object
    scoreCard(state) {
      return state.scoreCard;
    },

    //Returns the score card ID
    scoreCardID(state) {
      return state.scoreCardID;
    },

    //Returns the game data for the game being scored
    gameData(state) {
      return state.gameData;
    },

    //Returns the score card origination state.  Used to determine if a new score card is being created or a saved score card is being loaded
    scoreCardOrigination(state) {
      return state.scoreCardOrigination;
    },

    //Returns the name of the game being scored by the score card
    gameName(state) {
      return state.scoreCard.gdName;
    },

    //Returns the name vue file to open when loading score cardr
    vueFileToLoad(state) {
      if (state.scoreCard.vueFileToLoad === undefined) {
        return false;
      } else {
        return state.scoreCard.vueFileToLoad;
      }
    },

    //Returns the ID of the score card from the score card name
    getScoreCardIDFromName: (state) => (name) => {
      let ogID = state.scoreCardList.filter((og) => og.name === name);
      //if score card not found return false
      if (ogID.length === 0) {
        return false;
      }
      return ogID[0].id;
    },

    //Returns the name of the score card from the score card id
    getScoreCardNameFromID: (state) => (id) => {
      /* Check for number of open score cards. If no open score cards return false otherwise set default to first open score card in list */
      let defaultScorecard = "";
      if (state.scoreCardList.length > 0) {
        /* If at least one score card in array, set default scorecard return value to first scorecard in array in case last used scorecard is not found (eg. deleted) */
        defaultScorecard = state.scoreCardList[0].name;
      }

      //if id is null return defaultScorecard
      if (id === null) {
        return defaultScorecard;
      }
      //if no score cards return defaultScorecard
      if (state.scoreCardList === false) {
        return defaultScorecard;
      }
      //find open game by id
      let ogName = state.scoreCardList.filter((og) => og.id === id);
      //if open game not found return defaultscorecard
      if (ogName.length === 0) {
        return defaultScorecard;
      }
      return ogName[0].name;
    },
  },

  mutations: {
    //Update the last dealer
    updateLastDealer(state, dealer) {
      state.scoreCard.lastDealer = dealer;
    },

    //Update the showDealOrderDialog variable to show or hide the dialog
    showDealOrderDialog(state, payLoad) {
      state.showDealOrderDialog = payLoad;
    },

    //Update the showCustomizeGameDialog variable to show or hide the dialog
    showCustomizeGameDialog(state, payLoad) {
      state.showCustomizeGameDialog = payLoad;
    },

    //Add a hand to the score card display object
    addHand(state, payLoad) {
      state.scoreCard.hands.push(payLoad);
    },

    //Add a game to the score card display object
    addGame(state, payLoad) {
      state.scoreCard.games.push(payLoad);
    },

    //Update the player order to the score card display object
    updatePlayerOrder(state, players) {
      state.scoreCard.players = players;
    },

    //Update the variable to indicate if the score card is new or being reloaded
    scoreCardOrigination(state, origin) {
      state.scoreCardOrigination = origin;
    },

    //Update the score card display object with the list of score cards
    getListOfScoreCards(state, payLoad) {
      state.scoreCardList = payLoad;

      //Create a list of score card names for use in UI select control.  Sort the list alphabetically
      let ogName = [];
      if (state.scoreCardList === false) {
        state.scoreCardNames = ogName;
        return;
      }

      state.scoreCardList.forEach((og) => {
        let ogn = og.name;
        ogName.push(ogn);
      });

      //Sort by name before returning
      ogName.sort(function (a, b) {
        return a.localeCompare(b);
      });

      state.scoreCardNames = ogName;
    },

    createScoreCard(state, newScoreCard) {
      state.scoreCard = newScoreCard;
      state.scoreCardID = state.scoreCard.id;
      //change number of scorecards to trigger update to list on home page
    },

    //Load the game data for the game being scored
    loadGameData(state, gameData) {
      state.gameData = gameData;
      //Change state to indicate that score card data has been loaded
      state.scoreCardLoaded = true;
    },

    //Indicate that score card data is not loaded
    scoreCardLoading(state) {
      state.scoreCardLoaded = false;
    },

    //Update the score card name in the score card display object
    changeScoreCardName(state, payLoad) {
      state.scoreCard.scoreCardName = payLoad.name;
    },

    //Delete the hands for a game from the score card display object
    deleteHands(state, filteredHands) {
      /* Save hands to memory with the deleted hands already removed  */
      state.scoreCard.hands = filteredHands;
    },
  },

  /*  Database reads/writes are in actions.  */
  actions: {
    async createScoreCard({ commit, dispatch, state, rootGetters }) {
      //Indicate score card data is not loaded to keep score-sheets from
      //loading before data is available
      commit("scoreCardLoading");

      //Start with a blank score card header
      let newScoreCard = state.scoreCard;

      //Add data to the score card header. Some data may not be in addition to data in the state.scoreCard object
      let myDate = new Date();
      newScoreCard.siteMemberID = rootGetters["auth/loggedInSiteMemberID"];
      newScoreCard.siteMemberName = rootGetters["auth/userDisplayName"];
      newScoreCard.dateCreated = myDate.toDateString();
      newScoreCard.scoreCardName =
        rootGetters["cc/cardClubName"] +
        " / " +
        rootGetters["cg/gdsGameTranslatedName"] +
        " / " +
        myDate.toDateString() +
        " / " +
        myDate.toLocaleTimeString();
      newScoreCard.ccID = rootGetters["cc/cardClubID"];
      newScoreCard.ccName = rootGetters["cc/cardClubName"];
      newScoreCard.gdID = rootGetters["cg/gdsGameDataID"];
      newScoreCard.gdName = rootGetters["cg/gdsName"];
      newScoreCard.vueFileToLoad = rootGetters["cg/gdsVueFile"];
      newScoreCard.players = rootGetters["cp/cpsAllNamesAndID"];
      newScoreCard.lastDealer = 0;
      newScoreCard.teams = rootGetters["cp/cpsTeamNames"];
      newScoreCard.gamePlayedBy = rootGetters["cg/gdsGamePlayedBy"];
      newScoreCard.customizable = rootGetters["cg/gdsCustomizable"];
      newScoreCard.games = [];
      newScoreCard.hands = [];
      newScoreCard.gameEnds = rootGetters["cg/gameEnds"];

      //add the new score card to the database and to the score card display object
      const docRef = await addDoc(scoreCardsCollection, newScoreCard);
      newScoreCard.id = docRef.id;
      commit("createScoreCard", newScoreCard);

      //Write team names to db
      await dispatch("cc/writeTeamNames", newScoreCard.teams, { root: true });
      //Read Score Card from database
      await dispatch("loadScoreCard", newScoreCard.id);
    },

    async loadScoreCard({ commit, dispatch }, id) {
      //Indicate score card data is not loaded to keep score-sheets from
      //loading before data is available
      commit("scoreCardLoading");

      //Get score card from database
      const docRef = doc(db, "scoreCards", id);
      const sch = await getDoc(docRef);
      const newScoreCard = sch.data();
      newScoreCard.id = docRef.id;
      commit("createScoreCard", newScoreCard);
      //Get game data for game being scored
      dispatch("loadGameData");
    },

    /* loads data for card game being playerd.  Must be called after score card header has been created so game ID is available*/
    async loadGameData({ commit, state }) {
      const docRef = doc(db, "gamesData", state.scoreCard.gdID);
      const gd = await getDoc(docRef);
      commit("loadGameData", gd.data());
    },

    //Get list of score cards for current user
    async getListOfScoreCards({ commit, rootGetters }) {
      let idQ = rootGetters["auth/loggedInSiteMemberID"];
      const openGamesList = query(
        scoreCardsCollection,
        where("siteMemberID", "==", idQ)
      );

      const snapshot = await getDocs(openGamesList);

      let payLoad = false;
      if (!snapshot || snapshot.empty) {
        //If no open games found commit an empty payload then return
        commit("getListOfScoreCards", payLoad);
        return;
      }

      let ogl = [];
      snapshot.docs.forEach((doc) => {
        //add club club to card club array
        ogl.push({ name: doc.data().scoreCardName, id: doc.id });
      });
      payLoad = ogl;

      //write list to memory
      commit("getListOfScoreCards", payLoad);
    },

    //Add a hand to the score card
    async addHand({ getters, commit }, hand) {
      const docRef = doc(db, "scoreCards", getters["scoreCardID"]);
      await setDoc(docRef, { hands: arrayUnion(hand) }, { merge: true });
      commit("addHand", hand);
    },

    //Update the player order
    async updatePlayerOrder({ getters, commit }, players) {
      const docRef = doc(db, "scoreCards", getters["scoreCardID"]);
      await setDoc(docRef, { players: players }, { merge: true });
      commit("updatePlayerOrder", players);
    },

    //Update the last dealer
    async updateLastDealer({ getters, commit }, dealer) {
      const docRef = doc(db, "scoreCards", getters["scoreCardID"]);
      await setDoc(docRef, { lastDealer: dealer }, { merge: true });
      commit("updateLastDealer", dealer);
    },

    //Add a game to the score card
    async addGame({ getters, commit }, game) {
      const docRef = doc(db, "scoreCards", getters["scoreCardID"]);
      await setDoc(docRef, { games: arrayUnion(game) }, { merge: true });
      commit("addGame", game);
    },

    //Update the score card name
    async changeScoreCardName({ commit }, payLoad) {
      const docRef = doc(db, "scoreCards", payLoad.id);
      await updateDoc(docRef, {
        scoreCardName: payLoad.name,
      });

      commit("changeScoreCardName", payLoad);
    },

    //save customizable data after user edits
    async saveCustomizableData({ getters }, payLoad) {
      const docRef = doc(db, "scoreCards", getters["scoreCardID"]);
      await updateDoc(docRef, {
        customizable: payLoad.customizable,
      });
      //commit not needed because scoreCard customizable data was edited in place
    },

    //Delete the referenced score card
    async deleteScoreCard({ dispatch }, id) {
      const docRef = doc(db, "scoreCards", id);
      //delete the score card
      await deleteDoc(docRef);
      //Get updated list of score cards
      await dispatch("getListOfScoreCards");
    },

    //Delete all score cards for the referenced card club
    async deleteAllScoreCards({ dispatch }, id) {
      //query for and deleted all club players owned by card club
      const scoreCardsForClub = query(
        scoreCardsCollection,
        where("ccID", "==", id)
      );

      const batch = writeBatch(db);
      const snapshot = await getDocs(scoreCardsForClub);

      snapshot.forEach((cp) => {
        batch.delete(cp.ref);
      });
      await batch.commit();

      await dispatch("getListOfScoreCards");
    },

    //Delete hands for a game from the score card
    async deleteHands({ commit, state }, payLoad) {
      const docRef = doc(db, "scoreCards", payLoad.ID);
      /* filter out the hand records that are to be deleted.  Then rewrite the remaining hands.  Firebase does not easily allow removing individual records in arrays */
      let filteredHands = state.scoreCard.hands.filter((hand) => {
        if (hand.gameNumber != payLoad.gameNumber) {
          return true;
        }
      });

      await setDoc(docRef, { hands: filteredHands }, { merge: true });

      commit("deleteHands", filteredHands);
    },

    //Edit a score for a hand
    async editHand({ state }, payLoad) {
      /* Copy the hands array into a new array.  change the one game/hand/player value that has been edited */
      const docRef = doc(db, "scoreCards", payLoad.ID);

      let editedHands = [];
      //Find the game number and hand row
      state.scoreCard.hands.forEach((hand) => {
        if (
          hand.gameNumber === payLoad.gameNumber &&
          hand.handNumber === payLoad.row
        ) {
          //Find the player column and edit the score
          hand.scores[payLoad.column].score = payLoad.newValue;
        }
        //Add the hand to the new array
        editedHands.push(hand);
      });

      //Write the new array to the database
      await setDoc(docRef, { hands: editedHands }, { merge: true });
    },
  },
};

export default scoreCardHeader;
