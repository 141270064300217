<template>
  <v-snackbar
    location="center"
    transition="fade-transition"
    :left="true"
    multi-line
    timeout="5000"
    v-model="store.state.snackbar.show"
    class="snackBarBody"
    variant="elevated"
    elevation="24"
  >
    <!-- Create snackbar content -->
    <v-container class="pa-0" fluid>
      <v-row :no-gutters="true" class="d-flex ma-0 justify-left align-center">
        <v-col class="text-h6 font-weight-bold text-center">
          {{ store.getters["snackbarText"] }}
        </v-col>
      </v-row>
      <v-row
        :no-gutters="true"
        class="d-flex ma-0 mt-4 justify-left align-center"
      >
        <v-col class="text-right">
          <v-btn class="stdBtn-snackBar" @click="store.commit('hideSnackbar')">
            {{ t("common.close") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script setup>
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";

  //create function to insert i18N text
  let { t } = useI18n({});

  const store = useStore();
</script>

<style scoped></style>
