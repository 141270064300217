<template>
  <v-container flex>
    <pdfvuer
      :src="termsAndConditionsFile"
      v-for="i in numPDFPages"
      :key="i"
      :id="i"
      :page="i"
      :resize="true"
    ></pdfvuer>
  </v-container>
</template>

<script setup>
  import pdfvuer from "pdfvuer";
  import { ref, onMounted } from "vue";

  let numPDFPages = ref(1);

  let userLanguage = navigator.language || navigator.userLanguage;

  let termsAndConditionsFile = ref("");
  /* Get terms and conditions file*/
  if (userLanguage === "en-US") {
    /* If language english display english T&C */
    termsAndConditionsFile.value = "tandc/terms-and-conditions.pdf";
  } else if (userLanguage === "es") {
    /* If language spanish display spanish T&C */
    termsAndConditionsFile.value = "tandc/terms-and-conditions-es.pdf";
  } else {
    /* If language not found display English */
    termsAndConditionsFile.value = "tandc/terms-and-conditions.pdf";
  }

  /* Get the number of pages in the pdf file */
  const getPdf = async () => {
    let pdfdata = pdfvuer.createLoadingTask(termsAndConditionsFile.value);
    const pages = await pdfdata;
    numPDFPages.value = pages.numPages;
  };

  /* Once mounted call function to get the number of pages in the PDF being loaded */
  onMounted(() => {
    getPdf();
  });
</script>
<style scoped></style>
