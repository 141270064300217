import {
  getDocs,
  collection,
  doc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebaseInit";
import { saveStorage } from "../../components/composables/localStorage";

const gamesData = collection(db, "gamesData");

const cardGamesModule = {
  namespaced: true,
  state() {
    return {
      /* Loaded from database (page mount). Holds list of game data displayed on page */
      gamesData: [],

      //Holds whether games data has been loaded from database
      gamesDataLoaded: false,

      //Holds id of Card Club that was Selected when database was loaded
      gameDataID: "none",

      /* Holds whether currently selected game is played by teams or individuals. Data comes from game db, but if game can be either team or individual data comes from user selection */
      gamePlayedBy: null,

      /* Holds how the game ends for the currently selected game.  Data defaults comes from game db, but can be customized by user */
      gameEnds: {
        howGameEnds: "rounds",
        roundsToEnd: 10,
        pointsToEnd: 100,
        winningScore: "high",
      },

      /* Holds the translated name of the currently selected game */
      gameTranslatedName: "",
    };
  },

  //Getters for game data (gds prefix on getter means that it is a getter that returns data from only the currently selected game )
  getters: {
    //Return name of currently selected game
    gdsName(state, getters) {
      //Get the data for the currently selected game
      let cgSelected = getters.gdsGameData;
      //If no game selected, return NA
      if (cgSelected === false) {
        return "common.NA";
      }
      //Return the name of the currently selected game
      return cgSelected[0].name;
    },

    //Return the deck type for the currently selected game
    gdsDeckType(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].deckType;
    },

    //Return the vue file name for the currently selected game
    gdsVueFile(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].vueFileToLoad;
    },

    //Return more info web link for currently selected game
    gdsInfoLink(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].infoLink;
    },

    //Return game played by (teams or individuals)
    gdsGamePlayedBy(state) {
      //This variable is set outside the game data object because it can be set by the user
      return state.gamePlayedBy;
    },

    //Return true if game played by teams
    gdsGamePlayedByTeams(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return false;
      }
      return cgSelected[0].gamePlayedByTeams;
    },

    //Return true if game played by individuals
    gdsGamePlayedByIndividuals(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return false;
      }
      return cgSelected[0].gamePlayedByIndividuals;
    },

    //Return min players per team for currently selected game
    gdsMinTeamPlayers(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].minTeamPlayers;
    },

    //Return max players per team for currently selected game
    gdsMaxTeamPlayers(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].maxTeamPlayers;
    },

    //Return min number of teams for currently selected game
    gdsMinTeams(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].minTeams;
    },

    //Return max number of teams for currently selected game
    gdsMaxTeams(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].maxTeams;
    },

    //Return min number of players per team for currently selected game
    gdsMinIndividualPlayers(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].minIndividualPlayers;
    },

    //Return max number of players per team for currently selected game
    gdsMaxIndividualPlayers(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].maxIndividualPlayers;
    },

    //Return notes file name for currently selected game
    gdsNotesFileName(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return "NA";
      }
      return cgSelected[0].notesFileName;
    },

    /* return the selected game's full data record.
    return false if record not found */
    gdsGameData(state) {
      let cgSelected = state.gamesData.filter(
        (cg) => cg.id === state.gameDataID
      );
      if (cgSelected.length === 0) {
        return false;
      }
      return cgSelected;
    },

    //Return ID of game currently selected
    gdsGameDataID(state) {
      return state.gameDataID;
    },

    //Return true if game type is team.  Data comes from game db, but if game can be either team or individual data comes from user selection
    gdsIsGameTypeTeam(state) {
      return state.gamePlayedBy === "teams";
    },

    /* return the customizable data for the selected game.  Customizable data is data the user is allowed to change on the scorecard. */
    gdsCustomizable(state, getters) {
      let cgSelected = getters.gdsGameData;
      if (cgSelected === false) {
        return false;
      }

      return cgSelected[0].customizable;
    },

    /* Returns the translated name of the currently selected game */
    gdsGameTranslatedName(state) {
      return state.gameTranslatedName;
    },

    //Return all game daa
    gamesData(state) {
      return state.gamesData;
    },

    //Return true if game data has been loaded from database.  Watched by other functions to know when game data is available
    gamesDataLoaded(state) {
      return state.gamesDataLoaded;
    },

    //Return ID of game currently selected
    gameDataID(state) {
      return state.gameDataID;
    },

    //Return how game ends structure
    gameEnds(state) {
      return state.gameEnds;
    },
  },

  /* This section writes data to the state */
  mutations: {
    /* Sets how game ends structure */
    gameEnds(state, payLoad) {
      state.gameEnds = payLoad;

      /* Save data to local storage so that it can be restored when page refreshes */
      saveStorage("howGameEnds", payLoad);
    },

    //Sets game data loaded flag. Watched by other functions to know when game data is available
    setGameDataLoadedState(state, payload) {
      state.gamesDataLoaded = payload;
    },

    //Sets game played by structure
    gamePlayedBy(state, payLoad) {
      state.gamePlayedBy = payLoad;
      /* Save data in local storage so that it can be restored when page refreshes */
      saveStorage("gamePlayedBy", payLoad);
    },

    //Sets ID of game currently selected
    gameDataID(state, id) {
      state.gameDataID = id;
    },

    /* Writes game data to display object should be called after game data is loaded from database */
    loadGamesData(state, payLoad) {
      state.gamesData = payLoad.cg;
      state.gameDataID = payLoad.cgID;
      state.gamesDataLoaded = true;
    },

    /* Update the selected status of a Card Game in the display object. Makes same changes in display object that were made in database */
    setGameDataSelected(state, id) {
      state.gamesData.forEach((cc) => {
        if (cc.id === id) {
          cc.selected = true;
        } else {
          cc.selected = false;
        }
      });
    },

    /* Write currently selected game translated name */
    setGameTranslatedName(state, payLoad) {
      state.gameTranslatedName = payLoad;
    },
  },

  /*  Database reads/writes are in actions. */
  actions: {
    /*Load the games data from the database (called when gamesInsert.vue is mounted)*/
    async loadGamesData({ commit }) {
      //Get a snapshot of all the records found in the games collection
      const gamesSnapshot = await getDocs(collection(db, "gamesData"));

      //Create an array to hold the games data
      let cg = [];

      /* In case there is no selected club in the database, set temp to the first record */
      let tempCardGameID = gamesSnapshot.docs[0].id;

      //Loop through the records in the snapshot and add them to the array
      gamesSnapshot.docs.forEach((doc) => {
        cg.push({ ...doc.data(), id: doc.id });

        if (doc.data().selected === true) {
          tempCardGameID = doc.id;
        }
      });

      /*call to copy array from database to store gamesData array used on screen */
      let payLoad = { cg: cg, cgID: tempCardGameID };
      commit("loadGamesData", payLoad);
    },

    /* Called when user selects a new game data by clicking a game data radio button
        or when the card clubs are loaded 
        or when a new card club is selected by clicking a radio button.
        Update the Selected status of a Card Club in the database
        Update display object first to be more responsive
        In db, clear previously selected Card Club, then set newly selected card game based in ID passed in */
    async updateSelectedGameData({ commit }, id) {
      //to be more responsive, update display array before changing database
      commit("setGameDataSelected", id);

      //create query to find all records that are currently selected
      const selectedCardGameQuery = query(
        gamesData,
        where("selected", "==", true)
      );
      //Get a snapshot of all the records found
      const querySnapshot = await getDocs(selectedCardGameQuery);
      //if query returns a result
      if (!querySnapshot.empty) {
        //convert query collection to docs collection
        const allDocs = querySnapshot.docs;
        const docRef = doc(db, "gamesData", allDocs[0].id);
        await updateDoc(docRef, { selected: false });
      }

      //If valid ID passed in, for the CC club with the id pass in, turn on selected
      if ((id != undefined) & (id != null) & (id != "none")) {
        const docRef2 = doc(db, "gamesData", id);
        updateDoc(docRef2, { selected: true }).then(() => {});
      }
    },
  },
};

export default cardGamesModule;
