<template>
  <!-- TODO figure out what app id should be -->
  <v-app id="score_cards" v-cloak>
    <v-main class="vMain">
      <!-- App bar for site.  Shows on all pages  turned off when in preview mode-->
      <v-card class="pa-0" fluid>
        <v-app-bar
          :floating="false"
          elevation="2"
          :collapse="false"
          class="appBar"
        >
          <!-- App bar image -->
          <template v-slot:image>
            <v-img
              :cover="true"
              src="/AppBarImage.jpg"
              class="appBar--image"
              gradient="to top right, var(--cssAppBarGradient-left), var(--cssAppBarGradient-right) "
            ></v-img>
          </template>

          <!-- Title for app bar -->
          <v-app-bar-title
            @click="routerHome"
            class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h1 font-weight-bold appBar-title"
          >
            {{ t("app.name") }}
          </v-app-bar-title>

          <!-- Home button -->
          <v-btn
            class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 appBar appBar-home"
            @click="routerHome"
          >
            {{ $t("app.home") }}
          </v-btn>

          <!-- Logout button toggles between logout if user logged in and
        login if no user logged in -->
          <v-btn
            class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 appBar appBar-logout"
            @click="openLogInLogOutDialog"
            >{{ liloButtonTitle }}</v-btn
          >

          <!-- Register button is only displayed if no user logged in -->
          <v-btn
            v-if="!store.getters['auth/userLoggedIn']"
            @click="openRegisterDialog"
            class="text-subtitle-1 text-sm-h6 text-md-h5 text-lg-h4 appBar appBar-register"
            >{{ $t("app.register") }}</v-btn
          >

          <!-- Help button -->
          <v-tooltip location="left" open-delay="1000">
            <template v-slot:activator="{ props }">
              <v-icon
                class="iconButton iconButton-light size-md-xl"
                v-bind="props"
                icon="mdi-help-circle-outline"
                @click="routerHelp"
                :size="smAndDown ? '20px' : '30px'"
              />
            </template>
            <p class="ttText">{{ $t("app.ttGetHelp") }}</p>
          </v-tooltip>

          <!-- User acount info button only displayed if user logged in
        button icon will be green if use is a subcriber to the site, 
        otherwise icon will be white -->
          <div v-if="store.getters['auth/userLoggedIn']">
            <v-tooltip location="start" open-delay="1000">
              <template v-slot:activator="{ props }">
                <v-icon
                  class="iconButton iconButton-light"
                  v-bind="props"
                  :class="
                    store.getters['sm/isUserSubscriber']
                      ? 'iconButton-active'
                      : 'iconButton-notActive'
                  "
                  icon="mdi-account-plus-outline"
                  @click="openAccountDialog"
                  :size="smAndDown ? '20px' : '30px'"
                />
              </template>
              <p class="ttText">{{ t("app.ttUpdateAccountInfo") }}</p>
            </v-tooltip>
          </div>

          <!-- Change language button -->
          <v-tooltip location="left" open-delay="1000">
            <template v-slot:activator="{ props }">
              <v-icon
                class="iconButton iconButton-light"
                v-bind="props"
                icon="mdi-human"
                @click="changeLocale"
                :size="smAndDown ? '20px' : '30px'"
              />
            </template>
            <p class="ttText">{{ $t("app.ttChangeLang") }}</p>
          </v-tooltip>

          <!-- If user is a admin, display admin access icon here -->
          <div v-if="store.getters['auth/userIsAdmin']">
            <init-db-menu></init-db-menu>
          </div>
        </v-app-bar>

        <!-- Other components inserted here -->
        <!-- Router linkes -->
        <router-view></router-view>

        <!-- Login dialog, activated by value in auth/store -->
        <login-insert
          v-if="store.getters['auth/showLoginLogOutDialog']"
        ></login-insert>

        <!-- Register dialog, activated by value in auth/store -->
        <register-insert
          v-if="store.getters['auth/showRegisterDialog']"
        ></register-insert>

        <!-- Account update dialog, activated by value in auth/store -->
        <account-insert
          v-if="store.getters['auth/showAccountDialog']"
        ></account-insert>

        <!-- This is the about dialog -->
        <TheStandardDialog
          :showDialog="store.getters['aboutDialog']"
          :titleText="$t('about.title')"
          insertText="not-used"
          :explanationText="$t('about.text')"
          leftBtnText="not-used"
          :rightBtnText="t('common.close')"
          @rightBtn="store.commit('aboutDialogHide')"
        >
        </TheStandardDialog>

        <!-- This is the terms and conditions dialog -->
        <TheStandardDialog
          :showDialog="store.getters['tAndCDialog']"
          titleText="Terms and conditions"
          insertText="not-used"
          explanationText="not-used"
          leftBtnText="not-used"
          :rightBtnText="t('common.close')"
          @rightBtn="store.commit('tAndCDialogHide')"
        >
          <template v-slot:dSlot>
            <!-- Terms and conditions are displayed here if button is clicked -->

            <v-row>
              <v-col cols="12" class="pa-1">
                <the-terms-and-conditions></the-terms-and-conditions>
              </v-col>
            </v-row>
          </template>
        </TheStandardDialog>

        <!-- This is the contact inforation dialog -->
        <TheStandardDialog
          :showDialog="store.getters['contactInfoDialog']"
          :titleText="$t('contactInfo.title')"
          insertText="not-used"
          explanationText="not-used"
          leftBtnText="not-used"
          :rightBtnText="t('common.close')"
          @rightBtn="store.commit('contactInfoDialogHide')"
        >
          <template v-slot:dSlot>
            {{ t("contactInfo.text") }}
            <a href="mailto:mikeblomquist57@hotmail.com">{{
              t("contactInfo.email")
            }}</a>
          </template>
        </TheStandardDialog>

        <!-- Snackbar, activated by value in /store -->
        <the-snack-bar></the-snack-bar>

        <!-- Footer for site -->
        <v-footer :border="true" class="d-flex mb-8 cardBodySectionColor01">
          <v-row :no-gutters="true" class="d-flex ma-0 justify-start">
            <v-col cols="auto" class="d-flex justify-start align-self-center">
              <v-btn
                v-for="(link, i) in links"
                :key="link.name"
                variant="text"
                class="stdBtn font-italic text-body-2 mr-2"
                size="x-small"
                @click="footerButton(i)"
              >
                {{ link.name }}
              </v-btn>
            </v-col>

            <v-col
              class="font-italic mr-2 text-body-2 d-flex justify-end my-auto"
            >
              {{ $t("app.nameTM") }}
              {{ new Date().getFullYear() }}
            </v-col>
          </v-row>
        </v-footer>
      </v-card>
    </v-main>
  </v-app>
</template>

<script setup>
  import TheSnackBar from "./components/reusable/TheSnackBar.vue";
  import initDbMenu from "./store/initDatabase/initDbMenu";
  import loginInsert from "./components/viewInserts/LoginInsert.vue";
  import registerInsert from "./components/viewInserts/RegisterInsert.vue";
  import AccountInsert from "./components/viewInserts/AccountInsert.vue";
  import TheStandardDialog from "./components/reusable/TheStandardDialog.vue";
  import TheTermsAndConditions from "./components/reusable/TheTermsAndConditions.vue";
  import { computed, watch, onMounted, ref } from "vue";
  import { inDemoMode } from "../src/components/composables/demoModeHelpers";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";
  import { useDisplay } from "vuetify";

  //Load reactive true or false for small and down display size
  let { smAndDown } = useDisplay();
  //Load i18n for working with translations
  let { t, locale } = useI18n({});

  const router = useRouter();
  const store = useStore();

  const liloButtonTitle = computed(() => {
    if (store.state.auth.userLoggedIn) {
      //Return text for login/logout button
      return t("app.logout");
    } else {
      return t("app.login");
    }
  });

  /* Watch for user to log in.  If user logged in, load list of open
          score cards for the user */
  watch(
    () => store.getters["auth/userLoggedIn"],
    (userLoggedIn) => {
      if (userLoggedIn) {
        let userID = store.getters["auth/loggedInSiteMemberID"];
        store.dispatch("sm/loadSiteMember", userID);
      }
    }
  );

  //Open account dialog
  function openAccountDialog() {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.openAccountInfo"), true)) {
      return;
    }

    store.commit("auth/showAccountDialog");
  }

  //Login button pressed
  function openLogInLogOutDialog() {
    store.commit("auth/showLoginLogOutDialog");
  }

  //register button pressed
  function openRegisterDialog() {
    store.commit("auth/showRegisterDialog");
  }

  //Navigate to home page when Home button pressed
  function routerHome() {
    router.replace({ name: "home" });
  }

  //Navigate to help page when ? icon pressed
  function routerHelp() {
    router.push({ name: "help" });
  }

  /*Change language when language button pressed.  Currently only toggling between
        english and spanish*/
  function changeLocale() {
    /* Disable function if in demo mode */
    if (inDemoMode(t("demoMode.defaultMessage"), true)) {
      return;
    }
    locale.value = locale.value === "en" ? "es" : "en";
  }

  /* Handle footer */
  /*links name shows on the button in the footer dialogShow is the name of the store commit that turns on the corrosponding dialog*/
  let links = ref([
    { name: t("app.aboutScoreCards"), dialogShow: "aboutDialogShow" },
    { name: t("app.contactInfo"), dialogShow: "contactInfoDialogShow" },
    { name: t("app.showTandC"), dialogShow: "tAndCDialogShow" },
  ]);

  function footerButton(i) {
    //Get the string value of the store commit that opens the dialog
    store.commit(links.value[i].dialogShow);
  }

  onMounted(() => {
    //Setup observer to watch for changes in authorized user status
    store.dispatch("auth/authObserver");
  });
</script>

<style lang="scss">
  //This style cannot be scoped, as it is used by all components
  //Import main scss file, which imports all other scss files
  //@import "./sass/main.scss";

  [v-cloak] {
    display: none;
  }

  .vMain {
    background-color: var(--main-bg-color);
  }

  .appBar {
    &-title {
      margin-left: 1rem;
      text-align: left;
      color: var(--cssText-light);
      cursor: pointer;
      font-variant: all-small-caps;
    }

    &-home,
    &-logout,
    &-register {
      color: var(--cssText-light) !important;
      font-variant-caps: all-small-caps;
    }
  }
</style>
