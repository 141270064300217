<template>
  <v-row v-if="!store.getters['auth/userIsAdmin']">
    <v-container fluid class="ma-0">
      <v-row :no-gutters="true" class="d-flex ma-0 justify-center">
        <v-col
          class="text-h6 text-sm-h5 text-md-h4 my-4 d-flex justify-center alertText"
        >
          {{ t("adminView.pageNotAccessable") }}
        </v-col>
      </v-row>
    </v-container>
  </v-row>

  <v-card v-else class="card-standard card-body">
    <v-container fluid class="ma-0">
      <TheStandardHeaderRow>
        <template v-slot:title> {{ t("adminView.adminFunctions") }} </template>
      </TheStandardHeaderRow>

      <v-row :no-gutters="true" class="d-flex ma-0 justify-start">
        <v-col cols="12" class="pa-0 ma-0">
          <v-text-field
            v-model="email"
            autofocus
            :label="t('adminView.enterEmail')"
            required
            hide-details="true"
            class="pa-0 ma-0 textFieldStandard"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row :no-gutters="true" class="d-flex ma-0 justify-start">
        <v-radio-group
          v-model="adminStatus"
          :inline="true"
          hide-details="true"
          density="compact"
        >
          <v-col cols="2">
            <v-radio
              :label="t('adminView.makeAdmin')"
              value="makeAdmin"
              hide-details="true"
            ></v-radio>
          </v-col>
          <v-col cols="2">
            <v-radio
              :label="t('adminView.removeAdmin')"
              value="removeAdmin"
            ></v-radio>
          </v-col>
        </v-radio-group>
      </v-row>
      <v-row :no-gutters="true" class="d-flex ma-0 justify-start">
        <v-radio-group
          v-model="subscriberStatus"
          :inline="true"
          hide-details="true"
          density="compact"
        >
          <v-col cols="2">
            <v-radio
              :label="t('adminView.makeSubscriber')"
              value="makeSubscriber"
            ></v-radio>
          </v-col>
          <v-col cols="2">
            <v-radio
              :label="t('adminView.removeSubscriber')"
              value="removeSubscriber"
            ></v-radio>
          </v-col>
        </v-radio-group>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mx-2 text-h6 stdBtn elevation-1" @click="closeAdminView">
          {{ t("adminView.close") }}
        </v-btn>

        <v-btn class="mx-2 text-h6 stdBtn elevation-1" @click="updateClaims">
          {{ t("adminView.updateClaims") }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script setup>
  //import TheL2HeadingText from "../reusable/TheL2HeadingText.vue";
  import TheStandardHeaderRow from "../reusable/TheStandardHeaderRow.vue";
  import { useStore } from "vuex";
  import { onMounted, ref } from "vue";
  import { httpsCallable } from "firebase/functions";
  import { functions } from "../../store/firebaseInit";
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";

  //Load i18n for working with translations
  let { t } = useI18n({});
  const router = useRouter();
  const store = useStore();

  const email = ref("");
  const adminStatus = ref("makeAdmin");
  const subscriberStatus = ref("removeSubscriber");

  function closeAdminView() {
    router.push({ name: "home" });
  }
  async function updateClaims() {
    //Adjust claims per radio buttons on form
    let admin = false;
    let subscriber = false;
    if (adminStatus.value === "makeAdmin") {
      admin = true;
    }
    if (subscriberStatus.value === "makeSubscriber") {
      subscriber = true;
    }

    let payLoad = { email: email.value, admin: admin, subscriber: subscriber };

    const addAdminRole = httpsCallable(functions, "updateClaims");
    await addAdminRole(payLoad);
  }

  onMounted(function () {});
</script>
<style lang="scss" scoped></style>
